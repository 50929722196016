import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import WarningIcon from "@material-ui/icons/Warning";
import Link from "@mui/material/Link";

import ModalContentBase from "./ModalContentBase";
import Dialog from "../Dialogs/DraggableDialog/DraggableDialog";

import classes from "./InfoBoard.module.css";
import filestatsclasses from "../../Downloader/FileStats/FileStats.module.css";
import JDPLifeHelpIcon from "../Icons/JDPLifeHelpIcon";
import {
  HREF_DOC_CERTAIN_RESULTS,
  HREF_DOC_HOW_TO_FILTER,
} from "../../../utils/URLConstants";

export const CONFIRM_APPLY_FILTER = "CONFIRM_APPLY_FILTER";
export const CONFIRM_APPLY_RELEVANCY = "CONFIRM_APPLY_RELEVANCY";
const ConfirmChange = ({ atype, acceptFn, cancelFn, checkFn }) => {
  // console.log(atype, "[ConfirmChange]");
  const title =
    atype === CONFIRM_APPLY_FILTER
      ? "Confirm applying filter"
      : "Confirm viewing most relevant results";
  const warningTxt =
    atype === CONFIRM_APPLY_FILTER
      ? `With the filter change applied, you will lose all your current selections.`
      : `With the filter "Show Most Relevant Results" applied some of your selected files will not be part of your selection anymore.`;

  const linkTxt =
    atype === CONFIRM_APPLY_RELEVANCY
      ? `Learn more about "Show Most Relevant Results" category`
      : `Learn more about applying filters`;
  const faqID =
    atype === CONFIRM_APPLY_RELEVANCY
      ? HREF_DOC_CERTAIN_RESULTS
      : HREF_DOC_HOW_TO_FILTER;
  return (
    <Dialog
      cancelBtnFn={cancelFn}
      cancelBtnText="Return to previous page"
      acceptBtnFn={acceptFn}
      acceptBtnText="Continue"
      checkBtnText="Do not show this again."
      checkBtnFn={checkFn}
    >
      <ModalContentBase titleText={title} closeFn={cancelFn}>
        <Typography
          paragraph
          style={{
            backgroundColor: classes.orange50,
            padding: "10px",
          }}
        >
          <WarningIcon
            style={{ color: classes.orange600 }}
            className={filestatsclasses.icon}
          />
          {warningTxt}
        </Typography>

        {linkTxt && faqID && (
          <Typography paragraph>
            <JDPLifeHelpIcon size="default">
              <Link href={faqID} target="_blank" underline="hover">
                {linkTxt}
              </Link>
            </JDPLifeHelpIcon>
          </Typography>
        )}
      </ModalContentBase>
    </Dialog>
  );
};

export default ConfirmChange;

ConfirmChange.defaultProps = {
  atype: CONFIRM_APPLY_FILTER,
};

ConfirmChange.propTypes = {
  atype: PropTypes.string,
  acceptFn: PropTypes.func.isRequired,
  cancelFn: PropTypes.func.isRequired,
  checkFn: PropTypes.func.isRequired,
};
