import React from "react";

import PropTypes from "prop-types";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";

import classes from "../../../themes/theme.module.css";

const JDPLifeHelpIcon = ({ size, gap, children }) => {
  return (
    <>
      <LiveHelpIcon
        style={{
          color: classes.lake500,
          position: "relative",
          marginRight: gap,
          verticalAlign: "middle",
        }}
        fontSize={size}
      />
      {children}
    </>
  );
};

export default JDPLifeHelpIcon;

JDPLifeHelpIcon.defaultProps = {
  children: null,
  size: "small",
  gap: 10,
};

JDPLifeHelpIcon.propTypes = {
  size: PropTypes.string,
  gap: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
