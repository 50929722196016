import React from "react";

import Typography from "@mui/material/Typography";
import WarningIcon from "@material-ui/icons/Warning";
import classes from "../../UI/InfoBoard/InfoBoard.module.css";
import filestatsclasses from "../FileStats/FileStats.module.css";

const TapeFileRestoreWarningContent = () => {
  return (
    <>
      <Typography
        paragraph
        style={{
          backgroundColor: classes.orange50,
          padding: "10px",
        }}
      >
        <WarningIcon
          style={{ color: classes.orange600 }}
          className={filestatsclasses.icon}
        />
        Some of the files you’ve selected are in our tape archive (archived
        files). Your selected batch of files will be ready for download within
        24 hours
      </Typography>
    </>
  );
};

export default TapeFileRestoreWarningContent;
