import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core/";
import InfoIcon from "@material-ui/icons/Info";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TablePagination from "@mui/material/TablePagination";

import { sortRowsWithFileName } from "../../utils/SortUtils";
import { fileStatusTransform, selectedForRow } from "../../utils/FileUtils";
import { deepClone } from "../../utils/ObjectUtils";
import * as cartTool from "../../utils/CartDataTools";

import classes from "./FileTable.module.css";

import StyledCheckbox from "../StyledCheckbox/StyledCheckbox";
import FileTableCells from "./FileTableCells/FileTableCells";
import SkeletonTable from "./SkeletonTable";

import FileAvailabilityDlg from "../Downloader/dialogs/FileAvailabilityDlg";

import ConfigContext from "../../context/ConfigContext";
import FileAndPageContext from "../../context/FileAndPageContext";

import sortableHigh from "../../assets/images/sortable.svg";
import sortableUp from "../../assets/images/sorted.svg";

import { FILE_BASELINE_COLUMNS } from "../../config/appConfig";
import { useSearchParams } from "../../hooks/useSearchParams";

import Tooltip, { ICON_WARN } from "../UI/Tooltip/Tooltip";
// import { base as ldbase } from "../../utils/LDFFUtils";

import {
  ROW_SELECTED_NONE,
  ROW_SELECTED_SOME,
  ROW_SELECTED_ALL,
} from "../GenomeTable/Constants";

const FileTable = (props) => {
  const {
    fileGrouping,
    selectedFiles,
    setSelectedFiles, // SearchPageContainer.setSelectedFiles
    honeycomb,
    ldClient,
    onSelectAllClicked,
    selectState,
    rowId,
    cartData,
    restoreid,
    currentUser,
    addFilesToCart,
  } = props;

  const { cart: cartpage } = useSearchParams();

  const sendProcessingSpan = useRef();

  const { files } = fileGrouping;

  useEffect(() => {
    sendProcessingSpan.current = honeycomb.sendProcessingSpan;
  }, [honeycomb]);

  const { segment } = useParams();
  const { fileColumns } = useContext(ConfigContext);

  const headers = cartpage
    ? deepClone(FILE_BASELINE_COLUMNS)
    : fileColumns.filter((header) => {
        if (ldClient && header.ld) {
          return ldClient.variation(header.ld);
        }
        return true;
      });
  // console.log(headers, "[FileTable]");
  const [sortedfiles, setSortedFiles] = useState();
  const [sortInfo, setSortInfo] = useState({
    column: headers[0].id,
    ascending: true,
  });
  const [fileAvailInfo, setFileAvailInfo] = useState(false);

  const currentSelectedFIDs = selectedForRow(selectedFiles, rowId);

  const onFileRowCheckboxClicked = (file) => {
    const id = file._id;

    if (currentSelectedFIDs.includes(id)) {
      // Unselect
      const filteredSelectedFiles = selectedFiles.filter(
        (f) => !(f.organism_id === rowId && f._id === id)
      );
      setSelectedFiles(filteredSelectedFiles);
      honeycomb.sendUiInteractionSpan("unselected-file", {
        file: file.file_name,
      });
    } else {
      // Select
      setSelectedFiles(
        selectedFiles?.length > 0 ? selectedFiles.concat([file]) : [file]
      );
      honeycomb.sendUiInteractionSpan("selected-file", {
        file: file.file_name,
      });
    }
  };

  const onSort = (column) => {
    const ascending =
      column && column === sortInfo.column ? !sortInfo.ascending : true;

    honeycomb.sendUiInteractionSpan("clicked-sortable-file-table-header", {
      sort: { column, ascending },
    });
    setSortInfo({
      column,
      ascending,
    });
  };

  const kingdom = fileGrouping.kingdom || segment;
  /**  Keep displayed 'sortedFiles' in sync with sort params and files */
  useEffect(() => {
    const before = Date.now();
    const sortedRows = sortRowsWithFileName(
      files,
      sortInfo.column,
      sortInfo.ascending,
      kingdom
    );
    setSortedFiles(sortedRows);
    sendProcessingSpan.current("sorted-file-rows", {
      sort: {
        ...sortInfo,
        count: sortedRows.length,
      },
      duration: Date.now() - before,
    });
  }, [files, sortInfo, sendProcessingSpan, segment]);

  const sortArrow =
    sortInfo.column === undefined ? null : sortInfo.ascending ? (
      <img src={sortableUp} alt="sort asc" className={classes.sortAsc} />
    ) : (
      <img src={sortableUp} alt="sort des" className={classes.sortDes} />
    );

  // JDP-2166 - file level shopping cart column
  headers.push({
    align: "left",
    id: "cart",
    label: "",
    sortable: false,
  });

  // file level kebab column
  headers.push({
    align: "left",
    id: "file_kebab",
    label: "kebab",
    sortable: false,
  });

  const tableHeaderCell = (headerConfig) => {
    const { id, label, sortable, align } = headerConfig;

    const sortClass = sortable
      ? [classes.headerColumnSort]
      : [classes.headerColumn];
    if (sortInfo.column === id) {
      sortClass.push(classes.headerSorted);
    }

    const hdprops = {
      "data-testid": "filesTableHeader",
      align,
      className: sortClass.join(" "),
    };
    const checkboxProps = {
      className: cartpage
        ? classes.headerColumn
        : `${hdprops.className} ${classes.checkboxCell}`,
      align: "right",
    };

    if (sortable) {
      hdprops.onClick = () => {
        onSort(id);
      };
    }

    // when search and with cart FF, if all files of row are in cart already
    const allInCart =
      restoreid || !cartData || !Array.isArray(files) || files.length === 0
        ? false
        : cartTool.allFilesInCart(cartData, {
            id: files[0].organism_id,
            files,
          });

    return (
      // File table HEADER row
      <Fragment key={`${rowId}-${id}`}>
        {id === "file_name" && (
          <TableCell
            {...checkboxProps}
            style={{ cursor: allInCart ? "default" : "pointer" }}
          >
            {cartpage ? null : (
              <StyledCheckbox
                className="fileTableCheckbox"
                checked={selectState === ROW_SELECTED_ALL}
                onChange={(e) => {
                  e.stopPropagation();
                  if (!allInCart) {
                    onSelectAllClicked();
                  }
                }}
                inputProps={{
                  "aria-label": `Select All Checkbox`,
                }}
                showOutline={selectState === ROW_SELECTED_SOME}
                disabled={allInCart}
                style={{ cursor: allInCart ? "default" : "pointer" }}
              />
            )}
          </TableCell>
        )}
        <TableCell
          {...hdprops}
          style={
            label === "file_status" ? { paddingRight: 0, marginRight: 0 } : {}
          }
        >
          {label !== "kebab" ? label : null}
          {sortInfo.column === id ? (
            sortArrow
          ) : sortable ? (
            <img
              src={sortableHigh}
              alt="sortable"
              className={classes.sortable}
            />
          ) : null}
          {id === "file_status" && (
            <InfoIcon
              color="primary"
              className={classes.infoIcon}
              onClick={(event) => {
                event.stopPropagation();
                setFileAvailInfo(true);
                honeycomb.sendUiInteractionSpan(
                  "clicked-download-available-info-icon"
                );
              }}
            />
          )}
        </TableCell>
      </Fragment>
    );
  };

  const getClasses = (row, index) => {
    const cMap = {
      Now: classes.rowQuickDownload,
      Archived: classes.rowArchived,
      Pending: classes.rowQueued,
    };

    // JDP-2313/4 only highlight file row when in cart
    const incart = cartTool.fileInCart(cartData, row.organism_id, row._id);
    const cArray = [classes.dataRow];
    const fileStatus = fileStatusTransform(row.file_status);

    // if (currentSelectedFIDs.includes(row._id)) {
    if (incart) {
      cArray.push(cMap[fileStatus]);
    } else if (index % 2) {
      cArray.push(classes.rowEven);
    } else {
      cArray.push(classes.rowOdd);
    }

    return cArray.join(" ");
  };

  const { deleteFileFromCart } = useContext(FileAndPageContext);

  const handleDeleteFromCart = (file) => {
    honeycomb.sendUiInteractionSpan("cart-data-change", {
      action: "single file removal",
      data: { file: file._id },
    });
    deleteFileFromCart(file);
  };

  const fileListRowLeadColumn = (row) =>
    cartpage ? (
      <Tooltip iconType={ICON_WARN} title="Remove from the cart">
        <DeleteForeverIcon
          className="fileTableCheckbox"
          sx={{ color: classes.lake500 }}
          style={{
            position: "relative",
            left: -4,
            top: 2,
            cursor: "pointer",
          }} // so no visual change in icon change
          onClick={() => {
            handleDeleteFromCart(row);
          }}
        />
      </Tooltip>
    ) : restoreid ||
      !cartTool.fileInCart(cartData, row.organism_id, row._id) ? (
      <StyledCheckbox
        className="fileTableCheckbox"
        checked={currentSelectedFIDs.includes(row._id)}
        onChange={() => onFileRowCheckboxClicked(row)}
        inputProps={{
          "aria-label": `${row.file_name} Checkbox`,
        }}
      />
    ) : (
      // JDP-2166 : show disabled and unchecked box when in cart
      <StyledCheckbox
        className="fileTableCheckbox"
        checked={false}
        disabled
        inputProps={{
          "aria-label": `${row.file_name} Checkbox`,
        }}
      />
    );

  // const debug = true;
  // console.log(fileGrouping, sortedfiles, headers, "[FT]");

  const [page, setPage] = useState(0); // current page number
  const [rowsPerPage, setRowsPerPage] = useState(10); // current page size

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const FILE_COUNT_TO_PAGINATION = 100; // JDP-2046 - show paging only when file count is more than this
  const doPagination =
    sortedfiles?.length > rowsPerPage &&
    sortedfiles?.length > FILE_COUNT_TO_PAGINATION;

  const pageFiles = doPagination
    ? sortedfiles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : sortedfiles;
  // the file list UI
  const FILE_ROW_HEIGHT = 60;
  return (
    <Paper elevation={0}>
      <TableContainer
        className={classes.tableContainer}
        style={
          doPagination
            ? { maxHeight: FILE_ROW_HEIGHT * (rowsPerPage + 1) } // rows + header height; avoid scrolling
            : // ? { maxHeight: 400 }
              {}
        }
      >
        <Table size="medium" aria-label="species table" stickyHeader>
          <TableHead>
            <TableRow className={classes.headerRow}>
              {headers.map((header) => tableHeaderCell(header))}
            </TableRow>
          </TableHead>
          {!sortedfiles ? (
            <SkeletonTable />
          ) : (
            <TableBody>
              {pageFiles.map((row, index) => (
                <TableRow
                  key={row._id}
                  id={`file-${row._id}`}
                  data-testid="fileTableRow"
                  className={getClasses(row, index)}
                >
                  <TableCell className={classes.checkboxCell}>
                    {/* The 1st column of FileList table rows, chbox | cart | trash */}
                    {fileListRowLeadColumn(row)}
                  </TableCell>
                  <FileTableCells
                    row={row}
                    headers={headers}
                    segment={row.kingdom || kingdom}
                    ldClient={ldClient}
                    selected={currentSelectedFIDs.includes(row._id)}
                    honeycomb={honeycomb}
                    genomeId={rowId}
                    currentUser={currentUser}
                    cartData={cartData}
                    restoreid={restoreid}
                    addFilesToCart={addFilesToCart}
                    handleDeleteFromCart={handleDeleteFromCart}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                  />
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {doPagination && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={sortedfiles.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Number of files on page"
        />
      )}
      {fileAvailInfo && (
        <FileAvailabilityDlg
          handleCancel={() => {
            setFileAvailInfo(false);
          }}
        />
      )}
    </Paper>
  );
};

export default FileTable;

FileTable.defaultProps = {
  fileGrouping: null,
  selectedFiles: null,
  honeycomb: {
    sendProcessingSpan: () => {},
    sendUiInteractionSpan: () => {},
  },
  ldClient: null,
  onSelectAllClicked: () => {},
  rowId: "",
  cartData: null,
  selectState: ROW_SELECTED_NONE,
  restoreid: null,
  currentUser: {},
  addFilesToCart: null,
};

FileTable.propTypes = {
  fileGrouping: PropTypes.shape(),
  honeycomb: PropTypes.shape({
    sendProcessingSpan: PropTypes.func,
    sendUiInteractionSpan: PropTypes.func,
  }),
  ldClient: PropTypes.shape(),
  onSelectAllClicked: PropTypes.func,
  rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSelectedFiles: PropTypes.func.isRequired,
  selectedFiles: PropTypes.arrayOf(PropTypes.shape()),
  cartData: PropTypes.shape(),
  selectState: PropTypes.number,
  restoreid: PropTypes.string,
  currentUser: PropTypes.shape(),
  addFilesToCart: PropTypes.func,
};
