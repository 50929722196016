import React from "react";
import PropTypes from "prop-types";
import Dialog from "../../UI/Dialogs/DraggableDialog/DraggableDialog";

import ModalContentBase from "../../UI/InfoBoard/ModalContentBase";

const BrowserDownloadOKDlg = (props) => {
  const { handleClose } = props;

  return (
    <Dialog acceptBtnFn={handleClose} acceptBtnText="OK">
      <ModalContentBase
        closeFn={handleClose}
        titleText="Your download has begun"
      >
        <div id="browser-download-ok-confirmation-id">
          Thanks for using the JGI Genome Portal! Check the status of your
          downloads in your browser window.{" "}
        </div>
      </ModalContentBase>
    </Dialog>
  );
};

export default BrowserDownloadOKDlg;

BrowserDownloadOKDlg.propTypes = {
  handleClose: PropTypes.func.isRequired,
};
