/* istanbul ignore file */
import React, { useState, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useParams,
  // Redirect,
} from "react-router-dom";
import ImgBulkDownload from "../TableContainer/ImgBulkDownload";
import { useSearchParams } from "../../hooks/useSearchParams";

import {
  ERR_NOT_FOUND,
  ERR_NERSC_POWER_OUTAGE,
  ERR_SYSTEM_OUTAGE,
} from "../UI/ErrorMessage/ErrorMessage";

import appConfig from "../../config/appConfig";

import { useFileAndPageContext } from "../../context/FileAndPageContext";
import PageBrancher from "../TableContainer/PageBrancher";
import HomePageContainer from "../HomePage/HomePageContainer";
import MyDataPortal from "../MyDataPortal/MyDataPortal";

import {
  plannedNERSCOutageDowntime,
  genericOutage,
  base as ldbase,
} from "../../utils/LDFFUtils";

const RoutingContainer = (props) => {
  const { ldClient, topRef, currentUser } = props;

  const { cart } = useSearchParams();

  const portal_ff = ldbase(ldClient, "my-data-portal");

  // when at homepage with cart data, and cart in header is clicked, redirect /?cart=true to /search?cart=true to show the Cart page
  const pathname = window.location.pathname.trim();
  const toSearchWithCart = cart && ["/", "/mydata"].includes(pathname);

  const location = useLocation();
  const { searchParams } = useSearchParams();
  const isProteomePath = Boolean(searchParams.get("proteomeId"));

  const { handleLogin } = useFileAndPageContext();

  const [href, setHref] = useState(null);
  useEffect(() => {
    setHref(window.location.href);
  }, [window.location.href]);

  // to access filterExpanded in the handleClick event listener ...
  const [filterExpanded, _setFilterExpanded] = useState(false);
  const filterExpandedRef = useRef(filterExpanded);

  const setFilterExpanded = (val) => {
    filterExpandedRef.current = val;
    _setFilterExpanded(val);
  };

  const handleClick = (event) => {
    const cName = event.target?.className;
    // console.log("[RC]", cName);
    if (
      filterExpandedRef.current &&
      typeof cName === "string" &&
      (cName.indexOf("AppRoot") > -1 ||
        cName.indexOf("PageTitle") > -1 ||
        cName.indexOf("DownloaderPanel") > -1 ||
        cName.indexOf("Header") > -1 ||
        cName.indexOf("MuiBox-root MuiBox-root-9") > -1 ||
        cName.indexOf("SearchInfoPanel") > -1)
    ) {
      event.stopPropagation();
      setFilterExpanded(false);
    }
  };
  useEffect(() => {
    if (topRef?.current) {
      topRef.current.addEventListener("click", handleClick);
      return () => {
        topRef.current.removeEventListener("click", handleClick);
      };
    }
  }, [topRef]);

  // handling redirect from old proteomeId query param to genome_id
  if (isProteomePath) {
    const fullUrl = `${location.pathname}${location.search}`;
    const redirectUrl = fullUrl.replace("proteomeId", "genome_id");
    return <Redirect to={redirectUrl} />;
  }

  if (ldClient) {
    const downtime = plannedNERSCOutageDowntime(ldClient);
    if (downtime && downtime !== "OFF") {
      throw new Error(ERR_NERSC_POWER_OUTAGE);
    }
    if (genericOutage(ldClient)) {
      throw new Error(ERR_SYSTEM_OUTAGE);
    }
  }

  // :segment is set first in src/index.js but this is the earliest place we can validate it
  // and throw a visible error.

  const { segment } = useParams();

  // Remove portal_ff when the LD FF my-data-portal is deleted
  if (portal_ff && segment === "mydata" && currentUser.name === "Anonymous") {
    handleLogin();
  }

  const config = appConfig(ldClient);
  if (portal_ff) {
    if (segment && segment !== "mydata" && !config[segment]) {
      throw new Error(ERR_NOT_FOUND);
    }
  } else if (segment && !config[segment]) {
    throw new Error(ERR_NOT_FOUND);
  }

  // We do a 2nd round of routing here (the 1st round was in index.js)
  // because we need to preserve the :segment URL parameter
  // downstream components that call useParams() (for now -- TODO make that unnecessary)
  // Kingdom-specific and search routes both use TableContainer,
  // since "/search" is a match for :segment.

  return (
    <Switch>
      {toSearchWithCart ? <Redirect to="/search?cart=true" /> : null}
      <Route exact path="/">
        <HomePageContainer {...props} />
      </Route>

      {portal_ff && (
        <Route path="/mydata">
          <MyDataPortal {...props} href={href} />
        </Route>
      )}

      <Route path="/refine-download/img/bulk">
        <ImgBulkDownload {...props} />
      </Route>

      <Route path="/refine-download/:segment/">
        <PageBrancher
          {...props}
          filterExpanded={filterExpanded}
          setFilterExpanded={setFilterExpanded}
        />
      </Route>
      <Route path="/:segment">
        <PageBrancher
          {...props}
          filterExpanded={filterExpanded}
          setFilterExpanded={setFilterExpanded}
        />
      </Route>
      <Route
        render={() => {
          throw new Error(ERR_NOT_FOUND);
        }}
      />
    </Switch>
  );
};

export default RoutingContainer;

RoutingContainer.defaultProps = {
  honeycomb: {
    sendUiInteractionSpan: () => {},
  },
  ldClient: null,
  topRef: null,
  currentUser: {},
};

RoutingContainer.propTypes = {
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
  }),
  ldClient: PropTypes.shape({
    variation: PropTypes.func,
  }),
  topRef: PropTypes.shape(),
  currentUser: PropTypes.shape(),
};
