/* istanbul ignore next */
import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

import Link from "@mui/material/Link";

import classes from "../DataGrid.module.css";

import NoDraggableDialog, {
  DLG_INFO,
} from "../../../UI/InfoBoard/Standalone/NoDraggableDialog";
import { DF_BTN_INFO } from "../../../UI/Dialogs/DialogFooter";

const tdNumStyle = {
  verticalAlign: "baseline",
  width: 20,
};

const OrcidHelpDialog = ({ cancelBtnFn }) => {
  return (
    <NoDraggableDialog
      title="In order to grant access..."
      type={DLG_INFO}
      btnColor={DF_BTN_INFO}
      width={800}
      // contentHeight={300}
      acceptBtnText="OK"
      acceptBtnFn={cancelBtnFn}
      cancelCrossFn={cancelBtnFn}
      subcontent={
        <Typography style={{ paddingLeft: 24, display: "flex" }}>
          More info can be found on the
          <Link
            href="https://sites.google.com/lbl.gov/data-portal-help/home/tips_tutorials/my-data-portal"
            target="_blank"
            sx={{ color: classes.lake500, marginLeft: "5px" }}
          >
            Data Portal Help Page
          </Link>
        </Typography>
      }
    >
      <table>
        <tbody>
          <tr>
            <td style={tdNumStyle}>
              <Typography>1.</Typography>
            </td>
            <td>
              <Typography>
                Have your target user create an ORCID{" "}
                <Link
                  href="https://orcid.org/signin"
                  target="_blank"
                  sx={{ color: classes.lake500 }}
                >
                  here
                </Link>
              </Typography>
            </td>
          </tr>
          <tr>
            <td style={tdNumStyle}>
              <Typography>2.</Typography>
            </td>
            <td
              style={{
                verticalAlign: "baseline",
              }}
            >
              <Typography>
                Have your target user associate their existing JGI login with
                their new ORCID{" "}
                <Link
                  href="https://contacts.jgi.doe.gov/edit_contact"
                  target="_blank"
                  sx={{ color: classes.lake500 }}
                >
                  here
                </Link>
              </Typography>
            </td>
          </tr>
          <tr>
            <td style={tdNumStyle}>
              <Typography>3.</Typography>
            </td>
            <td>
              <Typography>
                Return to this manage access page to grant access to your target
                user
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>
    </NoDraggableDialog>
  );
};

export default OrcidHelpDialog;

OrcidHelpDialog.propTypes = {
  cancelBtnFn: PropTypes.func.isRequired,
};
