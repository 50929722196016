/* istanbul ignore file */
import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";

import Badge from "@mui/material/Badge";
import md5 from "md5";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import ListItemText from "@mui/material/ListItemText";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import DownloadIcon from "@mui/icons-material/Download";

import { styled } from "@mui/material/styles";

import classes from "./Notifications.module.css";
import {
  setLocalStorageViewedNotices,
  getLocalStorageViewedNotices,
} from "../../../utils/LocalStorageUtils";

import userTool from "../../../utils/UserUtils";
import { announcements } from "../../../utils/LDFFUtils";
import IconWithCounterBadge from "../../UI/IconWithBadge/IconWithCounterBadge";

const DotBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: 4,
    top: 0,
    backgroundColor: "#EB0000",
    transform: "scale(1.2)",
  },
}));

// map of data.type from FF to icon
const ICONMAP = {
  new: (
    <NewReleasesIcon
      className={classes.NoticeTypeIcon}
      style={{ color: classes.lake400 }}
    />
  ),
  schedule: (
    <AccessAlarmsIcon
      className={classes.NoticeTypeIcon}
      style={{ color: classes.red500 }}
    />
  ),
  download: (
    <DownloadIcon
      className={classes.NoticeTypeIcon}
      style={{ color: classes.moss300 }}
    />
  ),
};

/*
 * for a given message item in FF, it data.end exists and is in future, return # of days remains;
 * return true if data.end is not defined; else return false;
 */
// const isActive = (notice) => {
//   let show = notice.show !== false; // no explicitly disabled
//   show = show && timeUtils.currentTimeIsIn(notice.start, notice.end);
//   return show;
// };

const MONTH_NAME = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const NoticeItem = ({ notice, nodot }) => {
  // component for a single message item in FF
  // return # of days left for the notice

  const date = notice.end ? new Date(notice.end) : null;
  const expiration = date
    ? `${MONTH_NAME[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
    : null;

  let msg = notice.message;
  if (notice.url) {
    msg += ` - <a href="${notice.url}" target="_blank"> show more`;
  }

  return (
    <>
      <div className={classes.NoticeContent}>
        <div style={{ position: "relative", top: 12 }}>
          <DotBadge
            color="secondary"
            variant="dot"
            invisible={nodot || notice.viewed}
          >
            {ICONMAP[notice.type]}
          </DotBadge>
        </div>
        <div
          style={{
            fontSize: 12,
            paddingLeft: 30,
            overflowWrap: "break-word",
            position: "relative",
            top: -12,
          }}
        >
          <Typography
            variant="subtitle2"
            gutterBottom
            component="div"
            style={{ fontWeight: "bold" }}
            className="notice-title"
          >
            {notice.title}
          </Typography>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: msg,
            }}
          />
        </div>

        {/* </div> */}
        {/* </div> */}
      </div>

      {expiration ? (
        <div className={classes.NoticeExpire}>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            align="right"
          >
            Expires on {expiration}
          </Typography>
        </div>
      ) : null}
    </>
  );
};

NoticeItem.propTypes = {
  notice: PropTypes.shape().isRequired,
  nodot: PropTypes.bool.isRequired,
};

const DOC_URL =
  "https://sites.google.com/lbl.gov/data-portal-help/home/announcements";

const Announcements = ({ ldClient, honeycomb, size, currentUser }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [noticeData, setNoticeData] = useState(null);

  const open = Boolean(anchorEl);

  useEffect(() => {
    const inLS = getLocalStorageViewedNotices();
    const noticeList = [];
    const md5list = [];

    const data = announcements(ldClient, userTool.userEmail(currentUser));

    const docURL = data?.docurl === undefined ? DOC_URL : data.docurl;

    if (data) {
      data.forEach((itm) => {
        let str = "";
        Object.keys(itm).forEach((kn) => {
          str += itm[kn];
        });
        const hash = md5(str);

        if (!itm.previewer && inLS.includes(hash)) {
          itm.viewed = true;
        }
        if (itm.previewer || !md5list.includes(hash)) {
          md5list.push(hash);
          noticeList.push(itm);
        }
      });
      if (noticeList.length > 0) {
        setNoticeData({ noticeList, md5list, docURL });
      }
    } else if (data && Object.keys(data).length > 0) {
      // not empty json - show no notice
      setNoticeData({ docURL });
    }
    // else - empty json : leave noticeData to null so to hide the UI entirely
  }, []);

  const handleClick = (event) => {
    // console.log(event.currentTarget, "[handleClick]");
    const trace = honeycomb.buildTrace(honeycomb.getTraceID());
    honeycomb.sendSpan({ name: "announcement-viewed" }, trace);

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (noticeData?.md5list) {
      const tmp = { ...noticeData };
      tmp.noticeList = tmp.noticeList.map((itm) => {
        const d = { ...itm };
        d.viewed = true;
        return d;
      });
      setNoticeData(tmp);
      setLocalStorageViewedNotices(noticeData.md5list);
    }

    setAnchorEl(null);
  };

  const activeCnt = noticeData?.noticeList
    ? noticeData.noticeList.reduce((a, n) => (!n.viewed ? a + 1 : a), 0)
    : 0;

  const noticeList = noticeData?.noticeList
    ? noticeData.noticeList.map((itm) => (
        <NoticeItem
          key={itm.message}
          notice={itm}
          nodot={activeCnt === 0 || activeCnt === noticeData.noticeList.length} // when there are other viewed messages, dot-label the new messages
        />
      ))
    : null;

  const docURL = noticeData ? noticeData.docURL : DOC_URL;
  const popmenu = (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      data-testid="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <ListItemText className={classes.MainHeader}>
        {noticeList === null ? (
          <ListItemText className={classes.MainHeader}>
            No announcements to view. Click the link below to view old
            announcements.
          </ListItemText>
        ) : (
          `Notifications (${noticeList.length})`
        )}
      </ListItemText>
      <Divider />

      {noticeList}
      {docURL ? (
        <ListItemText>
          <Link
            href={docURL}
            target="_blank"
            variant="body2"
            style={{
              marginLeft: 20,
              fontSize: 12,
              fontStyle: "italic",
            }}
          >
            {" "}
            Old Notifications
          </Link>
        </ListItemText>
      ) : null}
    </Menu>
  );

  return (
    <>
      <IconWithCounterBadge
        onClick={handleClick}
        size={size}
        count={activeCnt}
      />
      <div id="notice-content-container" style={{ height: 120 }}>
        {anchorEl ? popmenu : null}
      </div>
    </>
  );
};

export default Announcements;

Announcements.defaultProps = {
  ldClient: null,
  size: "medium",
  currentUser: null,
};
Announcements.propTypes = {
  ldClient: PropTypes.shape(),
  honeycomb: PropTypes.shape().isRequired,
  size: PropTypes.string,
  currentUser: PropTypes.shape(),
};
