import * as jstool from "./JSTools";

const pageTotal = (psize, ototal) => {
  return (ototal % psize > 0 ? 1 : 0) + parseInt(ototal / psize, 10);
};

// return organism index in cData.organisms array for the given org row.id
// return -1 if not found
const orgIndexOfOrgId = (cData, oid) => {
  if (!cData || !oid || !cData.organisms || !jstool.isDict(cData)) return -1;
  return cData.organisms.map((org) => org.id).indexOf(oid);
};

const allFileIdsInCart = (cData) =>
  cData.organisms?.reduce((list, org) => list.concat(org.files), []);

// always return array!
const fileIdsForOrgId = (cData, oid) => {
  const idx = orgIndexOfOrgId(cData, oid);
  if (idx > -1) {
    return cData.organisms[idx].files;
  }
  return [];
};

const fileCountInCart = (cData) => allFileIdsInCart(cData).length;

/**
 * cData: cart data
 * files: list of file objects
 * org: organisms - same file can appear in different orgs, so need org
 * return: bool
 */
const allFilesInCart = (cData, org) => {
  if (
    !cData ||
    !cData.organisms ||
    cData.organisms.length === 0 ||
    !org ||
    !org.id
  ) {
    return false;
  }

  const oid = orgIndexOfOrgId(cData, org.id);
  if (oid > -1) {
    const fileIds = cData.organisms[oid].files;
    const { files } = org;
    if (fileIds.length > 0) {
      return (
        files.filter((f) => fileIds.includes(f._id)).length === files.length
      );
    }
  }

  return false;
};

/**
 * cData: cart data
 * fid: file._id
 */
const fileInCart = (cData, oid, fid) => {
  if (!cData || !cData.organisms || !oid || !fid) {
    return false;
  }

  const orgIndex = orgIndexOfOrgId(cData, oid);
  if (orgIndex > -1) {
    const fileIds = cData.organisms[orgIndex].files;
    if (Array.isArray(fileIds)) {
      return fileIds.includes(fid);
    }
  }

  return false;
};

/*
  return cart file count of a given org id
*/
const inCartFileCount = (cData, orgId) => {
  // return cart file count of a given org id
  if (cData) {
    const orgIndex = cData.organisms.map((org) => org.id).indexOf(orgId);
    if (orgIndex > -1) {
      const fileIds = cData.organisms[orgIndex].files;
      if (Array.isArray(fileIds)) {
        return fileIds.length;
      }
    }
  }

  return 0;
};

export {
  orgIndexOfOrgId,
  fileIdsForOrgId,
  pageTotal,
  allFilesInCart,
  fileInCart,
  inCartFileCount,
  fileCountInCart,
};
