const FileIdListsEqual = (_arr1, _arr2) => {
  if (
    Array.isArray(_arr1) &&
    Array.isArray(_arr2) &&
    _arr1.length === _arr2.length
  ) {
    // .concat() to not mutate arguments
    const arr1 = _arr1.concat().sort();
    const arr2 = _arr2.concat().sort();

    // original array only holds file_id hash:
    if (arr1.join(",") === arr2.join(",")) {
      return true;
    }
  }

  return false;
};

// compare arry of primative values
const ArrayOfPrimativeEqual = (_arr1, _arr2) => {
  if (
    Array.isArray(_arr1) &&
    Array.isArray(_arr2) &&
    _arr1.length === _arr2.length
  ) {
    // convert elements to string; .concat() to not mutate arguments
    const arr1 = _arr1
      .map((item) => `${item}`)
      .concat()
      .sort();
    const arr2 = _arr2
      .map((item) => `${item}`)
      .concat()
      .sort();

    // original array only holds file_id hash:
    if (arr1.join(",") === arr2.join(",")) {
      return true;
    }
  }

  return false;
};

const FindDuplicates = (arr) =>
  arr.filter((item, index) => arr.indexOf(item) !== index);

const FindUniques = (arr) => {
  const unique = (val, ind, ar) => ar.indexOf(val) === ind;
  return arr.filter(unique);
};

/*
  check if an arr1 is subset of arr2
*/
const IsArrayInArray = (arr1, arr2) => {
  let isIn = true;
  for (let i = 0; i < arr1.length; i++) {
    if (!arr2.includes(arr1[i])) {
      isIn = false;
      break;
    }
  }

  return isIn;
};

export default {
  FileIdListsEqual,
  ArrayOfPrimativeEqual,
  FindDuplicates,
  FindUniques,
  IsArrayInArray,
};
