import { React } from "react";
import PropTypes from "prop-types";

import { List, ListItem, ListItemText, Menu, Link } from "@material-ui/core/";

import DUSChip from "../UI/DUSChip/DUSChip";

import classes from "./KebabMenu.module.css";
// import { base as ldbase } from "../../utils/LDFFUtils";
import { fileStatusTransform } from "../../utils/FileUtils";
import { STATUS_NOW } from "../../config/labelers";
import { NOT_IN_JAMO_META } from "../../config/appConfig";
import {
  HREF_DOC_RESTRICTED,
  HREF_DOC_ARCHIVED,
} from "../../utils/URLConstants";
import {
  DUS_LABEL_RST,
  DUS_LABEL_UNRST,
  DUS_META_VALUE_RST,
  DUS_META_VALUE_UNRST,
} from "./GenomeKebab";

const FileKebab = (props) => {
  const { honeycomb, anchorEl, setAnchorEl, row } = props;

  const handleKebabClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    honeycomb.sendUiInteractionSpan("clicked-file-kebab-close");
  };

  // const jdp_superset = ldbase(ldClient, "jdp-superset");
  // console.log(jdp_superset, ldClient, "[Filekebab]");

  const tipHeader = "Data policy: ";
  let dus = null;
  const duschipProps = {
    label: DUS_LABEL_UNRST,
    mleft: "0px",
    mbottom: "0px",
    mright: "5px",
    tooltip: `${tipHeader}: unrestricted, but citation is required.`,
  };

  if (row.metadata.data_utilization_status === DUS_META_VALUE_RST) {
    duschipProps.label = DUS_LABEL_RST;
    duschipProps.tooltip = "none";

    dus = (
      <Link target="_blank" href={HREF_DOC_RESTRICTED}>
        file restricted
      </Link>
    );
  } else if (row.metadata.data_utilization_status === DUS_META_VALUE_UNRST) {
    dus = (
      <Link target="_blank" href={HREF_DOC_RESTRICTED}>
        file unrestricted
      </Link>
    );
  }

  const duschip = <DUSChip {...duschipProps} />;
  const fileTypeCleanup = (data) => {
    // pick simple format if exists
    let val = null;
    const suffix = ".gz";
    if (!data) {
      return val;
    }

    if (typeof data === "string") {
      val = data;
      if (
        val.indexOf(suffix, val.length - suffix.length) > -1 &&
        val.length > 3
      ) {
        val = val.substring(0, val.length - 3);
      }
    } else if (Array.isArray(data) && data.length > 0) {
      [val] = data;
      data.every((element) => {
        if (element.indexOf(suffix, element.length - suffix.length) === -1) {
          val = element;
          return false;
        }
        return true;
      });
    }

    return val;
  };

  const metavalue = (keyname, container = row.metadata, prepend = null) => {
    let value = NOT_IN_JAMO_META;
    if (container && keyname in container) {
      value = container[keyname];
    }
    if (keyname.startsWith("dt_") || keyname.endsWith("_date")) {
      [value] = value.split("T");
    }
    return prepend ? `${prepend} ${value}` : value;
  };

  const status =
    fileStatusTransform(row.file_status) !== STATUS_NOW ? (
      <Link target="_blank" href={HREF_DOC_ARCHIVED}>
        Archived
      </Link>
    ) : (
      `Available for immediate download${
        row.dt_to_purge ? metavalue("dt_to_purge", row, " until") : ""
      }`
    );

  const metadataMap = [
    {
      title: "Type",
      key: metavalue("type"),
    },
    {
      title: "Subtype",
      key: metavalue("subtype"),
    },
    {
      title: "Content",
      key: metavalue("content"),
    },
    {
      title: "Source",
      key: metavalue("source"),
    },
    {
      title: "Collection",
      key: metavalue("collection"),
    },
    {
      title: "File format",
      key: fileTypeCleanup(row.file_type),
    },
    {
      title: "Date added",
      key: metavalue("added_date", row),
    },
    {
      title: "File Availability",
      key: status,
    },
    {
      title: "Data utilization policy",
      key: dus,
    },
  ];

  const supBy = row.metadata?.file_superseded_by; // TODO : will from file metadata
  if (supBy) {
    metadataMap.push({
      title: "Superseded by",
      key: supBy,
    });
  }

  // console.log(row, "[FileKebab]");
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleKebabClose}
      keepMounted
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      onClick={(e) => e.stopPropagation()}
    >
      <List>
        <ListItem
          classes={{
            root: `${classes.bodySmall} ${classes.listHeader}`,
          }}
        >
          File metadata
        </ListItem>
        <ListItem
          classes={{
            root: `${classes.h6Desktop} ${classes.listTitle}`,
          }}
        >
          {row.file_name}
          {row.superseded_by ? (
            <DUSChip label="OBSOLETE" mbottom="0px" />
          ) : null}
        </ListItem>
        {metadataMap.map((item, idx) => {
          const keyVal = `${item.key}-${idx}`;
          const chip =
            item.title === "Data utilization policy" ? (
              <div style={{ flexBasis: "50%" }}>
                {" "}
                {duschip}
                {item.key}
              </div>
            ) : null;
          return item.key ? (
            <ListItem
              key={keyVal}
              divider
              classes={{
                root: `${classes.listItem}`,
              }}
              {...item.liProps}
            >
              <ListItemText
                classes={{
                  primary: `${classes.bodySmall} ${classes.listItemTitle}`,
                  root: `${classes.listItemText}`,
                }}
                primary={item.title}
              />
              {chip}
              {item.title !== "Data utilization policy" ? (
                <ListItemText
                  classes={{
                    secondary: `${
                      item.key === NOT_IN_JAMO_META
                        ? classes.bodySmallDimItalic
                        : classes.bodySmall
                    }`,
                    root: `${classes.listItemText}`,
                  }}
                  secondary={item.key}
                  style={
                    item.title === "Proposal acceptance date"
                      ? { marginBottom: 0 }
                      : item.title === "Award DOI"
                      ? { marginTop: 0 }
                      : null
                  }
                />
              ) : null}
            </ListItem>
          ) : null;
        })}
      </List>
    </Menu>
  );
};

export default FileKebab;

FileKebab.defaultProps = {
  row: {},
  // honeycomb: {
  //   sendUiInteractionSpan: () => {},
  // },
  anchorEl: null,
  setAnchorEl: null,
  ldClient: null,
};

FileKebab.propTypes = {
  row: PropTypes.shape({
    file_name: PropTypes.string,
    added_date: PropTypes.string,
    file_status: PropTypes.string,
    type: PropTypes.string,
    subtype: PropTypes.string,
    dt_to_purge: PropTypes.string,
    file_type: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    metadata: PropTypes.shape(),
    superseded_by: PropTypes.string,
  }),
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
  }).isRequired,
  anchorEl: PropTypes.shape(),
  setAnchorEl: PropTypes.func,
  ldClient: PropTypes.shape(),
};
