/* istanbul ignore file */
import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

import classes from "../../UI/InfoBoard/InfoBoard.module.css";

const GlobusDownloadWrongUser = ({ globusUser, error }) => {
  return (
    <div id="wrong-globus-user" className={classes.block}>
      <Typography paragraph>Error in validating Globus user:</Typography>

      <Typography paragraph>
        {globusUser} - {error}
      </Typography>
    </div>
  );
};

export default GlobusDownloadWrongUser;

GlobusDownloadWrongUser.propTypes = {
  globusUser: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
};
