import React from "react";
import { PropTypes } from "prop-types";
import { useParams } from "react-router-dom";
import ConfigContext from "../../context/ConfigContext";

import appConfig from "../../config/appConfig";

const ConfigProvider = ({ children, ldClient }) => {
  const { segment } = useParams();

  const config = appConfig(ldClient);

  return (
    <ConfigContext.Provider
      value={{
        ...config[segment],
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;

ConfigProvider.defaultProps = {
  ldClient: null,
};

ConfigProvider.propTypes = {
  children: PropTypes.element.isRequired,
  ldClient: PropTypes.shape(),
};
