// to be used in useApi as raw data to replace BE returned;
const data = {
  timings: {
    total: "0.006",
    file_fetch: "0.001",
    organisms: "0.002",
    facets: "0.000",
    count_file_total: "0.000",
  },
  hits_total: 2474,
  file_total: 2956,
  unique_file_total: 2956,
  total: 82,
  prioritized_file_total: 1319,
  prioritized_total: 73,
  score_threshold: 8,
  start: 1,
  end: 2,
  next_page: 2,
  cursor_id:
    "6d685b15f049c80c88bae32fab5d15f46336c1dbe7197af3ac1cbce01ecf124a:1",
  organisms: [
    {
      superseded_by: "Artbe1",
      successor_name: "Yellowstone National Park, Obsidian Pool itags",
      agg: "IMG_SP",
      agg_id: 1017761,
      kingdom: "img",
      label: "Metagenome",
      top_hit: {
        _id: "51e2e41a067c014b2c660955",
        _highlight: null,
        metadata: {
          proposal: {
            pi: {
              country: "United States",
              institution: "PNNL",
              email_address: "jim.fredrickson@pnnl.gov",
              last_name: "Fredrickson",
              middle_name: "",
              first_name: "Jim",
              name: "Jim Fredrickson",
            },
            date_approved: "2011-09-30",
          },
          ncbi_taxon: {
            ncbi_taxon_species: "hot springs metagenome",
          },
          sequencing_project: {
            sequencing_project_name: "Hot spring itags",
            scientific_program_name: "Metagenome",
          },
          sequencing_project_id: 1017761,
          final_deliv_project: {
            final_deliv_project_name: "Hot spring itags",
          },
        },
        added_date: "2014-06-19T11:00:31.534000",
        file_name: "7216.1.62965.CCTGGATATAG.fastq.gz",
        user: "sdm",
      },
      pi: {
        country: "United States",
        institution: "PNNL",
        email_address: "jim.fredrickson@pnnl.gov",
        last_name: "Fredrickson",
        middle_name: "",
        first_name: "Jim",
        name: "Jim Fredrickson",
      },
      proposal_acceptance_date: "2011-09-30",
      work_completion_date: null,
      status: null,
      id: "IMG_SP-1017761",
      grouped_by: "metadata.sequencing_project_id",
      score: {
        count: 101,
        min: 218.8787078857422,
        max: 253.280517578125,
        avg: 235.0555877685547,
        sum: 23740.614364624023,
      },
      grouped_count: 101,
      name: "Hot spring itags",
      title: "hot springs metagenome",
      file_total: 102,
      files: [
        {
          _id: "53c41b690d8785388cd43e3b",
          _highlight: null,
          file_path: "/global/dna/dm_archive/qaqc/analyses/AUTO-6090",
          file_status_id: 10,
          metadata: {
            superseded_by: "PortalID",
            file_superseded_by: "53c41b680d8785388cd43e36",
            proposal: {
              award_doi: "10.46936/10.25585/60000772",
              pi: {
                country: "United States",
                institution: "PNNL",
                email_address: "jim.fredrickson@pnnl.gov",
                last_name: "Fredrickson",
                middle_name: "",
                first_name: "Jim",
              },
              date_approved: "2011-09-30",
              doi: "10.25585/1488064",
            },
            // data_utilization_status: "Restricted",
            gold_data: {
              ecosystem: "Environmental",
              ecosystem_category: "Aquatic",
              ecosystem_type: "Thermal springs",
            },
            ncbi_taxon: {
              ncbi_taxon_species: "hot springs metagenome",
            },
            sequencing_project: {
              scientific_program_name: "Metagenome",
            },
            ncbi_taxon_id: 433727,
            sequencing_project_id: 1017761,
            portal: {
              display_location: ["itags", "16S", ""],
            },
            file_format: "tar",
          },
          file_status: "PURGED",
          added_date: "2014-07-14T11:03:21.945000",
          file_name: "data.tar.bz2",
          file_owner: "mbarton",
          modified_date: "2022-05-24T14:20:23.504000",
          dt_to_purge: null,
          file_size: 2384116541,
          file_date: "2014-07-11T22:47:41",
          md5sum: "d51b746b11b56386233f358dc11daba6",
          file_type: ["report", "paired_end_reads", "merged_reads"],
          file_id: 3505361,
          file_group: "genome",
          user: "mbarton",
          file_permissions: "0100640",
          data_group: "Sequencing",
          portal_detail_id: null,
        },
        {
          _id: "53c41b680d8785388cd43e36",
          _highlight: null,
          file_path: "/global/dna/dm_archive/qaqc/analyses/AUTO-6090",
          file_status_id: 10,
          metadata: {
            proposal: {
              award_doi: "10.46936/10.25585/60000772",
              pi: {
                country: "United States",
                institution: "PNNL",
                email_address: "jim.fredrickson@pnnl.gov",
                last_name: "Fredrickson",
                middle_name: "",
                first_name: "Jim",
              },
              date_approved: "2011-09-30",
              doi: "10.25585/1488064",
            },
            data_utilization_status: "Restricted",
            gold_data: {
              ecosystem: "Environmental",
              ecosystem_category: "Aquatic",
              ecosystem_type: "Thermal springs",
            },
            ncbi_taxon: {
              ncbi_taxon_species: "hot springs metagenome",
            },
            sequencing_project: {
              scientific_program_name: "Metagenome",
            },
            ncbi_taxon_id: 433727,
            sequencing_project_id: 1017761,
            portal: {
              display_location: ["itags", "16S", ""],
            },
            file_format: "text",
          },
          file_status: "PURGED",
          added_date: "2014-07-14T11:03:20.150000",
          file_name: "README.txt",
          file_owner: "mbarton",
          modified_date: "2022-05-24T14:20:23.515000",
          dt_to_purge: null,
          file_size: 7962,
          file_date: "2014-07-14T11:03:13",
          md5sum: "8cae7debbcb584a26a03e38dc21c2f9e",
          file_type: ["report"],
          file_id: 3505356,
          file_group: "genome",
          user: "mbarton",
          file_permissions: "0100644",
          data_group: "Sequencing",
          portal_detail_id: null,
        },
      ],
      fileSize: 4916886380,
      highlight: {},
      award_doi: "10.46936/10.25585/60000772",
      doi: "10.25585/1488064",
      data_utilization_status: "Restricted",
      build_time: 0.0040454864501953125,
    },
    {
      agg: "IMG_SP",
      agg_id: 1021297,
      kingdom: "img",
      label: "Metagenome",
      top_hit: {
        _id: "53c035160d8785134026efc0",
        _highlight: null,
        metadata: {
          proposal: {
            pi: {
              country: "United States",
              institution: "North Carolina State University",
              email_address: "rmkelly@ncsu.edu",
              last_name: "Kelly",
              middle_name: "",
              first_name: "Robert",
              name: "Robert Kelly",
            },
            date_approved: "2012-11-12",
          },
          ncbi_taxon: {
            ncbi_taxon_species: "hot springs metagenome",
          },
          sequencing_project: {
            sequencing_project_name:
              "Yellowstone National Park, Obsidian Pool itags",
            scientific_program_name: "Metagenome",
          },
          sequencing_project_id: 1021297,
          final_deliv_project: {
            final_deliv_project_name:
              "Yellowstone National Park, Obsidian Pool itags",
          },
        },
        added_date: "2014-07-11T12:03:50.662000",
        file_name: "qc_report.yaml",
        user: "mbarton",
      },
      pi: {
        country: "United States",
        institution: "North Carolina State University",
        email_address: "rmkelly@ncsu.edu",
        last_name: "Kelly",
        middle_name: "",
        first_name: "Robert",
        name: "Robert Kelly",
      },
      proposal_acceptance_date: "2012-11-12",
      work_completion_date: null,
      status: null,
      id: "IMG_SP-1021297",
      grouped_by: "metadata.sequencing_project_id",
      score: {
        count: 22,
        min: 11.419027328491211,
        max: 202.0457763671875,
        avg: 188.884409384294,
        sum: 4155.457006454468,
      },
      grouped_count: 22,
      name: "Yellowstone National Park, Obsidian Pool itags",
      title: "hot springs metagenome",
      file_total: 22,
      files: [
        {
          _id: "58e6d6147ded5e52c4b01d68",
          _highlight: null,
          file_status_id: 10,
          file_path: "/global/projectb/scratch/brycef/itag-old/itag",
          metadata: {
            proposal: {
              award_doi: "10.46936/10.25585/60007560",
              pi: {
                country: "United States",
                institution: "North Carolina State University",
                email_address: "rmkelly@ncsu.edu",
                last_name: "Kelly",
                middle_name: "",
                first_name: "Robert",
              },
              date_approved: "2012-11-12",
              doi: "10.25585/1487563",
            },
            data_utilization_status: "Unrestricted",
            sequencing_project: {
              scientific_program_name: "Metagenome",
            },
            sequencing_project_id: 1021297,
            portal: {
              display_location: ["itags"],
            },
          },
          file_status: "PURGED",
          added_date: "2017-04-06T16:58:12.223000",
          file_name: "1021297.tar",
          file_owner: "brycef",
          modified_date: "2022-05-22T16:33:20.713000",
          dt_to_purge: "2017-10-03T16:58:11",
          file_size: 801454080,
          file_date: "2017-04-06T11:29:11",
          md5sum: "09d5ac2bf3243ceaa8c8fe0d3e353f37",
          file_type: ["tar"],
          file_id: 6295824,
          file_group: "genome",
          user: "rqc",
          file_permissions: "0100640",
          data_group: "Sequencing",
          portal_detail_id: null,
        },
        {
          _id: "52471acb067c0136350e40cd",
          _highlight: null,
          file_status_id: 13,
          file_path: "/global/dna/dm_archive/sdm/illumina/00/73/84",
          metadata: {
            proposal: {
              award_doi: "10.46936/10.25585/60007560",
              pi: {
                country: "United States",
                institution: "North Carolina State University",
                email_address: "rmkelly@ncsu.edu",
                last_name: "Kelly",
                middle_name: "",
                first_name: "Robert",
              },
              date_approved: "2012-11-12",
              doi: "10.25585/1487563",
            },
            data_utilization_status: "Unrestricted",
            gold_data: {
              ecosystem_subtype: "Hot (42-90C)",
              ecosystem: "Environmental",
              ecosystem_category: "Aquatic",
              ecosystem_type: "Thermal springs",
            },
            ncbi_taxon: {
              ncbi_taxon_species: "hot springs metagenome",
            },
            sequencing_project: {
              scientific_program_name: "Metagenome",
            },
            ncbi_taxon_id: 433727,
            sequencing_project_id: 1021297,
            portal: {
              display_location: ["Raw Data"],
            },
          },
          file_status: "RESTORED",
          added_date: "2013-09-28T11:07:07.185000",
          file_name: "7384.1.69743.ACTGTCGAAGC.fastq.gz",
          file_owner: "sdm",
          modified_date: "2022-05-24T15:15:08.538000",
          dt_to_purge: "2022-06-09T00:00:00",
          file_size: 150309356,
          file_date: "2013-09-28T11:07:06",
          md5sum: "08341265fca9c7f5c41ce891c6ccde26",
          file_type: ["fastq.gz", "fastq"],
          file_id: 2934202,
          file_group: "genome",
          user: "sdm",
          file_permissions: "0100644",
          data_group: "Sequencing",
          portal_detail_id: null,
        },
      ],
      fileSize: 3069862763,
      highlight: {},
      award_doi: "10.46936/10.25585/60007560",
      doi: "10.25585/1487563",
      data_utilization_status: "Unrestricted",
      build_time: 0.0011756420135498047,
    },
  ],
  facets: {
    meta_type: {},
    meta_subtype: {},
    meta_content: {},
    meta_source: {},
    meta_collection: {},
    meta_file_format: {
      text: 311,
      tabix_index: 220,
      bgzip: 120,
      zip: 102,
      vcf: 100,
      bam: 51,
      bam_index: 51,
      flagstat: 51,
      xslx: 50,
      fastq: 43,
      tar: 43,
      html: 31,
    },
    file_status: {
      PURGED: 2324,
      RESTORED: 150,
    },
    file_type: {
      fastq: 1288,
      "fastq.gz": 1241,
      report: 985,
      alignment: 102,
      pdf: 53,
      logs: 51,
      chaff: 43,
      curated: 43,
      sff: 2,
      "sff.bz2": 2,
    },
    data_type: {
      "Raw Data": 1224,
      Resequencing: 958,
      "Structural Variation": 528,
      "Filtered Raw Data": 238,
      "Sequencing QC Reports": 53,
      Readme: 51,
      "IMG Data": 1,
    },
    phytozome_version: {},
    data_group: {
      Analysis: 959,
      Sequencing: 1515,
      Other: 0,
    },
    data_utilization_status: {
      Restricted: 8,
      Unrestricted: 2466,
    },
    ecosystem: {
      "Host-associated": 6,
      Engineered: 18,
    },
    ecosystem_category: {
      Mammals: 1,
      Plants: 1,
    },
    ecosystem_subtype: {
      Foregut: 1,
      Epiphytes: 1,
    },
    ecosystem_type: {
      "Digestive system": 1,
      Rhizoplane: 1,
    },
    specific_ecosystem: {
      Rumen: 1,
      Unclassified: 1,
    },
    ncbi_taxon_order: {
      Enterobacterales: 69,
      Sphingomonadales: 2,
      Boletales: 1,
      Mortierellales: 1,
      Agaricales: 1,
    },
    ncbi_taxon_class: {
      Gammaproteobacteria: 69,
      Alphaproteobacteria: 2,
      Agaricomycetes: 2,
      Mortierellomycetes: 1,
    },
    ncbi_taxon_family: {
      Enterobacteriaceae: 66,
      Sphingomonadaceae: 2,
      Mortierellaceae: 1,
      Suillaceae: 1,
      Yersiniaceae: 2,
      Erwiniaceae: 1,
      Pluteaceae: 1,
    },
    ncbi_taxon_genus: {
      Escherichia: 66,
      Novosphingobium: 2,
      Linnemannia: 1,
      Suillus: 1,
      Pantoea: 1,
      Pluteus: 1,
      Rahnella: 1,
      Serratia: 1,
    },
    phytozome_policy: {},
    phytozome_project: {},
  },
  fields: [
    "added_date",
    "dt_to_purge",
    "file_*",
    "md5sum",
    "metadata.data_utilization_status",
    "metadata.analysis_project.status*",
    "metadata.analysis_project_id",
    "metadata.collection",
    "metadata.content",
    "metadata.file_format",
    "metadata.gold_data.display_name",
    "metadata.gold_data.ecosystem",
    "metadata.gold_data.ecosystem_category",
    "metadata.gold_data.ecosystem_subtype",
    "metadata.gold_data.ecosystem_type",
    "metadata.gold_data.gold_project_url",
    "metadata.gold_data.gold_stamp_id",
    "metadata.gold_data.organism_name",
    "metadata.gold_data.specific_ecosystem",
    "metadata.img",
    "metadata.mycocosm_portal*",
    "metadata.ncbi_taxon.ncbi_taxon_class",
    "metadata.ncbi_taxon.ncbi_taxon_family",
    "metadata.ncbi_taxon.ncbi_taxon_genus",
    "metadata.ncbi_taxon.ncbi_taxon_order",
    "metadata.ncbi_taxon.ncbi_taxon_species",
    "metadata.ncbi_taxon_id",
    "metadata.phycocosm_portal*",
    "metadata.phytozome",
    "metadata.pi_name",
    "metadata.pmo_project.name",
    "metadata.pmo_project.pi_name",
    "metadata.pmo_project.scientific_program",
    "metadata.pmo_project_id",
    "metadata.portal",
    "metadata.proposal.award_doi",
    "metadata.proposal.date_approved",
    "metadata.proposal.doi",
    "metadata.proposal.pi",
    "metadata.sequencing_project.scientific_program_name",
    "metadata.sequencing_project_id",
    "metadata.source",
    "metadata.sow_segment.sequencing_project_name",
    "metadata.subtype",
    "metadata.type",
    "modified_date",
    "user",
  ],
  timeout: 180,
};

export default data;
