import React, { Component } from "react";
import { StylesProvider } from "@material-ui/core/styles";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { render } from "@testing-library/react";
import { act } from "react-dom/test-utils";

// keycloak HOC wrapper:
import keycloak from "./keycloak";

/** function from material ui src to build a safe class name prefix */
const safePrefix = (classNamePrefix) => {
  const prefix = String(classNamePrefix);
  const escapeRegex = /([[\].#*$><+~=|^:(),"'`\s])/g;
  return prefix.replace(escapeRegex, "-");
};

/** Build a class name that does not include a unique number on the end */
const generateStaticClassName = (rule, styleSheet) => {
  return `${safePrefix(styleSheet.options.classNamePrefix)}-${rule.key}`;
};

/** Render a static class name component */
const renderWithStaticClassNames = (ui, options) => {
  // eslint-disable-next-line react/prop-types
  const Wrapper = ({ children }) => (
    <StylesProvider generateClassName={generateStaticClassName}>
      {children}
    </StylesProvider>
  );

  return render(ui, { wrapper: Wrapper, ...options });
};

const CKTestWrapper = (comp) => (
  <ReactKeycloakProvider authClient={keycloak}>{comp}</ReactKeycloakProvider>
);
/*
 * To solve the warning for not wrapped with act()
 * when component state is updated asyncly in useEffect()
 */
const waitForComponentToPaint = async (wrapper) => {
  await act(async () => {
    await new Promise((resolve) => setTimeout(resolve, 0));
    wrapper.update();
  });
};

// Error Boundary
class TestErrorBoundary extends Component {
  componentDidCatch() {}

  render() {
    return null;
  }
}

/*
  use jdptest=TAG:VALUE in URL for testing specific code path that is hard to generate from 
  normal operations, such as HTTP request errors.
  the app will use this function to parse the VALUE
*/
const testValue = (tag) => {
  const searchParams = new URLSearchParams(window.location.search);
  let tagVal = null;
  if (searchParams.has("jdptest")) {
    const value = searchParams.get("jdptest");
    if (value.indexOf(tag) === 0) {
      tagVal = value.substring(tag.length + 1, value.length);
    }
  }
  return tagVal;
};

// eslint-disable-next-line import/prefer-default-export
export {
  renderWithStaticClassNames,
  CKTestWrapper,
  waitForComponentToPaint,
  TestErrorBoundary,
  testValue,
};
