import React from "react";

import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";

import classes from "./InfoBoard.module.css";

const CrossClose = ({ close, size }) => {
  return close ? (
    <div className={classes.flexTop} style={{ width: "100%" }}>
      <div style={{ marginLeft: "auto", cursor: "pointer" }} title="close">
        <CloseIcon fontSize={size} onClick={close} />
      </div>
    </div>
  ) : null;
};

export default CrossClose;

CrossClose.defaultProps = {
  close: null,
  size: "large",
};

CrossClose.propTypes = {
  close: PropTypes.func,
  size: PropTypes.string,
};
