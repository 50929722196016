import React from "react";
import PropTypes from "prop-types";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import Button from "../../Button/Button";
import classes from "./DraggableDialog/DraggableDialog.module.css";

// button types
export const DF_BTN_SUCCESS = "DF_BTN_SUCCESS";
export const DF_BTN_INFO = "DF_BTN_INFO";
export const DF_BTN_ERROR = "DF_BTN_ERROR";
export const DF_BTN_WARNING = "DF_BTN_WARNING";
export const DF_BTN_ORANGE_BIG = "DF_BTN_ORANGE_BIG";

const colorTranslation = {
  DF_BTN_SUCCESS: "primary",
  DF_BTN_INFO: "secondary",
  DF_BTN_ERROR: "red",
  DF_BTN_WARNING: "orange",
};

const colorFunc = (color) => {
  if (color === DF_BTN_SUCCESS) {
    return "primary";
  }
  if (color === DF_BTN_INFO) {
    return "secondary";
  }
  return "inherit";
};

const DialogFooter = (props) => {
  const {
    btnColor,
    cancelBtnFn,
    acceptBtnFn,
    cancelBtnText,
    acceptBtnText,
    cancelBtnDisabled, // show disabled button, if has BtnText
    acceptBtnDisabled, // show disabled button, if has BtnText
    checkBtnText,
    checkBtnFn,
    acceptBtnIcon, // the Accept button's icon option
    acceptBtnTooltip,
  } = props;

  // console.log("[DialogFooter]", acceptBtnIcon, acceptBtnTooltip);

  let size = "small";
  let cancelClsName = null;
  let acceptClsName = null;
  if (btnColor === DF_BTN_ORANGE_BIG) {
    acceptClsName = classes.orangeButton;
    cancelClsName = classes.orangeBorderButton;
    size = "large";
  } else {
    acceptClsName = colorTranslation[btnColor];
    cancelClsName = acceptClsName;
  }

  const btnMinWidth = "110px";

  const getCheckButton = () => {
    return checkBtnText && checkBtnFn ? (
      <FormControlLabel
        label={checkBtnText}
        control={
          <Checkbox
            onChange={(e) => {
              checkBtnFn(e);
            }}
          />
        }
        style={{}}
      />
    ) : null;
  };

  const getAcceptButton = () => {
    const btn =
      acceptBtnFn && acceptBtnText ? (
        <Button
          toolTip={acceptBtnTooltip}
          color={colorFunc(btnColor)}
          size={size}
          // click={acceptBtnTooltip ? acceptBtnFn : null}
          onClick={acceptBtnFn}
          className={acceptClsName}
          style={{ marginLeft: "auto", minWidth: btnMinWidth }}
          variant="contained"
          id="dialog-footer-accept-button"
          data-testid="dialog-footer-accept-button"
          disabled={acceptBtnDisabled}
          icon={acceptBtnIcon}
        >
          {acceptBtnText}
        </Button>
      ) : null;

    return btn;
  };

  const getCancelButton = () => {
    return cancelBtnFn && cancelBtnText ? (
      <Button
        color={colorFunc(btnColor)}
        size={size}
        onClick={cancelBtnFn}
        className={cancelClsName}
        style={{ marginRight: 10, minWidth: btnMinWidth }}
        variant="outlined"
        id="dialog-footer-cancel-button"
        data-testid="dialog-footer-cancel-button"
        disabled={cancelBtnDisabled}
      >
        {cancelBtnText}
      </Button>
    ) : null;
  };

  return (
    <div className={classes.buttonContainer}>
      {getCheckButton()}
      {getAcceptButton()}
      {getCancelButton()}
    </div>
  );
};

export default DialogFooter;
DialogFooter.defaultProps = {
  acceptBtnIcon: null,
  acceptBtnTooltip: null,
  btnColor: "",
  cancelBtnFn: null,
  acceptBtnFn: null,
  cancelBtnText: "Cancel",
  acceptBtnText: "Accept",
  cancelBtnDisabled: false,
  acceptBtnDisabled: false,
  checkBtnText: null,
  checkBtnFn: null,
};

DialogFooter.propTypes = {
  acceptBtnIcon: PropTypes.string,
  acceptBtnTooltip: PropTypes.string,
  btnColor: PropTypes.string,
  cancelBtnText: PropTypes.string,
  cancelBtnFn: PropTypes.func,
  acceptBtnFn: PropTypes.func,
  checkBtnText: PropTypes.string,
  checkBtnFn: PropTypes.func,
  acceptBtnText: PropTypes.string,
  cancelBtnDisabled: PropTypes.bool,
  acceptBtnDisabled: PropTypes.bool,
};
