import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import classes from "./InfoMessage.module.css";

export const MSG_SYSTEM_OUTAGE = "MSG_SYSTEM_OUTAGE";

export default function InfoMessage(props) {
  const { type, text, honeycomb } = props;
  const [rendered, setRendered] = useState(false);
  let { title, honeycombMsg } = {};

  switch (type) {
    case MSG_SYSTEM_OUTAGE:
      title = "Upcoming system outage";
      honeycombMsg = "upcoming-system-outage";
      break;
    default:
      break;
  }

  useEffect(() => {
    if (honeycombMsg && !rendered) {
      honeycomb.sendProcessingSpan(`show-message-${honeycombMsg}`);
      setRendered(true);
    }
  }, [honeycomb, honeycombMsg, rendered]);

  return honeycombMsg ? (
    <Grid container className={classes.gridContainer}>
      <Grid item className={classes.background}>
        <Grid
          container
          spacing={2}
          wrap="nowrap"
          className={classes.warningContainer}
        >
          <Grid item>
            <InfoIcon color="action" fontSize="large" />
          </Grid>
          <Grid item>
            <Typography variant="h1" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="subtitle1" className={classes.message}>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
}

InfoMessage.defaultProps = {
  honeycomb: {
    sendProcessingSpan: () => {},
  },
};

InfoMessage.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  honeycomb: PropTypes.shape({
    sendProcessingSpan: PropTypes.func,
  }),
};
