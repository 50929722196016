/* istanbul ignore file */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { initialize } from "launchdarkly-js-client-sdk";

const withLaunchdarkly = (WrappedComponent) => {
  const LaunchDarklyHOC = (props) => {
    const [ldClientMaster, setLdClientMaster] = useState(null);
    const [ldClientMimic, setLdClientMimic] = useState(null);
    const { currentUser, initializingUser } = props;

    useEffect(() => {
      const context = {
        kind: "user",
        name: currentUser?.name?.toLowerCase(), // email
        key: currentUser?.key?.toLowerCase(),
        anonymous: currentUser?.key === "Anonymous",
      };
      if (!initializingUser && !ldClientMaster) {
        const client = initialize(process.env.REACT_APP_LD_KEY, context);

        client.on("ready", () => {
          // It's now safe to request feature flags
          setLdClientMaster(client);
        });

        const mimicEmail = currentUser.mimic?.email_address?.toLowerCase();
        if (mimicEmail) {
          const clientMimic = initialize(process.env.REACT_APP_LD_KEY, context);

          if (clientMimic) {
            clientMimic.on("ready", () => {
              setLdClientMimic(clientMimic);
            });
          }
        }

        // The below is are examples of how we could subscribe to flag updates (specific flag or all)
      }
    }, [initializingUser]);

    return (
      <WrappedComponent
        {...props}
        ldClientMaster={ldClientMaster}
        ldClient={ldClientMimic || ldClientMaster}
      />
    );
  };

  LaunchDarklyHOC.defaultProps = {
    currentUser: null,
    ldClient: null,
    ldClientMimic: null,
    initializingUser: true,
  };

  LaunchDarklyHOC.propTypes = {
    currentUser: PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string,
      mimic: PropTypes.shape({ email_address: PropTypes.string }),
    }),
    initializingUser: PropTypes.bool,
    ldClient: PropTypes.objectOf(Object),
    ldClientMimic: PropTypes.objectOf(Object),
  };

  return LaunchDarklyHOC;
};

export default withLaunchdarkly;
