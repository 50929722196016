import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Collapse, TableCell, TableRow } from "@material-ui/core/";

import classes from "./GenomeTable.module.css";

import GenomeTableCells from "./GenomeTableCells/GenomeTableCells";
import FileTable from "../FileTable/FileTable";
import { selectedForRow } from "../../utils/FileUtils";

import { useSearchParams } from "../../hooks/useSearchParams";
// import { base as ldbase } from "../../utils/LDFFUtils";
import * as cartTool from "../../utils/CartDataTools";

import {
  ROW_SELECTED_NONE,
  ROW_SELECTED_SOME,
  ROW_SELECTED_ALL,
  ROW_ALL_IN_CART,
} from "./Constants";

const GenomeRow = ({
  row,
  selectedFiles,
  setSelectedFiles, // SearchPageContainer.setSelectedFiles
  expanded,
  onRowClick,
  handleGenomeLevelCheckboxClicked,
  ldClient,
  cartData,
  restoreid,
  ...props
}) => {
  const { cart: cartpage } = useSearchParams();

  const [selectState, setSelectState] = useState(ROW_SELECTED_NONE); // 0: none selected; 1: some selected, 2: all selected

  useEffect(() => {
    if (row || selectedFiles) {
      const selIds = selectedForRow(selectedFiles, row.id);
      const cartIds = cartTool.fileIdsForOrgId(cartData, row.id);

      let state = ROW_SELECTED_NONE;

      if ((!restoreid || cartpage) && cartIds.length === row.files.length) {
        state = ROW_ALL_IN_CART;
      } else if (selIds.length > 0) {
        if (
          selIds.length + (restoreid ? 0 : cartIds.length) ===
          row.files.length
        ) {
          state = ROW_SELECTED_ALL;
        } else {
          state = ROW_SELECTED_SOME;
        }
      }

      if (state !== selectState) {
        setSelectState(state);
      }
    }
  }, [row, selectedFiles]);

  const [isHovering, setHovering] = useState(false);

  const enter = () => {
    setHovering(true);
  };

  const leave = () => {
    setHovering(false);
  };

  return (
    <>
      <TableRow
        key={row.id}
        data-testid="GenomeTableRow"
        id={`GenomeTableRow-${row.id}`}
        className={expanded ? classes.rowExpand : classes.row}
        onMouseEnter={enter}
        onMouseLeave={leave}
      >
        <GenomeTableCells
          row={row}
          onRowClick={onRowClick}
          expanded={expanded}
          isHovering={isHovering}
          selectState={selectState}
          ldClient={ldClient}
          cartData={cartData}
          restoreid={restoreid}
          {...props}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
      </TableRow>
      <TableRow key={`${row.id}-files-table`}>
        <TableCell padding="none" colSpan={7}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {/* the file table under the genome row, when expanded */}
            <FileTable
              {...props}
              fileGrouping={row}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              onSelectAllClicked={() => handleGenomeLevelCheckboxClicked(row)}
              selectState={selectState}
              rowId={row.id}
              ldClient={ldClient}
              cartData={cartData}
              restoreid={restoreid}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default GenomeRow;

GenomeRow.defaultProps = {
  selectedFiles: null,
  row: {},
  onRowClick: () => {},
  handleGenomeLevelCheckboxClicked: () => {},
  expanded: false,
  honeycomb: {
    sendUiInteractionSpan: () => {},
  },
  props: null,
  ldClient: null,
  cartData: null,
  restoreid: null,
};

GenomeRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.shape()),
  }),
  expanded: PropTypes.bool,
  onRowClick: PropTypes.func,
  handleGenomeLevelCheckboxClicked: PropTypes.func,
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
  }),
  props: PropTypes.shape(),
  ldClient: PropTypes.shape(),
  setSelectedFiles: PropTypes.func.isRequired,
  selectedFiles: PropTypes.arrayOf(PropTypes.shape()),
  cartData: PropTypes.shape(),
  restoreid: PropTypes.string,
};
