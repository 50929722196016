import Cookies from "universal-cookie";
/**
options (object): Support all the cookie options from RFC 6265
   - path (string): cookie path, use / as the path if you want your cookie to be accessible on all pages
   - expires (Date): absolute expiration date for the cookie
   - maxAge (number): relative max age of the cookie from when the client receives it in seconds
   - domain (string): domain for the cookie (sub.domain.com or .allsubdomains.com)
   - secure (boolean): Is only accessible through HTTPS?
   - httpOnly (boolean): Is only the server can access the cookie?
   - sameSite (boolean|none|lax|strict): Strict or Lax enforcement
 */
const DOMAIN = "jgi.doe.gov";
const SSO_COOKIE_KEY = "jgi_session";
const KC_COOKIE_KEY = "kc_session"; // keycloak session cookie key
const DUP_COOKIE_KEY = "dup_agreed";
const CONTACT_USER_COOKIE_KEY = "mimic_contact_id";
const PMOS_SERVER = "pmos_env";

const SECONDS_IN_A_DAY = 24 * 60 * 60;

export const HCTRACE_COOKIE_KEY = "X_Honeycomb_Trace";

const api = new Cookies();

const defaultOpts = { path: "/", domain: DOMAIN, sameSite: "Strict" };

const get = (key) => api.get(key); // auto decoded - unescape()
const set = (key, value = "", opts = defaultOpts) => api.set(key, value, opts);
const remove = (key, opts = defaultOpts) => api.remove(key, opts);

// so returns to this site after sso logout
const setSSOReturnURL = () => {
  const url = escape(window.location.href);
  document.cookie = `jgi_return=${url}; domain=${DOMAIN}; path=/;`;
  // set("jgi_return", url);  // Didn't work !!
};

const clearnKey = (key) =>
  key ? key.replace("@", "_").replace(" ", "_").replace(".", "_") : key;

const setKCSessionCookie = (token) => set(KC_COOKIE_KEY, token);

const getSSOCookie = () => get(SSO_COOKIE_KEY);
const getKCSessionCookie = () => get(KC_COOKIE_KEY);

// DUP related cookie tools; ver : must be a string
const getDUPCookie = (key, ver) => {
  const ckey = `${DUP_COOKIE_KEY}_${clearnKey(key)}`;
  const val = get(ckey);
  // console.log(ckey, val, ver, "[getDUPCookie]");
  return val === ver;
};

const setDUPCookie = (key, ver, duration = 3 * 30 * SECONDS_IN_A_DAY) => {
  const opts = { ...defaultOpts };
  opts.maxAge = duration; // default to 3 month (90 days)
  set(`${DUP_COOKIE_KEY}_${clearnKey(key)}`, ver, opts);
};
const rmDUPCookie = (key) => remove(`${DUP_COOKIE_KEY}_${clearnKey(key)}`);
const isDUPAgreed = (key, ver) => getDUPCookie(key, ver);

const setHCTraceCookie = (traceHeader) =>
  set(HCTRACE_COOKIE_KEY, traceHeader[HCTRACE_COOKIE_KEY.replace(/_/g, "-")]);

const setContactIDCookie = (cid) => set(CONTACT_USER_COOKIE_KEY, cid);
const getContactIDCookie = () => {
  const value = get(CONTACT_USER_COOKIE_KEY);
  return value ? `${value}` : null; // return string format of the id
};
const deleteContactIDCookie = () => remove(CONTACT_USER_COOKIE_KEY);

const setPMOSEnv = (env) =>
  env === "staging" ? set(PMOS_SERVER, "staging") : remove(PMOS_SERVER);
const getPMOSEnv = () => {
  const value = get(PMOS_SERVER);
  return value !== "staging" ? "prod" : value;
};

export default {
  SSO_COOKIE_KEY,
  DUP_COOKIE_KEY,
  KC_COOKIE_KEY,

  get,
  set,
  remove,

  setSSOReturnURL,
  setDUPCookie,
  setHCTraceCookie,
  setKCSessionCookie,

  getSSOCookie,
  getKCSessionCookie,
  getDUPCookie,

  isDUPAgreed,
  rmDUPCookie,

  setContactIDCookie,
  getContactIDCookie,
  deleteContactIDCookie,

  setPMOSEnv,
  getPMOSEnv,
};
