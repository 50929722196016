/* istanbul ignore file */
import React from "react";
/*
  when mimicking a user, and try to grant access with PMOS production server,
  show this modal to confirm the action
*/

import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import NoDraggableDialog from "../../../UI/InfoBoard/Standalone/NoDraggableDialog";
import { infoTextBold } from "./styles";

const MimickingGrantWithProdWarningDlg = ({ cancelBtnFn, proceedBtnFn }) => {
  // console.log("[MimickingGrantWithProdWarningDlg]", JSON.stringify(data));

  return (
    <NoDraggableDialog
      title="Grant Access in Production Env"
      width={800}
      cancelCrossFn={cancelBtnFn}
      acceptBtnText="Confirm"
      acceptBtnFn={proceedBtnFn}
      cancelBtnText="Cancel"
      cancelBtnFn={cancelBtnFn}
    >
      <Typography paragraph>
        Please be aware that the action you are trying to take will be reflected
        on the <span style={infoTextBold}>production databases</span>. Please
        confirm that you would like to make this change in production.
      </Typography>

      <Typography paragraph>
        If you would like to make the change in the{" "}
        <span style={infoTextBold}>staging</span> environment, please:
      </Typography>

      {[
        `Select “Cancel”`,
        `Select “Use PMOS Staging” from the avatar dropdown`,
        "Submit your Manage Access change again",
      ].map((d) => {
        return (
          <Typography key={d} style={{ marginLeft: 18 }}>
            <FiberManualRecordIcon
              style={{ width: 12, height: 12, marginRight: 4 }}
            />
            {d}
          </Typography>
        );
      })}
    </NoDraggableDialog>
  );
};

export default MimickingGrantWithProdWarningDlg;

MimickingGrantWithProdWarningDlg.propTypes = {
  cancelBtnFn: PropTypes.func.isRequired,
  proceedBtnFn: PropTypes.func.isRequired,
};
