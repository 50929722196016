import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircleIcon from "@mui/icons-material/Circle";
import Link from "@mui/material/Link";

import { DF_BTN_INFO } from "../../../UI/Dialogs/DialogFooter";
import NoDraggableDialog, {
  DLG_INFO,
} from "../../../UI/InfoBoard/Standalone/NoDraggableDialog";
import { infoTextBold } from "./styles";
import { ORCID_USER_COLOR } from "./constants";

import classes from "../DataGrid.module.css";

const ReadyToGrantDlg = ({ data, cancelBtnFn, proceedBtnFn }) => {
  // console.log("[ReadyToGrantDlg]", JSON.stringify(data));
  const users = Object.values(data.users);

  const content = (
    <div>
      {users.map((d, idx) => {
        const kid = `${d.contact_id}-${idx}`;
        return (
          <Box key={kid}>
            <Typography paragraph>
              <span
                style={{ color: ORCID_USER_COLOR, fontWeight: "bold" }}
              >{`${d.first_name[0]}. ${d.last_name}`}</span>{" "}
              <span>{d.email}</span>
              <br />
              <CircleIcon
                sx={{
                  width: 8,
                  height: 8,
                  marginRight: 1,
                  marginLeft: 4,
                  position: "relative",
                  top: -2,
                }}
              />
              ORCID:{" "}
              <span style={{ fontWeight: "bold" }}>{`${d.orcid_id}`}</span>
            </Typography>
          </Box>
        );
      })}
    </div>
  );

  return (
    <NoDraggableDialog
      type={DLG_INFO}
      title="ORCID(s) found and recognized"
      width={800}
      cancelCrossFn={cancelBtnFn}
      acceptBtnText="Proceed with recognized users"
      acceptBtnFn={proceedBtnFn}
      cancelBtnText="Cancel"
      cancelBtnFn={cancelBtnFn}
      btnColor={DF_BTN_INFO}
    >
      <Typography sx={infoTextBold} paragraph>
        You are about to grant access to the following individuals:
      </Typography>
      {content}
      <Box>
        <Typography sx={infoTextBold} paragraph>
          To manage access to private organisms/portals in other JGI apps
        </Typography>
        <Typography paragraph>
          <CircleIcon
            sx={{
              width: 8,
              height: 8,
              marginRight: 1,
              marginLeft: 4,
              position: "relative",
              top: -2,
            }}
          />
          IMG: follow the steps in{" "}
          <Link
            href="https://img.jgi.doe.gov/docs/submission/index.html"
            target="_blank"
            sx={{ color: classes.lake500 }}
          >
            IMG FAQ (Q3)
          </Link>
        </Typography>
        <Typography paragraph>
          <CircleIcon
            sx={{
              width: 8,
              height: 8,
              marginRight: 1,
              marginLeft: 4,
              position: "relative",
              top: -2,
            }}
          />
          <Link
            href="http://mycocosm.jgi.doe.gov/"
            target="_blank"
            sx={{ color: classes.lake500 }}
          >
            Mycocosm
          </Link>{" "}
          and{" "}
          <Link
            href="https://phycocosm.jgi.doe.gov/"
            target="_blank"
            sx={{ color: classes.lake500 }}
          >
            Phycocosm
          </Link>
        </Typography>
      </Box>
    </NoDraggableDialog>
  );
};

export default ReadyToGrantDlg;

ReadyToGrantDlg.propTypes = {
  data: PropTypes.shape().isRequired,
  cancelBtnFn: PropTypes.func.isRequired,
  proceedBtnFn: PropTypes.func.isRequired,
};
