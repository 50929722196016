import React, { useEffect } from "react";

import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import jQuery from "jquery";

import classes from "./ContactUs.module.css";

/**
 * Component to provide the existing JGI Jira 'Issue Collector' dialog and functionality.
 * The Jira Issue Collector requires jquery to function. In the future this could/should
 * be replaced with a frontend form and use the Jira API to add issues so the jquery dependency
 * in the kingdom can be removed.
 */
const triggerId = "myCustomTrigger";
export const openCollectorDialog = () => {
  jQuery(`#${triggerId}`).trigger("click");
};

const ContactUs = (props) => {
  // Setup the trigger so the 'Contact Us' button opens the jira collector dialog
  window.ATL_JQ_PAGE_PROPS = {
    triggerFunction(showCollectorDialog) {
      jQuery(`#${triggerId}`).on("click", (e) => {
        e.preventDefault();
        const { honeycomb } = props;
        honeycomb.sendUiInteractionSpan("clicked-jira-contact-us-dialog");
        showCollectorDialog();
      });
    },
  };

  useEffect(() => {
    const jiraScript1 = document.createElement("script");
    jiraScript1.src =
      "https://issues.jgi.doe.gov/s/d41d8cd98f00b204e9800998ecf8427e-CDN/-pzb543/802003/" +
      "05c3a0f9ba6c44768195fb9bcae217f9/2.2.4.7/_/download/batch/com.atlassian.plugins.jquery:jquery/" +
      "com.atlassian.plugins.jquery:jquery.js?collectorId=fcc59cd6";
    jiraScript1.async = false;

    const jiraScript2 = document.createElement("script");
    jiraScript2.src =
      "https://issues.jgi.doe.gov/s/93549ed4da778cf56709ac8d8af0c49c-T/-pzb543/802003/" +
      "05c3a0f9ba6c44768195fb9bcae217f9/3.0.7/_/download/batch/com.atlassian.jira.collector.plugin." +
      "jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-" +
      "collector-plugin:issuecollector.js?locale=en-US&collectorId=fcc59cd6";
    jiraScript2.async = false;

    document.body.appendChild(jiraScript1);
    document.body.appendChild(jiraScript2);

    return () => {
      document.body.removeChild(jiraScript1);
      document.body.removeChild(jiraScript2);
    };
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link href="#" className={classes.linkButton} data-testid="contactUsLink">
      <Typography id={triggerId} color="primary" className={classes.linkFont}>
        Contact Us
      </Typography>
    </Link>
  );
};

export default ContactUs;

ContactUs.defaultProps = {
  honeycomb: { sendUiInteractionSpan: () => {} },
};
ContactUs.propTypes = {
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
  }),
};
