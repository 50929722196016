import React, { useState } from "react";
import PropTypes from "prop-types";

import { getNativeButton } from "../Button/Button";

import TransientTooltip from "../UI/Tooltip/TransientTooltip/TransientTooltip";

const AddToCart = (props) => {
  // Props
  const {
    disabled,
    setSelectedFiles, // SearchPage.setSelectedFiles
    files,
    honeycomb,
    searchCounts,
    resetSearchCounts,
  } = props;

  const [notice, setNotice] = useState(null);

  // organism count
  const oCount = () => {
    if (!files) {
      return 0;
    }
    const orgs = [];

    files.forEach((f) => {
      const oid = f.organism_id;
      if (!orgs.includes(oid)) orgs.push(oid);
    });
    return orgs.length;
  };

  const handleAddToCartClick = () => {
    if (files?.length > 0) {
      const orgs = oCount();
      const noticeText = `${files.length} file${
        files.length > 1 ? "s" : ""
      } in ${orgs} dataset${orgs > 1 ? "s" : ""} added to cart`;

      setSelectedFiles(files, true);
      setNotice(noticeText);
      setTimeout(() => {
        setNotice(null);
      }, 2000);

      if (resetSearchCounts) {
        resetSearchCounts();
      }

      // add to HC trace the AddToCart event with search info
      const trace = honeycomb.buildTrace(honeycomb.getTraceID());
      const hcPayload = {
        search: searchCounts,
        file_count: files.length,
      };

      honeycomb.sendDownloadSpan("add2cart", hcPayload, trace);
    }
  };

  const btnStyle = {
    width: 163, // match filter tool's width
    height: 48,
    padding: 20,
  };

  const config = {
    "aria-controls": "simple-menu",
    "aria-haspopup": "true",
    onClick: handleAddToCartClick,
    variant: "contained",
    color: "primary",
    size: "large",
    className: "button",
    style: btnStyle,
    id: "add-to-card-btn-id",
    disabled: !!disabled,
    children: "Add to Cart",
    files,
  };

  const theButton = getNativeButton({ ...config });

  if (disabled && !notice) {
    return <>{theButton}</>;
  }

  const orgs = oCount();
  const orgText = !notice
    ? files?.length > 1
      ? ` in ${orgs} dataset${orgs > 1 ? "s" : ""} `
      : " "
    : " ";
  const toolTipText = notice
    ? null
    : `${files?.length} file${files?.length > 1 ? "s" : ""}${orgText}${
        files?.length > 1 ? "are" : "is"
      } ready to be added to cart`;

  return (
    <TransientTooltip
      noticeLabel={notice}
      labelConf={
        notice
          ? null
          : [
              {
                type: "title",
                value: toolTipText,
              },
            ]
      }
      child={theButton}
      open={Boolean(notice)}
      bottomOffset={0}
      // iconType={ICON_WARN}
      noticeBottomOffset={-10}
    />
  );
};

export default AddToCart;

AddToCart.defaultProps = {
  files: null,
  disabled: true,
  searchCounts: null,
  resetSearchCounts: null,
  honeycomb: null,
};

AddToCart.propTypes = {
  // honeycomb: PropTypes.shape({ sendUiInteractionSpan: PropTypes.func }),
  files: PropTypes.arrayOf(PropTypes.shape([])),
  disabled: PropTypes.bool,
  setSelectedFiles: PropTypes.func.isRequired,
  searchCounts: PropTypes.number,
  resetSearchCounts: PropTypes.func,
  honeycomb: PropTypes.shape(),
};
