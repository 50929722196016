import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Grid, TextField, Avatar } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Popper from "@material-ui/core/Popper";

import StyledCheckbox from "../../StyledCheckbox/StyledCheckbox";
import classes from "./Filters.module.css";
import { useSearchParams } from "../../../hooks/useSearchParams";

import autil from "../../../utils/ArrayUtils";

import {
  FILTER_DUS_TITLE,
  GOLD_ECOSYSTEM,
  GOLD_ECOSYSTEM_CATEGORY,
  GOLD_ECOSYSTEM_SUBTYPE,
  GOLD_ECOSYSTEM_TYPE,
  GOLD_SPECIFIC_ECOSYSTEM,
  FILTER_VERSION,
} from "../../../config/appConfig";
import { Typography } from "../../../../node_modules/@material-ui/core/index";

// for auto-width of filter items. ref https://codesandbox.io/s/material-demo-forked-b927n?file=/demo.js
const styles = () => ({
  popper: {
    maxWidth: "fit-content",
  },
});
const PopperAutoWidth = (props) => {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

const capitalizeWord = (word) => {
  if (typeof word !== "string") {
    return word;
  }
  // console.log("[capitalizeWord]", word);
  return word.charAt(0).toUpperCase() + word.substring(1);
};

const Filter = (props) => {
  const { config, honeycomb, id, facet, applyFilter, onleft, inputLabelStyle } =
    props;
  const history = useHistory();
  const { searchParams } = useSearchParams();
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);
  const [newSelectedFilterItems, setNewSelectedFilterItems] = useState([]);
  const [selecting, setSelecting] = useState(false);
  const [selectionChanged, setSelectionChanged] = useState(false);

  const handleSelection = (value) => {
    const selections = [];
    setSelecting(true);
    value.forEach((item) => {
      if (item.values) {
        selections.push(item.values);
      } else {
        selections.push(item.title);
      }
    });

    const changed = !autil.ArrayOfPrimativeEqual(
      selectedFilterItems.map((v) => v.title),
      selections
    );

    setSelectionChanged(changed);
    setNewSelectedFilterItems(selections.map((v) => ({ title: v })));
  };

  /* update current selection items with the give searParams (sParams) */
  const getCurrentSearchParams = (sParams) => {
    const { filterId } = config;
    const existingValues = [];
    const allCurrentParams = [...sParams.entries()];
    if (!allCurrentParams || allCurrentParams.length === 0) {
      return [];
    }
    allCurrentParams.forEach((param) => {
      // if we're iterating over the values in the comboxbox the param belongs to...
      if (param[0] === filterId) {
        const paramList = param[1]
          .split(",")
          .map((item) => decodeURIComponent(item));

        paramList.forEach((item) => existingValues.push({ title: item }));
      }
    });
    return existingValues;
  };

  useEffect(() => {
    setSelectedFilterItems(getCurrentSearchParams(searchParams)); // initialize current selection with the init sParams
  }, []);

  useEffect(() => {
    return history.listen((loc) => {
      const urlParams = new URLSearchParams(loc.search);
      setSelectedFilterItems(getCurrentSearchParams(urlParams));
    });
  }, [history]);

  const updateURLParams = () => {
    if (!selectionChanged) {
      // no selections, do nothing
      return;
    }

    const filters = newSelectedFilterItems.map((item) => item.title);
    const { filterId } = config;
    const currentParams = searchParams.has(filterId)
      ? searchParams.get(filterId).split(",")
      : [];
    const changedValue = filters
      .filter((filter) => !currentParams.includes(filter))
      .concat(currentParams.filter((filter) => !filters.includes(filter)));

    // Send filter update to UI
    const hcSpan = {
      filter: {
        field: filterId,
        value: changedValue,
        "updated-value": filters,
      },
    };
    honeycomb.sendUiInteractionSpan(`filter-change`, hcSpan);

    // Update search params
    if (filters.length) {
      searchParams.set(filterId, filters);
    } else {
      searchParams.delete(filterId);
    }

    const queryString = searchParams.toString();

    applyFilter(`?${queryString}`);
    setSelecting(false);
    setSelectionChanged(false);
  };

  const renderAutocompleteInput = (inputParams) => {
    const currentFilter = searchParams.get(config.filterId);

    const countAvatar = currentFilter ? (
      <Avatar
        className={classes.countAvatar}
        data-testid={`${config.filterId}-count-avatar`}
      >
        {currentFilter.split(",").length}
      </Avatar>
    ) : null;

    // console.log("[FilterMultiSelect]", inputParams.InputProps);
    return (
      <>
        <TextField
          {...inputParams}
          variant="outlined"
          label={`${config.filterTitle}`}
          InputProps={{
            ...inputParams.InputProps,
            classes: {
              root: currentFilter
                ? classes.filterInputFilled
                : classes.filterInput,
            },
            endAdornment: (
              <>
                {countAvatar}
                {inputParams.InputProps.endAdornment}
              </>
            ),
          }}
          InputLabelProps={{
            style: onleft ? inputLabelStyle : null,
          }}
        />
      </>
    );
  };

  /** Build up the filter options using the facet data and config (from props) */
  const buildOptions = () => {
    if (config.filterTitle === FILTER_DUS_TITLE) {
      // JDP-1792 : static order
      return [
        { title: "Unrestricted", count: 1 },
        { title: "Restricted", count: 1 },
      ];
    }
    if (config.filterId === FILTER_VERSION) {
      // JDP-1481
      return [
        { title: "Current", count: 1 },
        { title: "All", count: 1 },
      ];
    }

    const options = [];

    Object.entries(facet).forEach(([name, count]) => {
      // labeler allows for presentation of different values than are actually
      // present in facets.
      if (config.labeler) {
        const label = config.labeler(name);

        // if multiple values have the same value, group them together with all values for BE request
        const existingIndex = options.findIndex(
          (option) => option.title === label
        );
        if (existingIndex > -1) {
          // Found prexisting option, merge values/counts in options
          const existingOption = options[existingIndex];
          const values = existingOption.values
            ? existingOption.values
            : existingOption.title;
          const newValues = values.split(";");
          newValues.push(name);
          options[existingIndex] = {
            title: label,
            count: existingOption.count + count + 0,
            values: newValues.sort().join(";"),
          };
        } else {
          options.push({ title: label, count, values: name });
        }
      } else {
        // No labeler, simple case, name is label
        options.push({ title: name, count });
      }
    });

    // Sort the options (if specified in config)
    if (config.sort) {
      if (config.alphaNumeric) {
        return Object.values(options).sort((a, b) => {
          if (a.title === "current") {
            return -1;
          }
          if (b.title === "current") {
            return 1;
          }
          return b.title.localeCompare(a.title, "en", { numeric: true });
        });
      }
      const opts = Object.values(options).sort((a, b) =>
        a.title.localeCompare(b.title, "en", { sensitivity: "accent" })
      );

      if (
        [
          GOLD_ECOSYSTEM,
          GOLD_ECOSYSTEM_CATEGORY,
          GOLD_ECOSYSTEM_SUBTYPE,
          GOLD_ECOSYSTEM_TYPE,
          GOLD_SPECIFIC_ECOSYSTEM,
        ].includes(config.filterId)
      ) {
        // JDP-1752: add None value (will be converted into NONE in useApi) for env filters
        opts.unshift({ title: "None", count: 1 });
      }
      return opts;
    }

    return options;
  };

  /*
    borrowed from this thread: https://github.com/mui-org/material-ui/issues/19692

    this seems to be necessary to declare using makeStyles rather than CSS as MUI doesn't offer any other ways to target styling of the option selected state
  */
  const useStyles = makeStyles({
    "@global": {
      '.MuiAutocomplete-option[data-focus="true"]': {
        backgroundColor: classes.lake25,
      },
      '.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: "unset",
      },
    },
  });

  useStyles();

  // console.log("[filters]", config.filterTitle);
  return (
    <Grid
      item
      key={config.filterTitle}
      className={onleft ? classes.gridItemLeft : classes.gridItem}
      style={{ paddingLeft: 0 }}
      sx={1}
    >
      <Autocomplete
        value={selecting ? newSelectedFilterItems : selectedFilterItems}
        // open={config.filterTitle === "Data type"}
        multiple
        PopperComponent={PopperAutoWidth}
        renderTags={() => {
          // Prevent the autocomplete from showing a 'chip' in the input for each selected
          return null;
        }}
        size="small"
        popupIcon={
          <KeyboardArrowDownIcon
            fontSize="large"
            color="primary"
            classes={{ fontSizeLarge: classes.boxIcon }}
          />
        }
        classes={{
          option: classes.listItem,
          listbox: classes.listbox,
          paper: classes.paper,
        }}
        id={`${id}_filter_${config.filterTitle}`}
        options={buildOptions()}
        disabled={config.disabled ? config.disabled() : false}
        disableCloseOnSelect
        disableClearable
        getOptionLabel={(option) => option.title}
        getOptionSelected={(option, value) => {
          if (option.values) {
            return option.values === value.title;
          }
          return option.title === value.title;
        }}
        onChange={(_event, value) => handleSelection(value)}
        onClose={updateURLParams}
        renderOption={(option, { selected }) => (
          <>
            <StyledCheckbox
              inputProps={{
                "aria-label": "Filter Checkbox",
              }}
              style={{ paddingLeft: 0 }}
              checked={selected}
            />
            {/* The filter option items - display with 1st char upcased */}
            <Typography
              style={{
                whiteSpace: "nowrap",
                paddingRight: 4,
                fontFamily: "Public Sans",
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              {capitalizeWord(option.title)}
            </Typography>
          </>
        )}
        renderInput={(inputParams) =>
          renderAutocompleteInput(inputParams, config)
        }
      />
    </Grid>
  );
};

export default Filter;

Filter.defaultProps = {
  config: {},
  honeycomb: {
    sendUiInteractionSpan: () => {},
  },
  id: "",
  facet: {},
  onleft: false,
  inputLabelStyle: {},
};

Filter.propTypes = {
  config: PropTypes.shape(),
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
  }),
  id: PropTypes.string,
  facet: PropTypes.shape(),
  applyFilter: PropTypes.func.isRequired,
  onleft: PropTypes.bool,
  inputLabelStyle: PropTypes.shape(),
};
