import React from "react";
import { Box, Card, CardMedia, Typography } from "@material-ui/core/";

import heroImage from "../../assets/images/hero.jpg";

import classes from "./Hero.module.css";

const Hero = () => {
  return (
    <Card className={classes.root}>
      <CardMedia
        component="img"
        alt="Closeup of a cell"
        height="383"
        image={heroImage}
        title="JGI data portal"
      />
      <Box className={classes.text}>
        <Typography
          gutterBottom
          className={`${classes.h1Desktop} ${classes.title}`}
        >
          {`Top-quality genomic data,
        open to all researchers`}
        </Typography>
        <Typography
          gutterBottom
          className={`${classes.h5Desktop} ${classes.subtitle}`}
        >
          Download plant, algal, fungal, and microbial genomes and metagenomes
        </Typography>
      </Box>
    </Card>
  );
};

export default Hero;
