/* istanbul ignore file */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography, Link } from "@material-ui/core/";

import classes from "./Filters.module.css";

import JDPLifeHelpIcon from "../../UI/Icons/JDPLifeHelpIcon";
import { useSearchParams } from "../../../hooks/useSearchParams";
import ConfigContext from "../../../context/ConfigContext";

import { HREF_DOC_HOW_TO_FILTER } from "../../../utils/URLConstants";

const FilterFooter = (props) => {
  const { honeycomb, applyFilter, onleft, getFilterChips } = props;
  const { organismFilters, fileFilters, goldFilters } =
    useContext(ConfigContext);

  let filterConfig = organismFilters
    ? organismFilters.concat(fileFilters)
    : fileFilters;

  if (filterConfig && goldFilters) {
    filterConfig = filterConfig.concat(goldFilters);
  }

  const { searchParams, FILE_NAME_REGX_KEY } = useSearchParams();

  /** Remove all filter params from URL */
  const handleClearAll = () => {
    honeycomb.sendUiInteractionSpan(`filter-clear-all`, {
      filter: { "updated-value": [] },
    });

    // Get keys of only 'filter' params (dont want to delete expand, organism, ect)
    const deleteKeys = [FILE_NAME_REGX_KEY];
    searchParams.forEach((value, key) => {
      if (filterConfig.some((config) => config.filterId === key)) {
        deleteKeys.push(key);
      }
    });

    // Delete all filters by key
    deleteKeys.map((key) => searchParams.delete(key));

    // update URL
    const queryString = searchParams.toString();
    applyFilter(`?${queryString}`);
  };

  /** Get the clear all button if filter params present in URL */
  const clearAllButton = () => {
    let haveFilters = Boolean(searchParams.get(FILE_NAME_REGX_KEY));
    if (!haveFilters) {
      // Check if any filters are shown currently
      const paramEntries = searchParams.entries();
      let searchParam = paramEntries.next();
      while (!searchParam.done && !haveFilters) {
        const paramKey = searchParam.value[0];
        if (filterConfig.some((config) => config.filterId === paramKey)) {
          haveFilters = true;
        }
        searchParam = paramEntries.next();
      }
    }

    return (
      haveFilters && (
        <Box className={classes.clearAllBox}>
          <Typography
            className={`${classes.bodySmall} ${classes.clearAllText}`}
            data-testid="clearAllFilters"
            onClick={handleClearAll}
          >
            Clear All
          </Typography>
        </Box>
      )
    );
  };

  if (onleft) {
    return (
      <Box>
        <Box style={{ margin: "10px 0px" }}>{clearAllButton()}</Box>
        <Box style={{ margin: "10px 14px 0px" }}>
          <JDPLifeHelpIcon>
            <Typography
              className={`${classes.bodySmall} ${classes.filterHelpText}`}
            >
              <Link
                className={classes.filterHelpLink}
                href={HREF_DOC_HOW_TO_FILTER}
                target="_blank"
                data-testid="filterHelp"
                onClick={() => {
                  honeycomb.sendUiInteractionSpan(
                    "clicked-internal-link: Learn more about filters"
                  );
                }}
              >
                Learn more
              </Link>{" "}
              about filters
            </Typography>
          </JDPLifeHelpIcon>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={`${classes.rootBox} ${classes.filterFooter}`}>
      <Grid container direction="row" justify="space-between" wrap="nowrap">
        <Box className={classes.chipContainer} data-testid="chip-container">
          <Box className={classes.filterChipBox}>
            {getFilterChips()}
            {clearAllButton()}
          </Box>
        </Box>
        <Box className={classes.filterHelpBox}>
          <JDPLifeHelpIcon>
            <Typography
              className={`${classes.bodySmall} ${classes.filterHelpText}`}
            >
              <Link
                className={classes.filterHelpLink}
                href={HREF_DOC_HOW_TO_FILTER}
                target="_blank"
                data-testid="filterHelp"
                onClick={() => {
                  honeycomb.sendUiInteractionSpan(
                    "clicked-internal-link: Learn more about filters"
                  );
                }}
              >
                Learn more
              </Link>{" "}
              about filters
            </Typography>
          </JDPLifeHelpIcon>
        </Box>
      </Grid>
    </Box>
  );
};

export default FilterFooter;

FilterFooter.defaultProps = {
  honeycomb: { sendUiInteractionSpan: () => {} },
  onleft: false,
  getFilterChips: null,
};

FilterFooter.propTypes = {
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
  }),
  applyFilter: PropTypes.func.isRequired,
  onleft: PropTypes.bool,
  getFilterChips: PropTypes.func,
};
