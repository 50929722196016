/* istanbul ignore file */
import React, { useRef, useState } from "react";
import { PropTypes } from "prop-types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { Typography, Box, Menu, MenuItem } from "@material-ui/core";

import toFullURL, {
  HREF_DOC_API,
  HREF_DOC_DOWNLOAD,
  HREF_DOC_ROOT,
} from "../../utils/URLConstants";
import classes from "./Header.module.css";

const HELP_ITEM_FAQ = "HELP_ITEM_FAQ";
const HELP_ITEM_DOWNLOADING = "HELP_ITEM_DOWNLOADING";
const HELP_ITEM_API_TUTORIAL = "HELP_ITEM_API_TUTORIAL";

const urlLookup = {};
urlLookup[HELP_ITEM_FAQ] = toFullURL(HREF_DOC_ROOT);
urlLookup[HELP_ITEM_DOWNLOADING] = toFullURL(HREF_DOC_DOWNLOAD);
urlLookup[HELP_ITEM_API_TUTORIAL] = toFullURL(HREF_DOC_API);

const HELP = ({ honeycomb }) => {
  const buttonRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLinkClick = (event) => {
    setAnchorEl(event.currentTarget);
    honeycomb.sendUiInteractionSpan("help-pulldown-in-header-clicked");
  };

  const handleDropdownClose = () => {
    // Close dropdown menu
    setAnchorEl(null);
    return true;
  };

  const handleItemClick = (id) => {
    setAnchorEl(null);
    honeycomb.sendUiInteractionSpan("help-pulldown-item-in-header-clicked", {
      helpItemURL: urlLookup[id],
    });
    window.open(urlLookup[id], "_blank");
  };

  const dropdownItems = [
    {
      label: "FAQ",
      key: "help-item-faq",
      id: "help-item-faq-id",
      click: () => {
        handleItemClick(HELP_ITEM_FAQ);
      },
    },
    {
      label: "Downloading",
      key: "help-item-downloading",
      id: "help-item-downloading-id",
      click: () => {
        handleItemClick(HELP_ITEM_DOWNLOADING);
      },
    },
    {
      label: "API Tutorial",
      key: "help-item-api-tutorial",
      id: "help-item-api-tutorial-id",
      click: () => {
        handleItemClick(HELP_ITEM_API_TUTORIAL);
      },
    },
  ];

  return (
    <Box className={classes.box} title="Frequently asked question">
      <Box
        ref={buttonRef}
        onClick={handleLinkClick}
        data-testid="faq-header-link"
        size="small"
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Typography className={`${classes.link} ${classes.linkFont}`}>
          HELP
        </Typography>

        <KeyboardArrowDownIcon
          style={{
            position: "relative",
            marginLeft: -4,
          }}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {dropdownItems.map((d) => {
          return (
            <MenuItem onClick={d.click} key={d.key} id={d.id}>
              {d.label}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default HELP;

HELP.propTypes = {
  honeycomb: PropTypes.shape().isRequired,
};
