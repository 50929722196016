/* istanbul ignore file */
/* eslint-disable react/jsx-filename-extension */
import React from "react";
import ReactDOM from "react-dom";
import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./index.css";
import App from "./App";
import keycloak from "./utils/keycloak";

import * as serviceWorker from "./serviceWorker";

if (process.env.REACT_APP_HIDE_ERROR_STACK) {
  import("react-error-overlay").then((module) => {
    module.stopReportingRuntimeErrors();
  });
}

const AppRouter = () => {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return <div style={{ padding: "20px" }}>Loading...</div>;
  }

  return (
    <Router>
      <Switch>
        <Route path="/(refine-download/)?:segment/">
          <App />
        </Route>
        <Route path="*">
          <App />
        </Route>
      </Switch>
    </Router>
  );
};
// Everything eventually routes to App because that handles all LD and HC
// and further routes in RoutingContainer, but capture the :segment immediately
// so that ConfigProvider has it in App
ReactDOM.render(
  <ReactKeycloakProvider authClient={keycloak}>
    <AppRouter />
  </ReactKeycloakProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
