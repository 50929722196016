import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import PropTypes from "prop-types";
import DialogFooter, { DF_BTN_INFO } from "../DialogFooter";

import classes from "./DraggableDialog.module.css";

/**
 Content is wrapped in the component : <DraggableDialog > <> ...  </> </DraggableDialog>
 TOP_TYPE
 */
export const TT_RED500 = "TT_RED500";
export const TT_ORANGE600 = "TT_ORANGE600";
export const TT_LAKE500 = "TT_LAKE500";
export const TT_MOSS500 = "TT_MOSS500";

export const TT_MAP = {
  TT_RED500: { borderTop: `solid 4px ${classes.red500}` },
  TT_ORANGE600: { borderTop: `solid 4px ${classes.orange600}` },
  TT_LAKE500: { borderTop: `solid 4px ${classes.lake500}` },
  TT_MOSS500: { borderTop: `solid 4px ${classes.moss500}` },
};

const DraggableDialog = ({
  title,
  cancelBtnFn,
  acceptBtnFn,
  cancelBtnText,
  acceptBtnText,
  acceptBtnIcon,
  acceptBtnTooltip,
  cancelBtnDisabled,
  acceptBtnDisabled,
  topType,
  btnColor,
  children,
  checkBtnText,
  checkBtnFn,
}) => {
  const PaperComponent = (props) => {
    const tCSS = topType ? TT_MAP[topType] : null;
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} style={tCSS} />
      </Draggable>
    );
  };

  return (
    <div key="dialog">
      <Dialog
        fullWidth
        maxWidth="md"
        open
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        {title === null ? null : (
          <DialogTitle
            style={{
              cursor: "move",
              paddingLeft: "20px",
              backgroundColor: "#ccc",
              minHeight: "20px",
            }}
            id="draggable-dialog-title"
            data-testid="draggable-dialog-title-id"
          >
            <span className={classes.title}>{title}</span>
          </DialogTitle>
        )}

        <DialogContent className={classes.modalContent}>
          {children}
        </DialogContent>
        {cancelBtnFn || acceptBtnFn ? (
          <DialogActions>
            <DialogFooter
              btnColor={btnColor}
              cancelBtnFn={cancelBtnFn}
              acceptBtnFn={acceptBtnFn}
              acceptBtnIcon={acceptBtnIcon}
              acceptBtnTooltip={acceptBtnTooltip}
              className={classes.footer}
              cancelBtnText={cancelBtnText}
              acceptBtnText={acceptBtnText}
              cancelBtnDisabled={cancelBtnDisabled}
              acceptBtnDisabled={acceptBtnDisabled}
              checkBtnText={checkBtnText}
              checkBtnFn={checkBtnFn}
            />
          </DialogActions>
        ) : (
          <span style={{ height: "26px" }}>
            <br />
          </span>
        )}
      </Dialog>
    </div>
  );
};

export default DraggableDialog;

DraggableDialog.defaultProps = {
  title: null,
  children: null,
  cancelBtnFn: null,
  acceptBtnFn: null,
  cancelBtnText: "Cancel",
  acceptBtnText: "Accept",
  acceptBtnIcon: null,
  acceptBtnTooltip: null,
  cancelBtnDisabled: false,
  acceptBtnDisabled: false,
  topType: TT_LAKE500,
  btnColor: DF_BTN_INFO,
  checkBtnText: null,
  checkBtnFn: null,
};

DraggableDialog.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  cancelBtnFn: PropTypes.func,
  acceptBtnFn: PropTypes.func,
  acceptBtnIcon: PropTypes.string,
  acceptBtnTooltip: PropTypes.string,
  cancelBtnText: PropTypes.string,
  acceptBtnText: PropTypes.string,
  topType: PropTypes.string,
  btnColor: PropTypes.string,
  cancelBtnDisabled: PropTypes.bool,
  acceptBtnDisabled: PropTypes.bool,
  checkBtnText: PropTypes.string,
  checkBtnFn: PropTypes.func,
};
