import React from "react";
import { PropTypes } from "prop-types";

import NotificationsIcon from "@mui/icons-material/Notifications";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FilterListIcon from "@mui/icons-material/FilterList";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

import classes from "./IconWithCounterBadge.module.css";

export const ICON_CART = "ICON_CART";
export const ICON_BELL = "ICON_BELL";
export const ICON_FILTER = "ICON_FILTER";

const IconWithCounterBadge = ({
  iconType,
  onClick,
  iconColor,
  count,
  size,
  cartRef,
  cursor,
  badgeBackgroundColor,
  badgeColor,
  badgeBorderColor,
}) => {
  const NumberBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 0, // number badge horizontal position
      top: 2, // number badge vertial position
      border: `1px solid ${badgeBorderColor || theme.palette.background.paper}`,
      padding: "0 4px",
      backgroundColor: badgeBackgroundColor,
      color: badgeColor,
    },
  }));

  const icon = () =>
    iconType === ICON_BELL ? (
      <NotificationsIcon
        className={classes.Icon}
        fontSize={size}
        sx={{ color: iconColor }}
      />
    ) : iconType === ICON_FILTER ? (
      <FilterListIcon
        className={classes.Icon}
        fontSize={size}
        sx={{ color: iconColor }}
      />
    ) : (
      <ShoppingCartIcon
        className={classes.Icon}
        fontSize={size}
        sx={{ color: iconColor }}
      />
    );

  const badgeId = `badge-${iconType.toLowerCase()}`;
  return (
    <Stack
      ref={cartRef}
      data-testid={`badge-stack-${iconType.toLowerCase()}`}
      spacing={2}
      direction="row"
      onClick={onClick}
      style={{
        cursor: onClick ? "pointer" : cursor,
        margin: "0px 10px",
      }}
    >
      <NumberBadge id={badgeId} data-testid={badgeId} badgeContent={count}>
        {icon()}
      </NumberBadge>
    </Stack>
  );
};

export default IconWithCounterBadge;

IconWithCounterBadge.defaultProps = {
  iconType: ICON_BELL,
  onClick: null,
  iconColor: classes.lake500,
  badgeBackgroundColor: classes.red500,
  count: 0,
  size: "medium",
  cartRef: null,
  cursor: "default",
  badgeColor: "#FFF",
  badgeBorderColor: "",
};
IconWithCounterBadge.propTypes = {
  iconType: PropTypes.string,
  onClick: PropTypes.func,
  iconColor: PropTypes.string,
  badgeBackgroundColor: PropTypes.string,
  count: PropTypes.number,
  size: PropTypes.string,
  cartRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  cursor: PropTypes.string,
  badgeColor: PropTypes.string,
  badgeBorderColor: PropTypes.string,
};
