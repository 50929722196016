import React from "react";
import PropTypes from "prop-types";

import ConfirmDialog from "../../UI/InfoBoard/Standalone/ConfirmDialog";

const PageChangeWithSelectionDlg = (props) => {
  const {
    addToCartAndProceedBtnFn,
    crossDismissBtnFn,
    dropFileAndProceedBtnFn,
  } = props;

  return (
    <ConfirmDialog
      title="Your Selections Will Be Lost"
      acceptTxt="Add to the Cart and Proceed"
      acceptBtnFn={addToCartAndProceedBtnFn}
      cancelCrossFn={crossDismissBtnFn}
      cancelTxt="Drop Files and Proceed"
      cancelBtnFn={dropFileAndProceedBtnFn}
    >
      <div>
        You have selected files and are trying to navigate to another page
        before your files have been added to the cart.
      </div>

      <div style={{ paddingTop: 20 }}>
        {`Please add your selected items to the Cart if you want to download
        them. Otherwise, the selected files can be dropped in order to
        proceed. Or press "x" to return to previous page.`}
      </div>
    </ConfirmDialog>
  );
};

export default PageChangeWithSelectionDlg;

PageChangeWithSelectionDlg.propTypes = {
  addToCartAndProceedBtnFn: PropTypes.func.isRequired,
  crossDismissBtnFn: PropTypes.func.isRequired,
  dropFileAndProceedBtnFn: PropTypes.func.isRequired,
};
