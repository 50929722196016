import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import toKebabCase from "lodash.kebabcase";

import { List, ListItem, ListItemText, Link, Menu } from "@material-ui/core/";
import LaunchIcon from "@material-ui/icons/Launch";

import appConfig, {
  KINGDOM_IMG,
  KINGDOM_PHYTOZOME,
  NOT_IN_JAMO_META,
} from "../../config/appConfig";
import DUSChip from "../UI/DUSChip/DUSChip";

import {
  toLinesByWordBoundary,
  removeFromEndOnWord,
} from "../../utils/StringUtils";
import { HREF_DOC_RESTRICTED } from "../../utils/URLConstants";

// eslint-disable-next-line no-unused-vars
import { base as ldbase } from "../../utils/LDFFUtils";
import JDPTooltip, { ICON_NONE } from "../UI/Tooltip/Tooltip";

import classes from "./KebabMenu.module.css";

export const DUS_STATUS_ALL = "all";
export const DUS_STATUS_PARTIAL = "partial";
export const DUS_LABEL_RST = "RST";
export const DUS_LABEL_UNRST = "UNRST";
export const DUS_META_VALUE_RST = "Restricted"; // Ref JDP-1549
export const DUS_META_VALUE_UNRST = "Unrestricted";
export const DUS_META_VALUE_PARTIAL = "Mixed";

const GenomeKebab = (props) => {
  const { honeycomb, anchorEl, setAnchorEl, row, ldClient } = props;
  const portalDetailId = row.portal_detail_id;
  const { title, dus_status } = row;
  const { segment } = useParams();
  const rowKingdom = row.kingdom || segment;

  const config = appConfig(ldClient);
  const { portalLink } = config[rowKingdom];

  const rowLabel = row.label || "Genome";
  const DETAILS = `${rowLabel} details`;
  const CITATION = "Citation information";

  const NO_DETAILS = () => {
    return (
      <>
        <LaunchIcon className={classes.launchDisabled} />
        {DETAILS} not available
      </>
    );
  };
  const NO_CITATION = () => {
    return (
      <>
        <LaunchIcon className={classes.launchDisabled} />
        Citation information not available
      </>
    );
  };

  const isCurrentPhytozome = () => {
    if (rowKingdom === KINGDOM_PHYTOZOME) {
      const releaseId = row.files[0]?.metadata?.phytozome?.phytozome_release_id;
      return releaseId && releaseId.includes("current");
    }
    return false;
  };

  const getBaseURL = () => {
    if (!portalDetailId || !portalLink) {
      return;
    }

    if (rowKingdom === KINGDOM_PHYTOZOME) {
      if (row.agg_id) {
        const baseURL = "https://phytozome.jgi.doe.gov/info";
        return `${baseURL}/${row.agg_id}`;
      }
      return null;
    }

    return portalLink(portalDetailId);
  };

  const getCitationAnchor = () => {
    if (rowKingdom === KINGDOM_IMG) {
      return "&expand=1#pubPortal";
    }
    if (isCurrentPhytozome()) {
      return "#citation";
    }
  };

  const getURL = (label) => {
    const baseURL = getBaseURL();
    if (!baseURL) {
      return;
    }
    if (label === DETAILS) {
      return baseURL;
    }
    const anchor = getCitationAnchor();
    return anchor ? `${baseURL}${anchor}` : null;
  };

  const handleKebabClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    honeycomb.sendUiInteractionSpan("clicked-genome-kebab-close");
  };

  const getDropdownLink = (url, label) => {
    if (!url) {
      switch (label) {
        case DETAILS:
          return NO_DETAILS();
        case CITATION:
          return NO_CITATION();
        default:
          return NO_DETAILS();
      }
    }
    return (
      <>
        <LaunchIcon className={classes.launch} />
        <Link
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          underline="always"
          style={{ color: "black", fontWeight: "normal" }}
          onClick={() => {
            honeycomb.sendUiInteractionSpan(
              `clicked-kebab-dropdown-${toKebabCase(label)}`
            );
          }}
        >
          {label}
        </Link>
      </>
    );
  };

  const RST = "data restricted";
  const UNRST = "data unrestricted";
  const PRST = "data partially restricted";
  let dus = UNRST;

  const duschipProps = {
    label: DUS_LABEL_UNRST,
    mleft: "0px",
    mbottom: "0px",
    mright: "5px",
    tooltip: "none",
  };

  if ([DUS_STATUS_ALL, DUS_STATUS_PARTIAL].includes(dus_status)) {
    duschipProps.label = DUS_LABEL_RST;
    // duschipProps.interactive = true;
    if (dus_status === DUS_STATUS_ALL) {
      dus = RST;
    } else if (dus_status === DUS_STATUS_PARTIAL) {
      dus = PRST;
      duschipProps.outline = true;
    }
  }

  const duschip = <DUSChip {...duschipProps} />;

  dus = (
    <Link target="_blank" href={HREF_DOC_RESTRICTED}>
      {dus}
    </Link>
  );

  let proposalApprovedDate = NOT_IN_JAMO_META;
  let awardDOI = NOT_IN_JAMO_META;

  const doi_len_max = 26; // value length max, for keeping display in boundary
  if (row && row.files?.length > 0) {
    // find the 1st metadata.proposal.date_approved
    row.files.every((file) => {
      if (file.metadata?.proposal?.date_approved) {
        proposalApprovedDate = file.metadata.proposal.date_approved;
        return false; // short circuit
      }
      return true;
    });

    row.files.every((file) => {
      const doi = file.metadata?.proposal?.award_doi;
      if (doi) {
        awardDOI = (
          <Link
            target="_blank"
            href={`https://doi.org/${doi}`}
            title={doi.length > doi_len_max ? doi : null}
          >
            {doi.length > doi_len_max
              ? `${doi.substring(0, doi_len_max)}...`
              : doi}
          </Link>
        );
        return false; // short circuit
      }
      return true;
    });
  }

  const completeDate = row?.work_completion_date
    ? row.work_completion_date.split("T")[0]
    : NOT_IN_JAMO_META;

  const metadataMap = [
    {
      title: "PI name",
      key: row.pi?.name,
    },
    {
      title: "PI email address",
      key: row.pi?.email_address,
      liProps: {
        component: "a",
        href: `mailto: ${row.pi?.email_address}`,
      },
    },
    {
      title: "Work completion date",
      key: completeDate,
    },
    {
      title: "Data utilization policy",
      key: dus,
    },
    {
      title: "Proposal acceptance date",
      key: proposalApprovedDate,
    },
    {
      title: "Award DOI",
      key: awardDOI,
    },
  ];

  // insert GOLD ecosystem metadata from search into kebab item below "PI email
  const ecoKeys = Object.keys(row.eco_data);
  const showEco =
    row.label === "Metagenome" || ecoKeys.some((d) => row.eco_data[d]); // any of the eco data contains non-null value;

  if (showEco) {
    metadataMap.splice(
      2,
      0,
      ...ecoKeys.map((name) => {
        return {
          title: name,
          key: row.eco_data[name] ? row.eco_data[name] : NOT_IN_JAMO_META,
        };
      })
    );
  }

  const insertPosition = 3 + (showEco ? ecoKeys.length : 0);

  const goldProjLink = (goldData) => {
    return Object.keys(goldData).map((gid) => (
      <Link
        key={gid}
        href={goldData[gid]}
        target="_blank"
        rel="noopener noreferrer"
        underline="always"
        style={{
          color: "#005982",
          fontWeight: "normal",
          paddingRight: 10,
          display: "block",
        }}
        onClick={() => {
          honeycomb.sendUiInteractionSpan(
            `clicked-genome-kebab-gold-project-link-${gid}`
          );
        }}
      >
        {gid}
      </Link>
    ));
  };
  // JDP-1727: glod proj links
  if (row.gold_proj_data && Object.keys(row.gold_proj_data).length > 0) {
    metadataMap.splice(insertPosition + 1, 0, {
      title: "Gold projects",
      key: goldProjLink(row.gold_proj_data),
    });
  }

  const titleList = toLinesByWordBoundary(title, 60); // title.match(/.{1,56}/g);

  const [proposalLayDesc, setProposalLayDesc] = useState(null);
  const propDescLastLineTail = ["... -", "show more"]; // for stitching the last line of proposal lay description
  useEffect(() => {
    if (row.proposal_lay_description) {
      const visible = 3;
      const long = toLinesByWordBoundary(row.proposal_lay_description, 65);
      const lineCnt = long.length;
      const short = lineCnt > visible ? long.slice(0, visible - 1) : [...long];

      if (lineCnt > visible) {
        const endText = `${propDescLastLineTail[0]}${propDescLastLineTail[1]}`;
        short.push(removeFromEndOnWord(long[visible - 1], endText.length));
      }
      setProposalLayDesc({
        short,
        long,
      });
    }
  }, []);

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleKebabClose}
      keepMounted
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      onClick={(e) => e.stopPropagation()}
    >
      <List>
        <ListItem
          classes={{
            root: `${classes.bodySmall} ${classes.listHeader}`,
          }}
        >
          {`${rowLabel} metadata`}
          {row.superseded_by ? (
            <DUSChip
              label="OBSOLETE"
              mleft="10px"
              mbottom="0px"
              mright="5px"
              tooltip="none"
            />
          ) : null}
        </ListItem>
        {titleList.map((sub, idx) => {
          const kname = `${sub.replace(" ", "_")}_${idx}`;
          return (
            <ListItem
              key={kname}
              divider={idx === titleList.length - 1}
              classes={{
                root: `${classes.h6Desktop} ${classes.listTitle2}`,
              }}
              style={idx < titleList.length - 1 ? { paddingBottom: 0 } : null}
            >
              {sub}
            </ListItem>
          );
        })}
        {metadataMap.map((item, idx) => {
          const keyVal = `${item.title.replace(" ", "_")}-${idx}`;
          const titleStyle =
            item.title === "Proposal acceptance date"
              ? { marginBottom: 0 }
              : item.title === "Award DOI"
              ? { marginTop: 0 }
              : null;

          const valueStyle = { maxHeight: 64, overflow: "auto" };
          if (item.title === "Proposal acceptance date") {
            valueStyle.marginBottom = 0;
          } else if (item.title === "Award DOI") {
            valueStyle.marginTop = 0;
          }

          const chip =
            item.title === "Data utilization policy" ? (
              <div style={{ flexBasis: "50%" }}>
                {" "}
                {duschip}
                {item.key}
              </div>
            ) : null;

          return item.key ? (
            <ListItem
              key={keyVal}
              divider // ={item.title !== "Proposal acceptance date"}
              classes={{
                root: `${classes.listItem}`,
              }}
              {...item.liProps}
            >
              <ListItemText
                classes={{
                  primary: `${classes.bodySmall} ${classes.listItemTitle}`,
                  root: `${classes.listItemText}`,
                }}
                primary={item.title}
                style={titleStyle}
              />
              {chip}
              {item.title !== "Data utilization policy" ? (
                <ListItemText
                  classes={{
                    secondary: `${
                      item.key === NOT_IN_JAMO_META
                        ? classes.bodySmallDimItalic
                        : classes.bodySmall
                    }`,
                    root: `${classes.listItemText}`,
                  }}
                  secondary={item.key}
                  style={valueStyle}
                />
              ) : null}
            </ListItem>
          ) : null;
        })}
      </List>
      {/* proposal lay description */}

      {proposalLayDesc && (
        <List>
          <ListItem
            classes={{
              root: `${classes.h6Desktop} ${classes.menuTitle}`,
            }}
          >
            Proposal Lay description
          </ListItem>
          {proposalLayDesc.short.map((line, idx) => {
            const kname = `p_lay_desc_${idx}`;
            const idxMax = proposalLayDesc.short.length - 1;
            const addTooltip =
              proposalLayDesc.long.length > proposalLayDesc.short.length &&
              idx === idxMax;

            return (
              <ListItem
                key={kname}
                style={{ paddingTop: 0, paddingBottom: 0, marginLeft: 20 }}
              >
                {line}{" "}
                {addTooltip && <span> &nbsp;{propDescLastLineTail[0]}</span>}
                {addTooltip && (
                  <JDPTooltip
                    title={proposalLayDesc.long.join(" ")}
                    iconType={ICON_NONE}
                    clickOpen
                    parentClose={anchorEl === null}
                    interactive
                    width={480}
                    marginRight={30}
                    // bgcolor="#efefef"
                    marginBottom={-1}
                    marginTop={-1}
                  >
                    <span
                      style={{
                        fontStyle: "italic",
                        color: "#005982",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      {propDescLastLineTail[1]}
                    </span>
                  </JDPTooltip>
                )}
              </ListItem>
            );
          })}

          <ListItem
            divider // ={item.title !== "Proposal acceptance date"}
            classes={{
              root: `${classes.listItem}`,
            }}
          />
        </List>
      )}

      {/* footer links */}
      <List>
        <ListItem
          classes={{
            root: `${classes.bodySmall} ${classes.menuHeader}`,
          }}
        >
          Open in new tab
        </ListItem>
        <ListItem
          classes={{
            root: `${classes.h6Desktop} ${classes.menuTitle}`,
          }}
        >
          Links for more info
        </ListItem>
        <ListItem
          disabled={Boolean(!getURL(DETAILS))}
          className={classes.item}
          onClick={handleKebabClose}
        >
          {getDropdownLink(getURL(DETAILS), DETAILS)}
        </ListItem>
        <ListItem
          disabled={Boolean(!getURL(CITATION))}
          className={classes.item}
          onClick={handleKebabClose}
          // divider={ffkebab}
        >
          {getDropdownLink(getURL(CITATION), CITATION)}
        </ListItem>

        {showEco ? (
          <ListItem className={classes.item} onClick={handleKebabClose}>
            {getDropdownLink(
              "https://gold.jgi.doe.gov/ecosystem_classification",
              "Ecosystem Classifications"
            )}
          </ListItem>
        ) : null}
      </List>
    </Menu>
  );
};

export default GenomeKebab;

GenomeKebab.defaultProps = {
  row: {},
  honeycomb: {
    sendUiInteractionSpan: () => {},
  },
  anchorEl: null,
  setAnchorEl: null,
  ldClient: null,
};

GenomeKebab.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.shape()),
    kingdom: PropTypes.string,
    portal_detail_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    pi: PropTypes.shape({
      name: PropTypes.string,
      email_address: PropTypes.string,
    }),
    proposal_acceptance_date: PropTypes.string,
    work_completion_date: PropTypes.string,
    dus_status: PropTypes.string,
    eco_data: PropTypes.shape(),
    gold_proj_data: PropTypes.shape(),
    superseded_by: PropTypes.string,
    proposal_lay_description: PropTypes.string,
    agg_id: PropTypes.number,
  }),
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
  }),
  anchorEl: PropTypes.shape(),
  setAnchorEl: PropTypes.func,
  ldClient: PropTypes.shape(),
};
