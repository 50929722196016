import React, { useRef, useContext, useState } from "react";
import { PropTypes } from "prop-types";
import { useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Paper, Typography, IconButton, Link, Box } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { makeStyles } from "@material-ui/core/styles";

import SegmentSelect from "./SegmentSelect/SegmentSelect";
import SearchBox from "./SearchBox/SearchBox";
import FilterToggle from "./FilterToggle/FilterToggle";
import { useSearchParams } from "../../hooks/useSearchParams";
import ConfigContext from "../../context/ConfigContext";

import classes from "./BentoBox.module.css";
import Tooltip, { ICON_WARN } from "../UI/Tooltip/Tooltip";

import CopyNoticeTooltip from "../UI/Tooltip/TransientTooltip/TransientTooltip";

import { useApi } from "../../hooks/useApi";
import { base as ldbase } from "../../utils/LDFFUtils";

const useStyles = makeStyles(() => ({
  iconButtonLabel: {
    display: "flex",
    flexDirection: "column",
  },
}));

const optionsList = [
  { value: "search", label: "Everything" },
  { value: "img", label: "IMG" },
  { value: "phytozome", label: "Phytozome" },
  { value: "mycocosm", label: "MycoCosm/PhycoCosm" },
];

const BentoBox = ({
  variant,
  hasFilters,
  options,
  disableSearch,
  ldClient,
  ...props
}) => {
  const { filterExpanded, honeycomb } = props;
  const [dividerVisibility, setDividerVisibility] = useState(true);
  const { q, searchParams: baseSearchParams } = useSearchParams();
  const { segment } = useParams();
  const { allowNoParamSearch } = useContext(ConfigContext);

  const copyToClipboardRef = useRef(null);

  const [apiCopied, setApiCopied] = useState(false);

  // State of the current url params, needed to reroute when one or the other changes
  const [query, setQuery] = useState(q || "");
  const [urlSegment, setUrlSegment] = useState(segment);

  // const history = useHistory();
  const { searchURL } = useApi();

  const new_filter_ff = ldbase(ldClient, "filter-on-left");

  const filtersDisabled = () => {
    if (baseSearchParams.toString() === "" && !allowNoParamSearch) {
      return true;
    }
    return false;
  };

  const updateUrlQuery = (newQuery) => {
    setQuery(newQuery);

    if (newQuery === null) return;

    if (newQuery === "") {
      const newLoc =
        urlSegment === "search" ? "/search" : `/refine-download/${urlSegment}`;
      window.location = newLoc;
      // history.push(newLoc);
      return;
    }

    const searchParams = new URLSearchParams();
    searchParams.set("q", newQuery.trim());
    const queryString = searchParams.toString(); // uri encoded
    const newLoc =
      urlSegment === "search"
        ? `/search?${queryString}`
        : `/refine-download/${urlSegment}?${queryString}`;

    // we want a clean state on every new query value, so issue a real redirect.
    window.location = newLoc;
    // history.push(newLoc);
  };

  const updateUrlSegment = (newSegment) => {
    setUrlSegment(newSegment);
    if (!newSegment) return;
    const searchParams = new URLSearchParams(`q=${query}`);
    const queryString = searchParams.toString();

    let newLoc =
      newSegment === "search" ? "/search" : `/refine-download/${newSegment}`;
    newLoc += query ? `?${queryString}` : "";

    // we want a clean state on every new query value, so issue a real redirect.
    window.location = newLoc;
    // history.push(newLoc);
  };

  const handleApiCopied = () => {
    setApiCopied(true);
    setTimeout(() => {
      setApiCopied(false);
    }, 1500);
  };

  // the search tool: segment + search UI + filter
  let segmentSearchFilter = (
    <Paper
      elevation={0}
      variant={variant}
      className={disableSearch ? classes.roundBoxDisabled : classes.roundBox}
    >
      <SegmentSelect
        className={
          disableSearch ? classes.selectBoxDisabled : classes.selectBox
        }
        options={options}
        setDividerVisibility={setDividerVisibility}
        updateUrlSegment={updateUrlSegment}
        {...props}
        disabled={disableSearch}
      />
      {dividerVisibility ? (
        <Typography className={classes.divider}>|</Typography>
      ) : (
        <Typography className={classes.dividerHidden}>|</Typography>
      )}

      <SearchBox
        className={
          disableSearch ? classes.searchBoxDisabled : classes.searchBox
        }
        setDividerVisibility={setDividerVisibility}
        updateUrlQuery={updateUrlQuery}
        setQuery={setQuery}
        {...props}
        disabled={disableSearch}
      />
    </Paper>
  );
  if (disableSearch) {
    segmentSearchFilter = (
      <Tooltip
        iconType={ICON_WARN}
        title="Please go to the homepage to start a search for public data."
        followCursor
      >
        {segmentSearchFilter}
      </Tooltip>
    );
  }

  // The Copy API UI
  const btnClass = useStyles();
  const copyAPITool = (
    <Box
      style={{
        width: 48,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        visibility: disableSearch ? "hidden" : "default",
      }}
    >
      <CopyToClipboard
        text={searchURL}
        ref={copyToClipboardRef}
        onCopy={() => {
          handleApiCopied();
          honeycomb.sendUiInteractionSpan("copy-api-performed");
        }}
      >
        {/* Can't use the JDP Button component in child - a limitation of mui */}
        <CopyNoticeTooltip
          noticeLabel={apiCopied ? "Value copied to clipboard" : null}
          labelConf={
            apiCopied
              ? null
              : [
                  {
                    type: "title",
                    value: "Copy API Search Query to Clipboard",
                  },
                  {
                    type: "node",
                    value: (
                      <Typography key="api-ref">
                        <Link
                          href="https://sites.google.com/lbl.gov/data-portal-help/home/tips_tutorials/api-tutorial?authuser=0#h.kl5mmcyatsql"
                          target="_blank"
                          style={{ textDecoration: "underline" }}
                          onClick={() => {
                            honeycomb.sendUiInteractionSpan(
                              "doc-link-for-copy-api-clicked"
                            );
                          }}
                        >
                          Learn more
                        </Link>{" "}
                        about the API.
                      </Typography>
                    ),
                  },
                ]
          }
          child={
            <IconButton
              id="copy-api-ibutton"
              classes={{ label: btnClass.iconButtonLabel }}
              sx={{
                textTransform: "none",
                fontSize: 14,
                fontFamily: "Public Sans, sans-serif",
                fontStyle: "normal",
                fontWeight: 600,
                letterSpacing: 0.16,
                textAlign: "left",
              }}
              type="button"
              variant="contained"
              size="small"
              color="secondary"
              onClick={(e) => {
                copyToClipboardRef.current.onClick(e);
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ContentCopyIcon style={{ color: classes.lake400 }} />
                <Typography style={{ color: "#000" }}>API</Typography>
              </Box>
            </IconButton>
          }
          open={apiCopied}
        />
      </CopyToClipboard>
    </Box>
  );

  return (
    <div
      id="bento-box-top-div"
      className={classes.bentoBoxGrid}
      style={
        filterExpanded ? { borderBottom: `1px solid ${classes.grey300}` } : {}
      }
    >
      {segmentSearchFilter}
      {hasFilters && !new_filter_ff ? (
        <FilterToggle {...props} disabled={filtersDisabled()} />
      ) : null}
      {copyAPITool}
    </div>
  );
};

export default BentoBox;

BentoBox.defaultProps = {
  variant: "elevation",
  hasFilters: false,
  options: optionsList,
  filterExpanded: false,
  disableSearch: false,
  ldClient: null,
};

BentoBox.propTypes = {
  variant: PropTypes.string,
  hasFilters: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape()),
  filterExpanded: PropTypes.bool,
  disableSearch: PropTypes.bool,
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
  }).isRequired,
  ldClient: PropTypes.shape(),
};
