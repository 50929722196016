import React from "react";
import PropTypes from "prop-types";

import { Button } from "@material-ui/core";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import classes from "./TableContainer.module.css";

export const RIGHT_ICON_NONE = "RIGHT_ICON_NONE";
export const RIGHT_ICON_HELP = "RIGHT_ICON_HELP";

const ReturnLink = ({
  leftLabel,
  leftClick,
  leftDisable,
  rightLabel,
  rightIcon,
  rightClick,
  rightDisable,
}) => (
  <div style={{ marginTop: 20, display: "flex" }}>
    <div style={{ paddingTop: 10 }}>
      <ArrowBackIosIcon />
    </div>
    {/* left button */}
    <Button
      className={`${classes.link}`}
      style={{
        textTransform: "none",
        fontSize: 18,
        position: "relative",
        left: -8,
      }}
      disabled={leftDisable}
      onClick={leftClick}
    >
      {leftLabel}
    </Button>

    {/* right button */}
    {(rightLabel || rightIcon) && (
      <Button
        className={`${classes.link}`}
        style={{
          textTransform: "none",
          fontSize: 18,
          marginLeft: "auto", // align to far-right
        }}
        disabled={rightDisable}
        onClick={rightClick}
      >
        {rightIcon === RIGHT_ICON_HELP && (
          <InfoOutlinedIcon style={{ paddingRight: 4 }} />
        )}
        {rightLabel}
      </Button>
    )}
  </div>
);

export default ReturnLink;
ReturnLink.defaultProps = {
  leftDisable: false,
  rightIcon: RIGHT_ICON_NONE,
  rightDisable: false,
  leftClick: null,
  rightClick: null,
  rightLabel: null,
};

ReturnLink.propTypes = {
  leftLabel: PropTypes.string.isRequired,
  leftClick: PropTypes.func,
  rightLabel: PropTypes.string,
  rightClick: PropTypes.func,
  leftDisable: PropTypes.bool,
  rightDisable: PropTypes.bool,
  rightIcon: PropTypes.string,
};
