import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Dialog from "../../UI/Dialogs/DraggableDialog/DraggableDialog";

import DataUtilization from "../../Pages/DataUtilization/DataUtilization";

import cookieUtil from "../../../utils/CookieHandler";
import { dupVersion } from "../../../utils/LDFFUtils";

const DUPDlg = (props) => {
  const { currentUser, handleCancel, handleAccept, honeycomb, ldClient } =
    props;

  const [startTime, setStartTime] = useState();

  useEffect(() => {
    setStartTime(new Date());
  }, []);

  // eslint-disable-next-line no-case-declarations
  const dupVer = dupVersion(ldClient);
  return (
    <Dialog
      cancelBtnFn={() => {
        const now = new Date();
        honeycomb.sendUiInteractionSpan(`dup-from-infoboard-cancel`, {
          openTime: startTime,
          closeTime: now,
          openDuration: now - startTime,
          unit: "ms",
        });
        handleCancel();
      }}
      cancelBtnText="Return to previous page"
      acceptBtnFn={() => {
        const now = new Date();
        honeycomb.sendUiInteractionSpan(`dup-from-infoboard-accept`, {
          openTime: startTime,
          closeTime: now,
          openDuration: now - startTime,
          unit: "ms",
        });
        cookieUtil.setDUPCookie(currentUser.key, dupVer);
        handleAccept();
      }}
      acceptBtnText="Agree to these terms"
    >
      <DataUtilization close={handleCancel} />
    </Dialog>
  );
};

export default DUPDlg;

DUPDlg.propTypes = {
  currentUser: PropTypes.shape({
    name: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    keycloak: PropTypes.bool,
  }).isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired,
  honeycomb: PropTypes.shape().isRequired,
  ldClient: PropTypes.shape().isRequired,
};
