/* istanbul ignore file */
import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link, Typography } from "@material-ui/core/";

import TextField from "@mui/material/TextField";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/material/styles";

import Tooltip, { ICON_NONE } from "../../UI/Tooltip/Tooltip";
import theme from "../../BentoBox/BentoBox.module.css";

import { useSearchParams } from "../../../hooks/useSearchParams";

/*
  styling TextField.
  Ref https://codesandbox.io/s/colortextfields-material-demo-forked-hpwc1?fontsize=14&hidenavigation=1&theme=dark&file=/demo.js:1042-1058
*/
const CssTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== "focusColor",
})((p) => ({
  // input label when focused
  "& label.Mui-focused": {
    color: p.focusColor,
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: p.focusColor,
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: p.focusColor,
  },
  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      // borderColor: p.focusColor,
      border: p.focusColor,
    },
  },
}));

const AdvancedFileNameInput = ({
  applyFilter,
  honeycomb,
  onleft,
  inputLabelStyle,
}) => {
  const [regx, setRegx] = useState("");
  const { fregx, searchParams, FILE_NAME_REGX_KEY } = useSearchParams();

  useEffect(() => {
    if (fregx !== regx) {
      setRegx(fregx);
    }
  }, [fregx]);

  const applyAdvanceFileName = () => {
    const oldVal = searchParams.get(FILE_NAME_REGX_KEY);
    if (oldVal !== regx) {
      setRegx(oldVal);
      if (regx) {
        searchParams.set(FILE_NAME_REGX_KEY, regx);
      } else {
        searchParams.delete(FILE_NAME_REGX_KEY);
      }

      const queryString = searchParams.toString();
      applyFilter(`?${queryString}`);
      if (honeycomb) {
        // to match filter update HC log in src/components/FilterContainer/Filters/FilterMultiSelect.jsx
        const hcSpan = {
          filter: {
            field: "file_name_pattern",
            value: oldVal || "",
            "updated-value": regx,
          },
        };
        honeycomb.sendUiInteractionSpan(`filter-change`, hcSpan);
      }
    }
  };

  return (
    <>
      <CssTextField
        className="filePattern" // so css defined in App.css can be applied
        focusColor="1px solid #00829B"
        label={onleft ? "File name pattern" : "Enter a file name pattern here"}
        placeholder="File name pattern"
        size="small"
        variant="outlined"
        InputProps={{
          style: onleft
            ? {
                margin: "8px 16px",
                padding: "0px 14px 0px 0px",
                height: 44,
              }
            : {
                minWidth: 300,
                width: "100%",
                // maxWidth: 400,
              },
          endAdornment: (
            <Tooltip
              title={
                <Box>
                  <Typography paragraph>
                    Example:
                    <span
                      style={{
                        fontWeight: "bold",
                        fontStyle: "italic",
                        backgroundColor: "#F5FFB5",
                        padding: 2,
                      }}
                    >
                      {" "}
                      {`.*assembled\\.faa`}
                    </span>
                    {` to search for "assembled.faa" files`}
                  </Typography>
                  <Typography paragraph>
                    <Link
                      component={RouterLink}
                      style={{ textDecoration: "underline" }}
                      target="_blank"
                      to="//www.elastic.co/guide/en/elasticsearch/reference/5.6/query-dsl-regexp-query.html#regexp-syntax"
                    >
                      Learn more
                    </Link>{" "}
                    about ElasticSearch regular expressions.
                  </Typography>
                </Box>
              }
              iconType={ICON_NONE}
            >
              <InfoOutlinedIcon
                style={{
                  position: "relative",
                  right: -10,
                  width: 30,
                  height: 30,
                  color: theme.lake600,
                }}
              />
            </Tooltip>
          ),
        }}
        InputLabelProps={{
          // label style - overwrite CssTextField
          sx: { "&.Mui-focused": { color: "#006073" } },
          style: onleft ? { ...inputLabelStyle, padding: "10px 14px" } : null,
        }}
        value={regx || ""}
        onKeyPress={(event) => {
          // validate contact id on Enter key
          if (event.key === "Enter") {
            event.preventDefault();
            if (applyFilter) {
              applyAdvanceFileName();
            }
          }
        }}
        onChange={(event) => {
          event.preventDefault();
          const val = event.target.value || "";
          setRegx(val.trim());
        }}
        onBlur={() => {
          if (applyFilter) {
            applyAdvanceFileName();
          }
        }}
        sx={{ width: "100%" }}
      />
    </>
  );
};

export default AdvancedFileNameInput;

AdvancedFileNameInput.defaultProps = {
  applyFilter: null,
  honeycomb: null,
  onleft: false,
  inputLabelStyle: {},
};

AdvancedFileNameInput.propTypes = {
  applyFilter: PropTypes.func,
  honeycomb: PropTypes.shape(),
  onleft: PropTypes.bool,
  inputLabelStyle: PropTypes.shape(),
};
