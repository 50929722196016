/* istanbul ignore file */
import React from "react";
import PropTypes from "prop-types";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import ClearIcon from "@material-ui/icons/Clear";
import Chip from "@mui/material/Chip";

import BentoBox from "../BentoBox/BentoBox";
import Filters from "./Filters/Filters";
import FilterFooter from "./Filters/FilterFooter";
import DividerWithText, {
  TP_HEAD,
} from "../UI/DividerWithText/DividerWithText";
import classes from "./FilterContainer.module.css";
import filterClasses from "./Filters/Filters.module.css";
import { useSearchParams } from "../../hooks/useSearchParams";
import FilterGroup from "./FilterGroup/FilterGroup";

import Tooltip, { ICON_NONE } from "../UI/Tooltip/Tooltip";

const FilterContainer = (props) => {
  const {
    filterExpanded,
    data,
    onleft,
    ldClient,
    applyFilter,
    filterGroups,
    filterCountMap,
    honeycomb,
  } = props;

  const { searchParams, FILE_NAME_REGX_KEY } = useSearchParams();

  /** Delete the key, value filter pair from the URL */
  const handleDelete = (key, value = null) => {
    if (!searchParams.has(key)) return;

    const pvalue = searchParams.get(key);
    const filterParams =
      key === FILE_NAME_REGX_KEY ? pvalue : pvalue.split(",");

    if (key === FILE_NAME_REGX_KEY) {
      searchParams.delete(key);
    } else {
      const index = filterParams.indexOf(value);
      if (index > -1 && filterParams.length > 1) {
        // Remove value from list of values for this filter
        filterParams.splice(index, 1);
        searchParams.set(key, filterParams);
      } else if (index > -1) {
        // Only 1 filter value, remove whole filter
        searchParams.delete(key);
      }
    }

    // Send delete to HC
    honeycomb.sendUiInteractionSpan(`filter-remove-option`, {
      filter: {
        field: key,
        value: value || "",
        "updated-value": filterParams,
      },
    });

    // update URL
    const queryString = searchParams.toString();
    applyFilter(`?${queryString}`);
  };

  // local helper: TODO - consider to store this in a state to avoid multiple invocations?
  const getFilterConfig = () => {
    const config = {};
    filterGroups?.forEach((d) => {
      config[d.label] = d.config;
    });
    const flatterned = []; // flatterned
    Object.keys(config).forEach((label) => {
      const d = config[label];
      if (d) {
        // eslint-disable-next-line prefer-spread
        flatterned.push.apply(flatterned, d);
      }
    });
    return { config, flatterned };
  };
  // if config has values prefer title as label
  const getChipLabel = (paramKey, paramValue, flatternedConfig) => {
    const config = flatternedConfig.find((el) => el.filterId === paramKey);
    const label = config.labeler ? config.labeler(paramValue) : paramValue;
    return { label, title: config.filterTitle };
  };

  const getFilterChips = () => {
    const chips = [];
    if (!filterGroups) return chips;

    const chipStyle = onleft
      ? { margin: "0px 4px 4px 0px" }
      : {
          color: "#00829b",
          backgroundColor: "#ffffff",
          borderColor: "#00829b",
          fontSize: "14px",
          fontWeight: 600,
          margin: "4px",
        };
    const chipSX = onleft
      ? {
          "& .MuiChip-label": {
            color: "#302E2E",
            fontFamily: "Public Sans",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 400,
          },
        }
      : {};
    const chipProps = {
      variant: "outlined",
      style: { ...chipStyle },
      sx: { ...chipSX },
      deleteIcon: (
        <ClearIcon
          className={filterClasses.deleteIcon}
          style={onleft ? { color: "#00829B" /* height: 28, width: 28 */ } : {}}
        />
      ),
    };

    const filterConfig = getFilterConfig();

    const paramEntries = searchParams.entries();
    let searchParam = paramEntries.next();
    while (!searchParam.done) {
      const paramKey = searchParam.value[0];
      const pvalue = searchParam.value[1];
      const paramValues = pvalue.split(",");

      if (paramKey === FILE_NAME_REGX_KEY) {
        chipProps.label = decodeURIComponent(pvalue);
        chipProps.id = paramKey + pvalue;
        chipProps.key = paramKey + pvalue;
        chips.push({
          key: "advanced-file-name",
          tip: "File Property / file name",
          chip: (
            <Chip
              {...chipProps}
              onDelete={() => {
                handleDelete(paramKey);
              }}
            />
          ),
        });
      } else {
        Object.keys(filterConfig.config).forEach((label) => {
          const d = filterConfig.config[label];
          if (d && d.some((config) => config.filterId === paramKey)) {
            paramValues.forEach((paramValue) => {
              const chipInfo = getChipLabel(
                paramKey,
                decodeURIComponent(paramValue),
                filterConfig.flatterned
              );

              chipProps.label = chipInfo.label;
              chipProps.id = paramKey + paramValue;
              chipProps.key = paramKey + paramValue;
              const chip = (
                <Chip
                  {...chipProps}
                  onDelete={() => {
                    handleDelete(paramKey, paramValue);
                  }}
                />
              );

              chips.push({
                key: `${label}_${chipInfo.title}_${paramValue}`,
                tip: `${label} / ${chipInfo.title}`,
                chip,
              });
            });
          }
        });
      }
      searchParam = paramEntries.next();
    }

    const tipedChips = chips.map((d) => (
      <Tooltip
        iconType={ICON_NONE}
        key={d.key}
        title={d.tip}
        bottomOffset={-12}
      >
        {d.chip}
      </Tooltip>
    ));

    if (onleft) {
      return <Box style={{ padding: "12px 12px 6px" }}>{tipedChips}</Box>;
    }
    return tipedChips;
  };

  if (onleft) {
    return filterGroups ? (
      <Box>
        {getFilterChips()}
        <List
          sx={{ width: "99%", bgcolor: "background.paper" }}
          component="nav"
        >
          <Divider />
          {filterGroups
            .filter((fg) => Boolean(fg.config))
            .map((fg) => {
              return (
                <FilterGroup
                  key={fg.label}
                  label={fg.label}
                  filterConfig={fg.config}
                  data={data}
                  ldClient={ldClient}
                  applyFilter={applyFilter}
                  activeCount={filterCountMap[fg.label]}
                  honeycomb={honeycomb}
                />
              );
            })}
        </List>
        <FilterFooter {...props} onleft />
      </Box>
    ) : null;
  }

  return (
    <>
      <BentoBox hasFilters {...props} />
      <Collapse className={classes.filtersCollapse} in={filterExpanded}>
        {filterGroups &&
          filterGroups.map((d) => {
            return (
              d.config && (
                <Box key={d.label}>
                  <DividerWithText position={TP_HEAD}>
                    {d.label}
                  </DividerWithText>
                  <Filters
                    {...props}
                    label={d.label}
                    filterConfig={d.config}
                    data={data}
                  />
                </Box>
              )
            );
          })}
        <Divider />
        <FilterFooter {...props} getFilterChips={getFilterChips} />
      </Collapse>
    </>
  );
};

export default FilterContainer;

FilterContainer.defaultProps = {
  filterExpanded: false,
  data: null,
  ldClient: null,
  relevantIndex: null,
  filterGroups: null,
  filterCountMap: {},
};

FilterContainer.propTypes = {
  filterExpanded: PropTypes.bool,
  data: PropTypes.shape(),
  ldClient: PropTypes.shape(),
  relevantIndex: PropTypes.number,
  onleft: PropTypes.bool.isRequired,
  applyFilter: PropTypes.func.isRequired,
  filterGroups: PropTypes.arrayOf(PropTypes.shape()),
  honeycomb: PropTypes.shape().isRequired,
  filterCountMap: PropTypes.shape(),
};
