import React from "react";
import PropTypes from "prop-types";

import { useScrollTrigger, Zoom } from "@material-ui/core";

const distanceOfFilterFromTop = (refNode, headerNode, offset = 0) => {
  if (refNode?.current && headerNode?.current) {
    return (
      refNode.current.getBoundingClientRect().top -
      headerNode.current.getBoundingClientRect().top +
      offset
    );
  }
  return 0;
};

const HideOnScroll = (props) => {
  const { children, unmountOnExit, mountOnEnter, refNode, topRef } = props;
  const threshold = distanceOfFilterFromTop(refNode, topRef);
  const trigger = useScrollTrigger({
    /* this 1 pixel offset is a fix for a useScrollTrigger bug we haven't figured out
     it prevents infinite calling of hide/show when scrolling near the threshold value */
    threshold: threshold - 1,
    disableHysteresis: false,
  });
  return (
    <Zoom
      id="hide-on-scroll"
      in={!trigger}
      unmountOnExit={unmountOnExit}
      mountOnEnter={mountOnEnter}
    >
      {children}
    </Zoom>
  );
};

HideOnScroll.defaultProps = {
  unmountOnExit: false,
  mountOnEnter: false,
  refNode: null,
  topRef: null,
};

HideOnScroll.propTypes = {
  refNode: PropTypes.shape(),
  topRef: PropTypes.shape(),
  children: PropTypes.element.isRequired,
  unmountOnExit: PropTypes.bool,
  mountOnEnter: PropTypes.bool,
};

const ShowOnScroll = (props) => {
  const { children, unmountOnExit, mountOnEnter, refNode, topRef, offset } =
    props;
  const threshold = distanceOfFilterFromTop(refNode, topRef, offset);
  const trigger = useScrollTrigger({
    threshold,
    disableHysteresis: true,
  });
  return (
    <Zoom
      id="show-on-scroll"
      in={trigger}
      unmountOnExit={unmountOnExit}
      mountOnEnter={mountOnEnter}
    >
      {children}
    </Zoom>
  );
};

ShowOnScroll.defaultProps = {
  unmountOnExit: false,
  mountOnEnter: false,
  refNode: null,
  topRef: null,
  offset: 0,
};

ShowOnScroll.propTypes = {
  refNode: PropTypes.shape(),
  topRef: PropTypes.shape(),
  children: PropTypes.element.isRequired,
  unmountOnExit: PropTypes.bool,
  mountOnEnter: PropTypes.bool,
  offset: PropTypes.number,
};

export { HideOnScroll, ShowOnScroll };
