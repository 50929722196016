import React from "react";
import PropTypes from "prop-types";

import { Box, Grid } from "@material-ui/core/";
import { Skeleton } from "@material-ui/lab";

import classes from "./Filters.module.css";
import FilterMultiSelect from "./FilterMultiSelect";

import AdvancedFileNameInput from "./AdvancedFileNameInput";

const Filters = (props) => {
  const { filterConfig, data, label, ldClient } = props;

  return (
    <Box className={classes.rootBox} style={{ borderBottom: "none" }}>
      <Grid
        container
        direction="row"
        spacing={2}
        justify="flex-start"
        alignItems="center"
        className={classes.gridContainer}
      >
        {!data || data.error
          ? [1, 2, 3, 4].map((item) => (
              <Skeleton
                variant="text"
                animation="wave"
                width={219}
                height={56}
                key={item}
                style={{ margin: "0px 8px" }}
              />
            ))
          : filterConfig.map((config) => {
              const facet = config.filterOptions
                ? data[config.filterOptions][config.filterId]
                : data.facets[config.filterId];
              if (!config.ld || (ldClient && ldClient.variation(config.ld))) {
                return (
                  <FilterMultiSelect
                    {...props}
                    config={config}
                    facet={facet}
                    key={config.filterId}
                  />
                );
              }
              return null;
            })}
        {label.toLowerCase() === "file property" &&
          (!data || data.error ? (
            <Grid sx={6}>
              <Skeleton
                variant="text"
                animation="wave"
                width={219}
                height={56}
                key={5}
                style={{ margin: "0px 8px" }}
              />
            </Grid>
          ) : (
            <Grid sx={6}>
              <AdvancedFileNameInput
                {...props}
                config={{ filterTitle: "Enter a file name pattern here" }}
                facet={{}}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default Filters;

Filters.defaultProps = {
  filterConfig: [],
  data: null,
  count: 0,
  ldClient: null,
  label: null,
};

Filters.propTypes = {
  filterConfig: PropTypes.arrayOf(PropTypes.shape()),
  data: PropTypes.shape(),
  count: PropTypes.number,
  ldClient: PropTypes.shape(),
  label: PropTypes.string,
};
