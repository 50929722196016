/* istanbul ignore file */
import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { Link } from "@material-ui/core";

import Card, { ICON_EVENTS, ICON_NEWS, ICON_NEW_RELEASES } from "../Card/Card";
import classes from "./HomePage.module.css";

const FootCards = ({ honeycomb }) => {
  const linkClass = `${classes.bodyBase} ${classes.linkInCard}`;

  const getLinkComp = (label, href, title) => {
    const props = {
      className: linkClass,
      href,
      target: "_blank",
      onClick: () => {
        if (honeycomb) {
          honeycomb.sendUiInteractionSpan("homepage-link-click", {
            link: `${label}/${href}`,
          });
        }
      },
    };
    if (title) {
      props.title = title;
    }
    return <Link {...props}>{label}</Link>;
  };

  const cardNewReleaseLinkText =
    "See new datasets that have become public in the last 30 days";
  const cardNewRelease = (
    <>
      New genomes are released daily!{" "}
      {getLinkComp(
        cardNewReleaseLinkText,
        "/search?since=30",
        cardNewReleaseLinkText
      )}
      .
    </>
  );

  const cardNewsLinkText = "recently published studies";
  const cardNews = (
    <>
      Get links to{" "}
      {getLinkComp(
        cardNewsLinkText,
        "https://jgi.doe.gov/news-publications/",
        cardNewsLinkText
      )}{" "}
      that incorporate JGI-sequenced data.
    </>
  );

  const cardEventLinkText = "Register for upcoming JGI webinars";
  const cardEvents = (
    <>
      {getLinkComp(
        cardEventLinkText,
        "https://jgi.doe.gov/news-publications/webinars/",
        cardEventLinkText
      )}{" "}
      on a variety of topics.
    </>
  );

  return (
    <>
      <div>
        <Grid container spacing={4}>
          <Grid item md={4} sm={12}>
            <Card
              title="New releases"
              content={cardNewRelease}
              icon={ICON_NEW_RELEASES}
              ctype="borderless"
            />
          </Grid>
          <Grid item md={4} sm={12}>
            <Card
              title="JGI in the news"
              content={cardNews}
              icon={ICON_NEWS}
              ctype="borderless"
            />
          </Grid>
          <Grid item md={4} sm={12}>
            <Card
              title="Upcoming events"
              content={cardEvents}
              icon={ICON_EVENTS}
              ctype="borderless"
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default FootCards;

FootCards.defaultProps = {
  honeycomb: null,
};

FootCards.propTypes = {
  honeycomb: PropTypes.shape(),
};
