/* istanbul ignore file */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { useHistory } from "react-router-dom";

import SearchAutocomplete from "../SearchAutocomplete/SearchAutocomplete";

import classes from "./GiantSearchBar.module.css";

const useStyles = makeStyles(() => ({
  input: {
    "&::placeholder": {
      color: classes.grey400,
    },
  },
  /* we need a label for accessibility, but for mysterious reasons
  the label shows up in the upper left of the window, so we hide it */
  root: {
    "& .MuiFormLabel-root": {
      display: "none",
    },
  },
  endAdornment: {
    marginTop: "-10px",
  },
  popper: {
    margin: "11px 0px",
  },
  option: {
    // override first option selected with same color as focus
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
      borderColor: "transparent",
    },
    '&[data-focus="true"]': {
      backgroundColor: classes.lake25,
    },
    '&[aria-selected="true"][data-focus="true"]': {
      backgroundColor: classes.lake25,
    },
  },
}));

const GiantSearchBar = (props) => {
  const { honeycomb } = props;

  const history = useHistory();

  const updateUrlQuery = (query) => {
    if (!query) return;

    const searchParams = new URLSearchParams();
    searchParams.set("q", query.trim());
    const queryString = searchParams.toString(); // uri encoded string
    const updatedLocation = `/search?${queryString}`;

    // we want a clean state on every new query value, so issue a real redirect.
    // window.location = updatedLocation;
    history.push(updatedLocation);
  };

  const searchAutocompleteProps = {};
  searchAutocompleteProps.updateUrlQuery = updateUrlQuery;
  searchAutocompleteProps.honeycomb = honeycomb;
  const myStyles = useStyles();
  searchAutocompleteProps.autoStyling = {
    closeIcon: <ClearIcon className={classes.clearIcon} />,
    classes: {
      endAdornment: myStyles.endAdornment,
      option: `${myStyles.option} ${classes.bodyLargeDesktop}`,
      popper: myStyles.popper,
    },
    className: classes.autoComplete,
  };
  searchAutocompleteProps.textInputStyling = {
    classes: { root: myStyles.root },
    className: classes.searchBar,
    style: { margin: "0 auto" },
    margin: "normal",
    variant: "standard",
    placeholder: "Search by organism, project name/ID, or PI name",
  };
  searchAutocompleteProps.inputProps = {
    classes: { input: myStyles.input },
    style: {
      marginTop: 0,
      paddingRight: 0,
      fontSize: "22px",
      fontWeight: "bold",
      color: classes.lake600,
    },
    disableUnderline: true,
  };
  searchAutocompleteProps.startAdornment = {
    className: classes.searchIcon,
  };

  return <SearchAutocomplete {...searchAutocompleteProps} />;
};

export default GiantSearchBar;

GiantSearchBar.defaultProps = {
  fullScreen: false,
  honeycomb: {
    sendUiInteractionSpan: () => {},
  },
};

GiantSearchBar.propTypes = {
  fullScreen: PropTypes.bool,
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
  }),
};
