import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

import NoDraggableDialog from "../../../UI/InfoBoard/Standalone/NoDraggableDialog";
import { infoText, infoTextBold } from "./styles";

const DuplicatesDlg = ({ dupIds, cancelBtnFn, proceedBtnFn }) => {
  // console.log("[DuplicatesDlg]", dupIds);
  return (
    <NoDraggableDialog
      title="ORCID duplicate"
      width={800}
      cancelCrossFn={cancelBtnFn}
      acceptBtnText="Proceed Without Duplicates"
      acceptBtnFn={proceedBtnFn}
      cancelBtnText="Cancel"
      cancelBtnFn={cancelBtnFn}
    >
      <Typography sx={infoText} paragraph>
        You have provided duplicate ORCID ids in your submission. You can either
        proceed and drop the duplicate(s), or go back to make corrections in
        your input.
      </Typography>
      <Typography sx={infoTextBold} paragraph>
        ORCID id{dupIds.length > 1 ? "s" : null} affected:
      </Typography>
      <Typography sx={infoText} paragraph>
        {dupIds.join(", ")}
      </Typography>
    </NoDraggableDialog>
  );
};

export default DuplicatesDlg;

DuplicatesDlg.propTypes = {
  dupIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  cancelBtnFn: PropTypes.func.isRequired,
  proceedBtnFn: PropTypes.func.isRequired,
};
