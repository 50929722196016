/* istanbul ignore file */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import IconButton from "@mui/material/IconButton";

import UnknownOrcidDlg from "./Dialogs/UnknownOrcidDlg";
import DuplicatedsDlg from "./Dialogs/DuplicatesDlg";
import ReadyToGrantDlg from "./Dialogs/ReadyToGrantDlg";
import Button from "../../Button/Button";
import classes from "./DataGrid.module.css";

import ErrorInfo from "../../UI/ErrorInfo/ErrorInfo";
import autil from "../../../utils/ArrayUtils";
import { isValidOrcid } from "../../../utils/StringUtils";

import { validateORCIDs } from "../../../utils/HttpClientProvider";
import ORCIDHelpDlg from "./Dialogs/ORCIDHelpDlg";
import MimickingGrantWithProdWarningDlg from "./Dialogs/MimickingGrantWithProdWarningDlg";
import cookieUtil from "../../../utils/CookieHandler";

// issue type consts
const IT_BAD_FORMAT = "IT_BAD_FORMAT";
const IT_NO_USER = "IT_NO_USER";
const IT_DUPS = "IT_DUPS";
const IT_BAD_ORCID = "IT_BAD_ORCID";
const IT_MIMIC_WITH_PMOS_PROD = "IT_MIMIC_WITH_PMOS_PROD";

const GrantAccessControls = ({
  rows,
  doGrantAccess,
  busyHandler,
  setHTTPError,
  honeycomb,
  currentUser,
}) => {
  const [idList, setIdList] = useState(null);
  const [text, setText] = useState("");
  const [issues, setIssues] = useState(null);
  const [grantData, setGrantData] = useState(null);
  const [showORCIDHelpDlg, setShowORCIDHelpDlg] = useState(false);

  const btnStyle = {
    height: 40,
    width: 200,
    margin: "8px 0px 24px",
  };

  const onNewInput = (value) => {
    if (issues !== null) {
      setIssues(null);
    }

    setText(value);
    if (value.length > 18) {
      setIdList(value.split(/[\s,;]+/).filter((d) => d !== ""));
    } else if (idList !== null) {
      setIdList(null);
    }
  };

  const textChangeHandler = (e) => {
    onNewInput(e.target.value);
  };

  const formatCheck = () => {
    const badIDs = [];
    idList.forEach((d) => {
      if (!isValidOrcid(d)) {
        badIDs.push(d);
      }
    });
    if (badIDs.length > 0) {
      setIssues({ type: IT_BAD_FORMAT, data: badIDs });
      return false;
    }

    return true;
  };

  const dupCheck = () => {
    // chech duplications
    const dups = autil.FindDuplicates(idList);
    if (dups?.length > 0) {
      setIssues({ type: IT_DUPS, data: dups });
      return false;
    }

    return true;
  };

  const callDoGrantAccess = () => {
    setIssues(null);
    doGrantAccess(grantData);
    setText("");
    setGrantData(null);
  };

  const grantAccessButtonHandler = (uniqueIds = null) => {
    // console.log("[grantAccessButtonHandler]");
    let pass = true;
    if (uniqueIds) {
      onNewInput(uniqueIds.join(", "));
      setIssues(null);
    } else {
      pass = formatCheck();
      if (pass) {
        pass = dupCheck();
      }
    }

    if (pass) {
      const ids = uniqueIds || idList;
      busyHandler(true);
      const promise = validateORCIDs(ids, honeycomb);
      promise
        .then((resp) => {
          if (!resp.error) {
            const { data } = resp;
            const bad = [];
            const good = [];
            ids.forEach((id) => {
              if (data[id].error) {
                bad.push(id);
              } else {
                good.push({ ...data[id], orcid_id: id });
              }
            });
            if (bad.length > 0) {
              setIssues({ type: IT_BAD_ORCID, data: { good, bad } });
            } else {
              const users = {};
              good.forEach((d) => {
                users[d.orcid_id] = { ...d };
              });
              // console.log(
              //   "[grantAccessButtonHandler setGrantData]",
              //   rows,
              //   users
              // );
              setGrantData({
                data: rows,
                users,
              });
            }
          } else {
            setHTTPError(resp.error, resp.status);
          }
        })
        .finally(() => {
          busyHandler(false);
        });
    }
  };

  const INPUT_WIDTH = 600;
  // The format of the following ORCID id(s) is incorrect:
  const issueInfo =
    issues?.type === IT_BAD_FORMAT ? (
      <ErrorInfo
        id="bad-orcid-format"
        width={INPUT_WIDTH}
        message={`The format of the following ORCID id${
          issues.data.length > 1 ? "s" : ""
        } is incorrect: ${issues.data.join(", ")}`}
      />
    ) : issues?.type === IT_NO_USER ? (
      <ErrorInfo
        id="bad-orcid-format"
        width={INPUT_WIDTH}
        message={`No user found for the following ORCID id${
          issues.data.length > 1 ? "s" : ""
        } ${issues.data.join(", ")}`}
      />
    ) : null;

  // console.log("[Grant]", JSON.stringify(grantData));
  const dialog =
    issues?.type === IT_BAD_ORCID ? (
      <UnknownOrcidDlg
        data={issues.data}
        cancelBtnFn={() => {
          setIssues(null);
        }}
        proceedBtnFn={() => {
          setIssues(null);
          setGrantData({
            data: rows,
            users: issues.data.good,
          });
        }}
      />
    ) : issues?.type === IT_DUPS ? (
      <DuplicatedsDlg
        dupIds={issues.data}
        cancelBtnFn={() => {
          setIssues(null);
        }}
        proceedBtnFn={() => {
          grantAccessButtonHandler(autil.FindUniques(idList));
        }}
      />
    ) : issues?.type === IT_MIMIC_WITH_PMOS_PROD ? (
      <MimickingGrantWithProdWarningDlg
        cancelBtnFn={() => {
          setIssues(null);
          setGrantData(null);
        }}
        proceedBtnFn={() => {
          honeycomb.sendUiInteractionSpan(
            "grant-access-with-mimic-and-pmos-prod",
            {
              mimic: JSON.stringify(currentUser.mimic),
              grantTo: JSON.stringify(Object.keys(grantData.users)),
              grantIds: JSON.stringify(grantData.data.map((d) => d.id)),
            }
          );
          callDoGrantAccess();
        }}
      />
    ) : (
      grantData && (
        <ReadyToGrantDlg
          data={grantData}
          cancelBtnFn={() => {
            setGrantData(null);
          }}
          proceedBtnFn={() => {
            if (currentUser.mimic && cookieUtil.getPMOSEnv() !== "staging") {
              setIssues({
                type: IT_MIMIC_WITH_PMOS_PROD,
              });
            } else {
              callDoGrantAccess();
            }
          }}
        />
      )
    );

  const useStyles = makeStyles(() => ({
    root: {
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          "border-color": `${
            issues?.type === IT_BAD_FORMAT ? "#F00" : classes.lake500
          }`,
        },
      },
    },
  }));

  const inputStyles = useStyles();

  const footerStyle = { fontSize: 12, color: "#767676", marginLeft: "8px" };
  return (
    <Box sx={{ marginLeft: "8px", marginBottom: 6 }}>
      <Button
        data-testid="grant-access-btn"
        size="medium"
        style={btnStyle}
        disabled={idList === null || rows?.length === 0}
        onClick={() => {
          grantAccessButtonHandler();
        }}
      >
        Grant Access
      </Button>
      <Box>
        <TextField
          error={issues?.type === IT_BAD_FORMAT}
          data-testid="orcid-input"
          // inputRef={(input) => input && input.focus()} // default to focus, but also grab input focus on when typing in filters
          // inputProps={{ autoFocus: true }} // should input focus on mount, but doesn't work
          className={`${inputStyles.root}`} // the border color on focus
          id="outlined-basic"
          variant="outlined"
          label="ORCID(s)"
          placeholder="ORCID(s) (Required)"
          value={text}
          multiline
          maxRows={4}
          sx={{
            minWidth: `${INPUT_WIDTH}px`,
            "& label": {
              "&.Mui-focused": {
                color: `${
                  issues?.type === IT_BAD_FORMAT ? "#F00" : classes.lake500
                }`, // label color on border
              },
            },
          }}
          InputLabelProps={{
            shrink: true, // to display both label and placeholder
          }}
          onChange={textChangeHandler}
        />
        {issueInfo}
        <Typography sx={footerStyle}>
          Separate ORCID(s) with a comma in order to manage access for multiple
          users.
          <Link
            // className={classes.link}
            component={RouterLink}
            target="_blank"
            to="//orcid.org/orcid-search/search"
            onClick={() => {}}
          >
            <span> Look up ORCID(s)</span>
          </Link>
          .
        </Typography>
        <Typography sx={footerStyle}>
          {`My target user doesn't have an ORCID account. What should I do?`}{" "}
          <IconButton
            sx={{
              color: classes.lake500,
              margin: 0,
              padding: 0,
            }}
            onClick={() => {
              setShowORCIDHelpDlg(true);
            }}
          >
            <LiveHelpIcon />
          </IconButton>
        </Typography>
      </Box>
      {dialog}
      {showORCIDHelpDlg && (
        <ORCIDHelpDlg
          cancelBtnFn={() => {
            setShowORCIDHelpDlg(false);
          }}
        />
      )}
    </Box>
  );
};

export default GrantAccessControls;

GrantAccessControls.defaultProps = {
  setHTTPError: null,
  honeycomb: null,
};
GrantAccessControls.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  doGrantAccess: PropTypes.func.isRequired,
  busyHandler: PropTypes.func.isRequired,
  setHTTPError: PropTypes.func,
  honeycomb: PropTypes.shape(),
  currentUser: PropTypes.shape().isRequired,
};
