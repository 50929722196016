/* istanbul ignore file */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

import classes from "./DividerWithText.module.css";

// text positions
export const TP_LEFT = "left"; // LABEL ---------
export const TP_HEAD = "head"; // - LABEL -------
export const TP_CENTER = "center"; // ---- LABEL ----
export const TP_TAIL = "tail"; // ------- LABEL -
export const TP_RIGHT = "right"; // --------- LABEL

/*
      <div style={{ width: "100%", padding: "40px" }}>
        <DividerWithText position="left">left</DividerWithText>
        <DividerWithText position="head">head</DividerWithText>
        <DividerWithText position="center">center</DividerWithText>
      </div>
*/
const DividerWithText = ({ children, position }) => {
  const useStyles = makeStyles((theme) => ({
    content: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.0),
      paddingRight: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
      // - font
      // fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "266%",
      letterSpacing: "1px",
      textTransform: "uppercase",
      color: "black",
      opacity: 0.5,
      whiteSpace: "nowrap",
      position: "relative",
      top: -2,
    },
  }));

  const classesEx = useStyles();
  const lDash =
    position !== TP_LEFT ? (
      <div
        className={classes.border}
        style={position === TP_HEAD ? { width: 10 } : null}
      /> //
    ) : null;

  const rDash =
    position !== TP_RIGHT ? (
      <div
        className={classes.border}
        style={position === TP_TAIL ? { width: 10 } : null}
      /> //
    ) : null;

  return (
    <div className={classes.container}>
      {lDash}
      <span className={classesEx.content}>{children}</span>
      {rDash}
    </div>
  );
};
export default DividerWithText;

DividerWithText.defaultProps = {
  children: null,
  position: "center",
};

DividerWithText.propTypes = {
  children: PropTypes.node,
  position: PropTypes.string,
};
