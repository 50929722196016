import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";

import SearchAutocomplete from "../../SearchAutocomplete/SearchAutocomplete";
import classes from "../BentoBox.module.css";

const useStyles = makeStyles(() => ({
  /* we need a label for accessibility, but for mysterious reasons
  the label shows up in the upper left of the window, so we hide it */
  root: {
    "& .MuiFormLabel-root": {
      display: "none",
    },
  },
  endAdornment: {
    marginTop: "-2px",
  },
  popper: {
    margin: "9px 0px",
  },
  option: {
    // override first option selected with same color as focus
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
      borderColor: "transparent",
    },
    '&[aria-selected="true"][data-focus="true"]': {
      backgroundColor: classes.lake25,
    },
  },
}));

const SearchBox = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const {
    honeycomb,
    className,
    setQuery,
    updateUrlQuery,
    setDividerVisibility,
    // for SearchAutocomplete to handle new search with selections
    addToCartHandler,
    withSelections,
    disabled,
  } = props;
  const myStyles = useStyles();
  const searchAutocompleteProps = {};
  searchAutocompleteProps.updateUrlQuery = updateUrlQuery;
  searchAutocompleteProps.setQuery = setQuery;

  searchAutocompleteProps.honeycomb = honeycomb;
  const closeIconClasses = `${classes.clearIcon} ${
    isHovered && !isOpen ? classes.hovered : ""
  }`;
  searchAutocompleteProps.autoStyling = {
    closeIcon: <ClearIcon className={closeIconClasses} />,
    openOnFocus: true,
    classes: {
      popper: myStyles.popper,
      endAdornment: myStyles.endAdornment,
      option: myStyles.option,
    },
    className,
  };
  searchAutocompleteProps.textInputStyling = {
    classes: { root: myStyles.root },
    style: { margin: "0 auto" },
    margin: "normal",
    color: "primary",
    placeholder: "Search by organism, project name/ID, or PI name",
  };
  searchAutocompleteProps.startAdornment = {
    className: classes.searchIcon,
  };
  searchAutocompleteProps.inputProps = {
    style: {
      marginTop: 0,
      paddingRight: 0,
      fontWeight: "bold",
      color: classes.lake600,
    },
    edge: "start",
    disableUnderline: true,
  };
  searchAutocompleteProps.extraProps = {
    onMouseEnter: () => {
      setDividerVisibility(false);
      setIsHovered(true);
    },
    onMouseLeave: () => {
      setDividerVisibility(!isOpen);
      setIsHovered(false);
    },
    onMouseDown: () => {
      setDividerVisibility(false);
    },
    onFocus: () => {
      setDividerVisibility(false);
    },
    onBlur: () => {
      setDividerVisibility(true);
    },
  };

  return (
    <SearchAutocomplete
      disabled={disabled}
      setIsOpen={setIsOpen}
      {...searchAutocompleteProps}
      addToCartHandler={addToCartHandler}
      withSelections={withSelections}
    />
  );
};

export default SearchBox;

SearchBox.defaultProps = {
  updateUrlQuery: () => {},
  setQuery: () => {},
  honeycomb: {
    sendUiInteractionSpan: () => {},
  },
  className: "",
  setDividerVisibility: () => {},
  addToCartHandler: null,
  withSelections: null,
  disabled: false,
};

SearchBox.propTypes = {
  updateUrlQuery: PropTypes.func,
  setQuery: PropTypes.func,
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
  }),
  className: PropTypes.string,
  setDividerVisibility: PropTypes.func,
  addToCartHandler: PropTypes.func,
  withSelections: PropTypes.bool,
  disabled: PropTypes.bool,
};
