import React from "react";
import { PropTypes } from "prop-types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Grid from "@mui/material/Grid";
// import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { grey600 } from "../../../../themes/theme.module.css";

import TooltipBase, { ICON_NONE } from "../Tooltip";

const TransientTooltip = ({
  iconType,
  labelConf,
  noticeLabel,
  child,
  open,
  noticeBottomOffset,
  bottomOffset,
  placement,
}) => {
  const buildtip = () => {
    // the optional classic hover tooltip
    if (labelConf) {
      const titleComp = (
        <Box>
          {labelConf.map((d, idx) => {
            const cid = `jgi-tip-${idx}`;
            if (d.type === "title") {
              return <Box key={cid}>{d.value}</Box>;
            }
            if (d.type === "node") {
              return d.value;
            }
            return null;
          })}
        </Box>
      );

      return (
        <TooltipBase
          iconType={iconType}
          placement={placement}
          bottomOffset={bottomOffset} // the main tooltip y-offset relative to archor
          title={titleComp}
        >
          {child}
        </TooltipBase>
      );
    }

    // the notice floating "tooltip"
    if (noticeLabel) {
      const noticeUI = (text) => (
        <Grid container sx={{ backgroundColor: `${grey600}` }}>
          <Grid item xs={1}>
            <CheckCircleIcon
              style={{
                color: "#32de84",
              }}
            />
          </Grid>
          <Grid item>
            <div
              style={{
                paddingLeft: "16px",
                paddingTop: "2px",
                fontSize: "14px",
                color: "#FFFFFF",
              }}
            >
              {text}
            </div>
          </Grid>
        </Grid>
      );
      const noticeTipProps = {
        title: noticeLabel ? noticeUI(noticeLabel) : "notice",
        arrow: false, // remove arrow
        bgcolor: `${grey600}`, // set darkgray background
        placement: "top", // show on top of archor element
        controlledOpen: open, // open up by client
        bottomOffset: noticeBottomOffset, // the notice tooltip y-offset relative to archor
        PopperProps: {
          disablePortal: true,
        },
      };
      if (placement) {
        noticeTipProps.placement = placement;
      }
      return (
        <TooltipBase
          iconType={ICON_NONE}
          data-testid="avatar-tool-tip"
          {...noticeTipProps}
        >
          {child}
        </TooltipBase>
      );
    }
    return <>{child}</>;
  };

  return buildtip();
};

export default TransientTooltip;

TransientTooltip.defaultProps = {
  iconType: ICON_NONE,
  noticeLabel: null,
  labelConf: null,
  noticeBottomOffset: -20,
  bottomOffset: -20,
  placement: "bottom",
};

TransientTooltip.propTypes = {
  iconType: PropTypes.string,
  noticeLabel: PropTypes.string,
  labelConf: PropTypes.arrayOf(PropTypes.shape()),
  child: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  noticeBottomOffset: PropTypes.number,
  bottomOffset: PropTypes.number,
  placement: PropTypes.string,
};
