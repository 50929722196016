import React from "react";
import { Box, Typography } from "@material-ui/core/";
import FilterListIcon from "@material-ui/icons/FilterList";
import PropTypes from "prop-types";

export default function EmptyFilter({ main, sub }) {
  // const { updateYour } = props;
  return (
    <Box
      style={{
        height: "120px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
      data-testid="no-results-found"
    >
      <FilterListIcon
        style={{
          width: 120,
          height: 120,
          color: "#C8C8C8",
        }}
        viewBox="-5 -5 32.7 32.7"
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography
          display="block"
          style={{
            fontSize: 44,
            color: "#919191",
            fontWeight: "bold",
            marginLeft: -3,
            paddingTop: 12,
          }}
        >
          {main}
        </Typography>

        <div display="block" style={{ fontSize: 16, marginTop: -10 }}>
          {sub}
        </div>
      </Box>
    </Box>
  );
}

EmptyFilter.defaultProps = {
  main: "No results found",
  sub: "Update your search to find the data you're looking for.",
};

EmptyFilter.propTypes = {
  main: PropTypes.string,
  sub: PropTypes.node,
};
