import React from "react";

import { PropTypes } from "prop-types";

import Avatar from "@mui/material/Avatar";

import classes from "./JDPAvatar.module.css";

const JDPAvatar = ({ archor, label, size, color, pointer, handleClick }) => {
  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let clr = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      clr += `00${value.toString(16)}`.slice(-2);
    }
    return clr;
  };

  const sizeMap = {
    small: 24,
    medium: 32,
    large: 40,
  };
  const sizeParam = () => {
    const d = sizeMap[size]; // size === "small" ? 30 : 40;
    return { width: d, height: d };
  };

  const avatarLabel = (name) => {
    let nlabel = name.trim().toUpperCase();
    if (nlabel.indexOf(" ") > 0) {
      const toks = nlabel.split(" ");
      nlabel = `${toks[0][0]}${toks[1][0]}`;
    } else if (nlabel.length > 2) {
      nlabel = nlabel.substring(0, 2);
    }
    const bclr = color ? stringToColor(nlabel) : classes.grey450;

    return {
      sx: {
        bgcolor: bclr,
        ...sizeParam(),
      },
      children: nlabel,
    };
  };

  const localStyle = {};
  if (size === "small") {
    localStyle.fontSize = 16;
  }
  if (!pointer) {
    localStyle.cursor = "default";
  }

  return (
    <Avatar
      ref={archor}
      className={classes.userAvatar}
      {...avatarLabel(label)}
      style={localStyle}
      onClick={handleClick}
    />
  );
};

export default JDPAvatar;

JDPAvatar.defaultProps = {
  archor: null,
  label: "Label",
  size: "medium",
  color: false,
  pointer: true,
  handleClick: null,
};

JDPAvatar.propTypes = {
  archor: PropTypes.shape(),
  label: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.bool,
  pointer: PropTypes.bool,
  handleClick: PropTypes.func,
};
