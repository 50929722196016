import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Link } from "@material-ui/core";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import classes from "./ErrorMessage.module.css";
import jgiLogo from "../../../assets/images/jgi_logo.jpg";
import nerscLogo from "../../../assets/images/nersc_logo.png";

export const ERR_DATA = "ERR_DATA";
export const ERR_NOT_FOUND = "Not Found";
export const ERR_NERSC_POWER_OUTAGE = "ERR_NERSC_POWER_OUTAGE";
export const ERR_SYSTEM_OUTAGE = "ERR_SYSTEM_OUTAGE";
export const ERR_BAD_FILTER = "bad filter key";
export const ERR_API_CALL = "api call failure";
export const ERR_NETWORK = "Error: Network Error";
export const ERR_FILTER_RECORDS = "ERR_FILTER_RECORDS";

const JGI = "JGI";
const NERSC = "NERSC";
const NERSC_MOTD = "https://www.nersc.gov/live-status/motd/";

export default function ErrorMessage(props) {
  const {
    type,
    cancel,
    accept,
    text,
    cancelLabel,
    acceptLabel,
    cancelStyle,
    acceptStyle,
    honeycomb,
    downtime,
  } = props;
  const [rendered, setRendered] = useState(false);
  const { pathname } = useLocation();
  let { logo, title, message, honeycombMsg } = {};

  if (text) {
    // eslint-disable-next-line no-console
    console.warn(type, text, "[ErrorMessage]");
  }

  logo = JGI;
  switch (type) {
    case ERR_DATA:
    case ERR_API_CALL:
    case ERR_NETWORK:
      title = "Data not loading";
      message = (
        <>
          <Typography variant="subtitle1" className={classes.subtitle}>
            We’re currently unable to access the data needed for this page.
          </Typography>
          <Typography variant="subtitle1">
            Please visit again later or contact us for assistance.
          </Typography>
        </>
      );
      honeycombMsg = "data-not-loading";
      break;
    case ERR_NOT_FOUND:
    case ERR_FILTER_RECORDS:
      title = "Page not found";
      message = (
        <Typography variant="subtitle1" className={classes.subtitle}>
          We’re sorry — we couldn’t find the page you’re looking for.
        </Typography>
      );
      honeycombMsg = `page-not-found: ${pathname}`;
      break;
    case ERR_NERSC_POWER_OUTAGE:
      logo = NERSC;
      title = "Planned NERSC power outage";
      message = (
        <>
          <Typography variant="subtitle1" className={classes.text}>
            Due to a planned NERSC power outage, JGI portals will be down from:
          </Typography>
          <Typography variant="subtitle1" className={classes.downtime}>
            {downtime}
          </Typography>
          <Typography variant="subtitle1" className={classes.text}>
            System Status updates available at{" "}
            <Link href={NERSC_MOTD}>{NERSC_MOTD}</Link>
          </Typography>
        </>
      );
      honeycombMsg = "planned-nersc-power-outage";
      break;
    case ERR_SYSTEM_OUTAGE:
      title = "Unplanned system outage";
      message = (
        <>
          <Typography variant="subtitle1" className={classes.subtitle}>
            We’re sorry — our system is temporarily down.
          </Typography>
          <Typography variant="subtitle1">Please visit again later.</Typography>
        </>
      );
      honeycombMsg = "unplanned-system-outage";
      break;

    default:
      break;
  }

  useEffect(() => {
    if (honeycombMsg && !rendered) {
      honeycomb.sendProcessingSpan(`show-message-${honeycombMsg}`);
      setRendered(true);
    }
  }, [honeycomb, honeycombMsg, rendered]);

  return honeycombMsg ? (
    <Grid
      container
      direction="column"
      spacing={2}
      className={classes.gridContainer}
    >
      <Grid item>
        <Grid container spacing={3} wrap="nowrap">
          <Grid item>
            <img
              src={logo === JGI ? jgiLogo : nerscLogo}
              className={logo === JGI ? classes.jgiLogo : classes.nerscLogo}
              alt={`${logo} logo`}
            />
          </Grid>
          <Grid item>
            <Typography variant="h1">{title}</Typography>
            <br />
            {message}
            <br />
          </Grid>
        </Grid>
      </Grid>
      {(cancel || accept) && (
        <Grid item>
          <Grid container spacing={2} wrap="nowrap" justify="flex-end">
            {cancel && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  style={cancelStyle}
                  onClick={() => {
                    honeycomb.sendUiInteractionSpan(
                      "clicked-error-message-cancel"
                    );
                    cancel();
                  }}
                >
                  <Typography>{cancelLabel}</Typography>
                </Button>
              </Grid>
            )}
            {accept && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  style={acceptStyle}
                  onClick={() => {
                    honeycomb.sendUiInteractionSpan(
                      "clicked-error-message-accept"
                    );
                    accept();
                  }}
                >
                  <Typography>{acceptLabel}</Typography>
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  ) : null;
}

ErrorMessage.defaultProps = {
  cancel: null,
  accept: null,
  text: null,
  cancelLabel: "Contact Us",
  acceptLabel: "Return Home",
  cancelStyle: { backgroundColor: "#427107" },
  acceptStyle: { backgroundColor: "#005982" },
  honeycomb: {
    sendProcessingSpan: () => {},
    sendUiInteractionSpan: () => {},
  },
  downtime: null,
};

ErrorMessage.propTypes = {
  type: PropTypes.string.isRequired,
  cancel: PropTypes.func,
  accept: PropTypes.func,
  text: PropTypes.string,
  cancelLabel: PropTypes.string,
  acceptLabel: PropTypes.string,
  cancelStyle: PropTypes.shape(),
  acceptStyle: PropTypes.shape(),
  honeycomb: PropTypes.shape({
    sendProcessingSpan: PropTypes.func,
    sendUiInteractionSpan: PropTypes.func,
  }),
  downtime: PropTypes.string,
};
