/* eslint-disable no-console */
import arrayUtil from "./ArrayUtils";

const withPropPrefix = (obj, prefix) =>
  Object.keys(obj).reduce(
    (acc, key) => ({ ...acc, [`${prefix}.${key}`]: obj[key] }),
    {}
  );

export const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

// in-place remove an element from the give array
export const rmArrayElementByValue = (arr, val) => {
  if (!Array.isArray(arr)) {
    console.log(
      `not an array : ${JSON.stringify(arr)}`,
      "[rmArrayElementByValue]"
    );
    return;
  }
  const idx = arr.indexOf(val);
  if (idx > -1) arr.splice(idx, 1);
};

export const rmArrayElementByIndex = (arr, index) => {
  if (!Array.isArray(arr)) {
    console.log(
      `not an array : ${JSON.stringify(arr)}`,
      "[rmArrayElementByIndex]"
    );
    return;
  }
  if (index > -1 && index < arr.length) arr.splice(index, 1);
};

// if 2 dictionary of primative values are equal
export const dictOfPrimativeEqual = (dict1, dict2) => {
  if (
    !dict1 ||
    !dict2 ||
    typeof dict1 !== "object" ||
    typeof dict2 !== "object" ||
    Array.isArray(dict1) ||
    Array.isArray(dict2)
  ) {
    return false;
  }

  const keys1 = Object.keys(dict1);
  const keys2 = Object.keys(dict2);
  if (!arrayUtil.ArrayOfPrimativeEqual(keys1, keys2)) return false;

  for (let idx = 0; idx < keys1.length; idx++) {
    const kname = keys1[idx];
    if (dict1[kname] !== dict2[kname]) return false;
  }
  return true;
};

// keep only @fields, also excludes @exFields
export const objShallowClone = (oriObj, fields, exFields = []) => {
  const newObj = {};

  const keys = fields || Object.keys(oriObj);
  keys.forEach((key) => {
    if (!exFields.includes(key)) {
      newObj[key] = oriObj[key];
    }
  });
  return newObj;
};

export default withPropPrefix;
