/* istanbul ignore file */
import { KINGDOM_PHYTOZOME } from "../config/appConfig";

const getFileType = (row, kingdom) => {
  // JDP-2312
  if (kingdom === KINGDOM_PHYTOZOME) {
    const ftype = row.metadata?.type;
    return typeof ftype === "string" && ftype.length > 0
      ? `${ftype[0].toUpperCase()}${ftype.substring(1)}`
      : ftype;
  }

  if (!row.file_type) {
    return "";
  }

  const display_location = row.metadata?.portal?.display_location;
  let types = [];
  if (display_location) {
    if (typeof display_location === "string") {
      // some records with string value
      if (display_location !== "Mycocosm") {
        types.push(display_location);
      }
    } else {
      // must be an array
      types = display_location.filter((t) => t !== "Mycocosm");
    }
  }
  return types ? types.join(", ") : "";
};

export const genomeCount = (data) =>
  data?.organisms ? data.organisms.length : 0;

export default getFileType;
