/* istanbul ignore file */
import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import ModalContentBase from "../../UI/InfoBoard/ModalContentBase";

import cookieUtil from "../../../utils/CookieHandler";

import Dialog, {
  TT_RED500,
  TT_ORANGE600,
  TT_LAKE500,
} from "../../UI/Dialogs/DraggableDialog/DraggableDialog";

import {
  DF_BTN_INFO,
  DF_BTN_WARNING,
  DF_BTN_ERROR,
} from "../../UI/Dialogs/DialogFooter";

import {
  globusRequest,
  getGlobusUser,
} from "../../../utils/HttpClientProvider";

import googlea from "../../../utils/GoogleAnalytics";

// import { dupVersion, base as ldbase } from "../../../../utils/LDFFUtils";
import { dupVersion } from "../../../utils/LDFFUtils";
import { useFileAndPageContext } from "../../../context/FileAndPageContext";

import GlobusDownloadUI from "./GlobusDownloadUI";
import DataUtilization from "../../Pages/DataUtilization/DataUtilization";
import GlobusDownloadWrongUser from "./GlobusDownloadWrongUser";
import GlobusDownloadOKWithId from "./GlobusDownloadOKWithId";

const GlobusDownloadDlg = ({
  currentUser,
  handleClose,
  fileSummary,
  ldClient,
  honeycomb,
  data,
}) => {
  const [globusUser, setGlobusUser] = useState({
    name: "",
    initializing: true,
  });

  // globus id validation results: {valid: bool, message: str}
  const [globusUserStatus, setGlobusUserStatus] = useState({
    valid: true,
    message: null,
  });

  const [requesting, setRequesting] = useState(false);
  const [requestid, setRequestID] = useState(null);
  const [transferInProgress, setTransferInProgress] = useState(false);
  const [transferRequestError, setTransferRequestError] = useState(null);

  const dupVer = dupVersion(ldClient); // "1.0";
  const { clearCartData } = useFileAndPageContext();

  const [dupAgreed, setDUPAgreed] = useState(
    cookieUtil.isDUPAgreed(currentUser.key, dupVer)
  );

  const doGlobusFileTransfer = () => {
    setRequesting(true); // disable close to prevent dismiss during the delayed user checking and download request
    const promise = getGlobusUser(globusUser?.name);
    promise
      .then((resp) => {
        if (resp.error || !resp.user_id) {
          setGlobusUserStatus({ valid: false, message: resp.error });
        } else {
          setTransferInProgress(true);
        }
      })
      .catch(() => {})
      // .catch((e) => {
      //   console.log(e, "[doGlobusFileTransfer] call getGlobusUser error");
      // })
      .finally(() => {
        setRequesting(false);
      });
  };

  useEffect(() => {
    if (transferInProgress) {
      // add google analytics and HC trace
      const trace = honeycomb.buildTrace(honeycomb.getTraceID());
      const tstart = Date.now();
      googlea.add("globus-download");
      googlea.add("tape-download-request");

      const hcPayload = {
        now_file_count: fileSummary.ondiskCount,
        tape_file_count: fileSummary.purgedIds.length,
        now_file_size: fileSummary.ondiskSizeInBytes,
        tape_file_size: fileSummary.purgedSizeInBytes,
        genome_count: fileSummary.organismCount,
      };

      data.globus_user_name = globusUser.name;
      data.send_mail = true;
      data.send_confirmation = true;

      const promoise = globusRequest(honeycomb.getTraceHeader(trace), data);
      promoise
        .then((resp) => {
          if (resp.error) {
            hcPayload.duration = Date.now() - tstart;
            hcPayload.error = resp.error;

            if (resp.data.status) {
              hcPayload.status = resp.data.status;
            }
            honeycomb.sendDownloadSpan(
              "globus-download-failure",
              hcPayload,
              trace
            );
            setTransferRequestError(resp.error);
          } else {
            hcPayload.duration = Date.now() - tstart;
            hcPayload.status = 200;
            hcPayload.resp = resp.data;

            const rid = resp.data.request_id;
            if (rid) {
              honeycomb.sendDownloadSpan(
                "globus-download-success",
                hcPayload,
                trace
              );
              setRequestID(rid);
              // clear cart
            } else {
              honeycomb.sendDownloadSpan(
                "globus-download-error",
                hcPayload,
                trace
              );
              setTransferRequestError(
                `Bad response from server: ${JSON.stringify(resp.data)}`
              );
            }
          }
        })
        .finally(() => {
          setTransferInProgress(false);
        });
    }
  }, [transferInProgress]);

  // only initialize the globus user from BE once, at loading
  // console.log(`globusUser=`, globusUser, transferInProgress, "[GD]");
  useEffect(() => {
    let cancelled = false;

    if (cancelled) {
      return;
    }

    // console.log("[GD] call getGlobusUser()");
    const promise = getGlobusUser();
    promise.then((resp) => {
      const gname = resp.username ? resp.username : "";
      setGlobusUser({ name: gname, initializing: false });
    });
    // .catch(() => {
    //   setGlobusUser({ name: "", initializing: false });
    // });

    return () => {
      cancelled = true;
    };
  }, []);

  const handleEdit = (event) => {
    // console.log(event.target.value, "[GD]");
    setGlobusUser({ ...globusUser, name: event.target.value });
    setGlobusUserStatus({ valid: true, message: null });
    setRequesting(false);
  };

  const content = globusUserStatus.message ? (
    <GlobusDownloadWrongUser
      globusUser={globusUser.name}
      error={globusUserStatus.message}
    />
  ) : requestid ? (
    <GlobusDownloadOKWithId
      requestid={requestid}
      currentUser={currentUser}
      fileSummary={fileSummary}
    />
  ) : (
    <GlobusDownloadUI
      fileSummary={fileSummary}
      currentUser={currentUser}
      globusUserStatus={globusUserStatus}
      globusUser={globusUser}
      handleEdit={handleEdit}
      doGlobusFileTransfer={doGlobusFileTransfer}
      transferRequestError={transferRequestError}
      transferInProgress={transferInProgress}
    />
  );

  let title = "Globus Download";
  let ttype = TT_LAKE500;
  let btnColor = DF_BTN_INFO;
  let submitBtnText = "Submit";
  let cancelBtnText = "Cancel";
  let submitBtnFn = doGlobusFileTransfer;
  const cancelBtnFn = handleClose;

  if (requestid) {
    title = "Download Request Confirmed";
    submitBtnText = "Close";
    submitBtnFn = handleClose;
    cancelBtnText = null;
  } else if (!globusUserStatus.valid) {
    btnColor = DF_BTN_ERROR;
    ttype = TT_RED500;
    submitBtnFn = null;
    submitBtnText = null;
  } else if (globusUser.name.trim() === "") {
    ttype = TT_ORANGE600;
    btnColor = DF_BTN_WARNING;
  }

  const doClearCart = () => {
    if (requestid) {
      clearCartData();
    }
  };

  if (!dupAgreed) {
    return (
      <Dialog
        data-testid="dup-in-globus-download"
        id="dup-in-globus-download"
        cancelBtnFn={handleClose}
        cancelBtnText="Return to previous page"
        acceptBtnFn={() => {
          cookieUtil.setDUPCookie(currentUser.key, dupVer);
          setDUPAgreed(true);
        }}
        acceptBtnText="Agree to these terms"
      >
        <DataUtilization close={handleClose} />
      </Dialog>
    );
  }

  return (
    <Dialog
      topType={ttype}
      cancelBtnFn={
        cancelBtnText
          ? () => {
              doClearCart();
              cancelBtnFn();
            }
          : null
      }
      acceptBtnFn={() => {
        doClearCart();
        submitBtnFn();
      }}
      cancelBtnText={cancelBtnText}
      acceptBtnText={submitBtnText}
      btnColor={btnColor}
    >
      <ModalContentBase
        titleText={title}
        closeFn={
          requesting
            ? () => {}
            : () => {
                doClearCart();
                handleClose();
              }
        }
      >
        {content}
      </ModalContentBase>
    </Dialog>
  );
};
export default GlobusDownloadDlg;

GlobusDownloadDlg.defaultProps = {
  handleClose: null,
  data: { ids: [], organisms: 0, filecnt: 0 },
};

GlobusDownloadDlg.propTypes = {
  handleClose: PropTypes.func,
  fileSummary: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape().isRequired,
  ldClient: PropTypes.shape().isRequired,
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
    sendDownloadSpan: PropTypes.func,
    buildTrace: PropTypes.func,
    getTraceID: PropTypes.func,
    getTraceHeader: PropTypes.func,
  }).isRequired,
  data: PropTypes.shape(),
};
