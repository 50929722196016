import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

import { useFileAndPageContext } from "../../../context/FileAndPageContext";
import NoDraggableDialog from "../../UI/InfoBoard/Standalone/NoDraggableDialog";

export const NEED_LOGIN = "NEED_LOGIN";

const SearchProjectsDlg = ({ reason, cancelBtnFn }) => {
  // console.log("[SearchProjectsDlg]", dupIds);

  const { handleLogin } = useFileAndPageContext();
  const getTitle = () => {
    switch (reason) {
      case NEED_LOGIN:
        return "Login required";
      default:
        return "Request has issue";
    }
  };

  const getContent = () => {
    switch (reason) {
      case NEED_LOGIN:
        return (
          <>
            <Typography paragraph>
              Log in to see all datasets (public + private) you are authorized
              to see.
            </Typography>
          </>
        );
      default:
        return <Typography> Has unknown issue </Typography>;
    }
  };

  return (
    <NoDraggableDialog
      title={getTitle()}
      width={800}
      cancelCrossFn={cancelBtnFn}
      acceptBtnFn={handleLogin}
      acceptBtnText="Login"
      cancelBtnFn={cancelBtnFn}
      cancelBtnText="Close"
    >
      {getContent()}
    </NoDraggableDialog>
  );
};

export default SearchProjectsDlg;

SearchProjectsDlg.propTypes = {
  cancelBtnFn: PropTypes.func.isRequired,
  reason: PropTypes.string.isRequired,
};
