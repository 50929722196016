import React from "react";

import Document from "../Document/Document";

import data from "./data";
import classes from "./DataUtilization.module.css";

const DataUtilization = () => (
  <div id="data-utilization-policy" className={classes.main}>
    <Document title={data.title} paragraphs={data.paragraphs} />
  </div>
);
export default DataUtilization;
