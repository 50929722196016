import { createMuiTheme } from "@material-ui/core/styles";
import merriweather from "@fontsource/merriweather";
import publicSans from "@fontsource/public-sans";
import oswald from "@fontsource/oswald";
import classes from "./theme.module.css";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Public Sans, sans-serif",
    h1: {
      fontFamily: "Merriweather",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "40px",
      color: "#000",
    },
    h2: {
      fontSize: "28px",
      color: "#000",
    },
    h3: {
      fontFamily: "Public Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "22px",
      color: "#302E2E",
    },
    h4: {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#000",
    },
    h5: {
      fontFamily: "Public Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "24px",
      alignItems: "center",
      letterSpacing: "0.15px",
      color: "#000",
      display: "inline",
    },
    h6: {
      fontFamily: "Public Sans",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "24px",
      color: "#000",
      display: "inline",
    },
    subtitle1: {
      fontFamily: "Public Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      color: "#5C5C5C",
    },
    subtitle2: {
      fontFamily: "Public Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "27px",
      display: "inline",
      color: "#000",
    },
  },
  palette: {
    primary: { main: "#005982", dark: "#162E51" },
    secondary: { main: "#007600", dark: "#006200" },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [publicSans, oswald, merriweather],
      },
    },
    MuiTable: {
      stickyHeader: {
        borderCollapse: "collapse",
      },
    },
    MuiTableRow: {
      root: {
        padding: "0px",
      },
    },
    MuiTableCell: {
      root: {
        padding: "0px 16px",
        borderBottom: "0px",
      },
      stickyHeader: {
        zIndex: "auto",
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#747474",
        borderRadius: "8px",
        color: "#FFFFFF",
        fontSize: "16px",
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: classes.lake500,
        "&$checked": {
          color: classes.lake500,
        },
        "&$disabled": {
          color: classes.grey300,
        },
      },
    },
    MuiRadio: {
      colorPrimary: {
        color: "#535353",
        "&$checked": {
          color: "#005EA2",
        },
        "&$disabled": {
          color: "#979797",
        },
      },
    },
    MuiChip: {
      colorPrimary: {
        color: "#FFFFFF",
        backgroundColor: "#005982",
      },
      outlinedPrimary: {
        color: "#535353",
        border: "1px solid #535353",
      },
    },
    MuiButton: {
      containedPrimary: {
        boxShadow: "0 0 0 0",
        backgroundColor: classes.moss400,
        "&:hover": {
          backgroundColor: classes.moss500,
          boxShadow: "0 0 0 0",
        },
        "&:active, &$focusVisible": {
          boxShadow: "0 0 0 0",
          backgroundColor: classes.moss600,
        },
        "&$disabled": {
          color: "white",
          backgroundColor: classes.grey500,
        },
        "&.MuiButton-containedSizeSmall": {
          boxShadow: "0 0 0 0",
          backgroundColor: classes.moss600,
          "&:hover": {
            backgroundColor: classes.moss700,
            boxShadow: "0 0 0 0",
          },
          "&:active, &$focusVisible": {
            boxShadow: "0 0 0 0",
            backgroundColor: classes.moss700,
          },
          "&$disabled": {
            color: "white",
            backgroundColor: classes.grey500,
          },
        },
      },
      containedSecondary: {
        backgroundColor: classes.lake500,
        boxShadow: "0 0 0 0",
        "&.theme_roundButton__1-4KI": {
          backgroundColor: classes.lake25,
          color: classes.lake600,
        },
        "&:hover": {
          boxShadow: "0 0 0 0",
          backgroundColor: classes.lake600,
          "&.theme_roundButton__1-4KI": {
            backgroundColor: classes.lake50,
            color: classes.lake600,
          },
        },
        "&:active, &$focusVisible": {
          boxShadow: "0 0 0 0",
          backgroundColor: classes.lake700,
        },
        "&$disabled": {
          color: "white",
          backgroundColor: classes.grey500,
        },
        "&.MuiButton-containedSizeSmall": {
          boxShadow: "0 0 0 0",
          backgroundColor: classes.lake500,
          "&.theme_roundButton__1-4KI": {
            backgroundColor: classes.lake25,
            color: classes.lake600,
          },
          "&:hover": {
            backgroundColor: classes.lake600,
            boxShadow: "0 0 0 0",
            "&.theme_roundButton__1-4KI": {
              backgroundColor: classes.lake50,
              color: classes.lake600,
            },
          },
          "&:active, &$focusVisible": {
            boxShadow: "0 0 0 0",
            backgroundColor: classes.lake700,
          },
          "&$disabled": {
            color: "white",
            backgroundColor: classes.grey500,
          },
        },
      },
      colorInherit: {
        "&.red": {
          color: classes.white,
          boxShadow: "0 0 0 0",
          backgroundColor: classes.red500,
          "&:hover": {
            backgroundColor: classes.red500,
            boxShadow: "0 0 0 0",
          },
          "&:active, &$focusVisible": {
            boxShadow: "0 0 0 0",
            backgroundColor: classes.red600,
          },
          "&$disabled": {
            color: "white",
            backgroundColor: classes.grey500,
          },
          "&.MuiButton-outlined": {
            boxShadow: "0 0 0 0",
            borderColor: classes.red500,
            color: classes.red500,
            backgroundColor: classes.white,
            borderWidth: "2px !important",
            "&:hover": {
              backgroundColor: classes.red500,
              borderColor: classes.red500,
              boxShadow: "0 0 0 0",
              color: classes.white,
            },
            "&:active, &$focusVisible": {
              boxShadow: "0 0 0 0",
              color: classes.white,
              backgroundColor: classes.red600,
              borderColor: classes.red600,
            },
            "&:disabled": {
              color: classes.grey500,
              borderColor: classes.grey500,
            },
          },
        },
        "&.orange": {
          boxShadow: "0 0 0 0",
          color: classes.white,
          backgroundColor: classes.orange700,
          "&:hover": {
            backgroundColor: classes.orange700,
            boxShadow: "0 0 0 0",
          },
          "&:active, &$focusVisible": {
            boxShadow: "0 0 0 0",
            backgroundColor: classes.orange800,
          },
          "&$disabled": {
            color: "white",
            backgroundColor: classes.grey500,
          },
          "&.MuiButton-outlined": {
            boxShadow: "0 0 0 0",
            borderColor: classes.orange700,
            color: classes.orange700,
            backgroundColor: classes.white,
            borderWidth: "2px !important",
            "&:hover": {
              backgroundColor: classes.orange700,
              borderColor: classes.orange700,
              boxShadow: "0 0 0 0",
              color: classes.white,
            },
            "&:active, &$focusVisible": {
              boxShadow: "0 0 0 0",
              color: classes.white,
              backgroundColor: classes.orange800,
              borderColor: classes.orange800,
            },
            "&:disabled": {
              color: classes.grey500,
              borderColor: classes.grey500,
            },
          },
        },
      },
      outlinedPrimary: {
        boxShadow: "0 0 0 0",
        borderColor: classes.moss500,
        color: classes.moss500,
        borderWidth: "2px !important",
        "&:hover": {
          backgroundColor: classes.moss500,
          borderColor: classes.moss500,
          boxShadow: "0 0 0 0",
          color: classes.white,
        },
        "&:active, &$focusVisible": {
          boxShadow: "0 0 0 0",
          color: classes.white,
          backgroundColor: classes.moss600,
          borderColor: classes.moss600,
        },
        "&:disabled": {
          color: classes.grey500,
          borderColor: classes.grey500,
        },
        "&.MuiButton-outlinedSizeSmall": {
          boxShadow: "0 0 0 0",
          borderColor: classes.moss600,
          color: classes.moss600,
          borderWidth: "2px !important",
          "&:hover": {
            backgroundColor: classes.moss600,
            boxShadow: "0 0 0 0",
            color: classes.white,
          },
          "&:active, &$focusVisible": {
            boxShadow: "0 0 0 0",
            backgroundColor: classes.moss700,
          },
          "&:disabled": {
            color: classes.grey500,
            borderColor: classes.grey500,
          },
        },
      },
      outlinedSecondary: {
        boxShadow: "0 0 0 0",
        borderColor: classes.lake500,
        color: classes.lake500,
        borderWidth: "2px !important",
        "&:hover": {
          boxShadow: "0 0 0 0",
          backgroundColor: classes.lake500,
          borderColor: classes.lake500,
          color: "white",
        },
        "&:active, &$focusVisible": {
          boxShadow: "0 0 0 0",
          color: "white",
          backgroundColor: classes.lake600,
          borderColor: classes.lake600,
        },
        "&:disabled": {
          color: classes.grey500,
          borderColor: classes.grey500,
        },
      },
      sizeLarge: {
        padding: "8px 40px",
        "&& $iconSizeLarge": {
          "& svg": {
            fontSize: "32px",
          },
        },
      },
      sizeSmall: {
        padding: "5px 24px",
        "&& $iconSizeSmall": {
          "& svg": {
            fontSize: "24px",
          },
        },
      },
      textPrimary: {
        color: classes.moss500,
        "&:hover": {
          color: classes.moss600,
          backgroundColor: classes.grey100,
        },
        "&:active, &$focusVisible": {
          color: classes.moss700,
          backgroundColor: classes.grey100,
        },
        "&:disabled": {
          color: classes.grey500,
        },
        "&.MuiButton-sizeSmall": {
          color: classes.moss600,
          "&& $iconSizeSmall": {
            color: classes.grey700,
          },
          "&:hover": {
            color: classes.moss600,

            "&& $iconSizeSmall": {
              color: classes.grey700,
            },
          },
          "&:active, &$focusVisible": {
            color: classes.moss700,
            "&& $iconSizeSmall": {
              color: classes.grey700,
            },
          },
          "&:disabled": {
            color: classes.grey500,
            "&& $iconSizeSmall": {
              color: classes.grey500,
            },
          },
        },
      },
      textSecondary: {
        color: classes.lake500,
        "&.MuiButton-sizeLarge": {
          color: classes.lake500,
          "&& $iconSizeLarge": {
            color: classes.grey500,
          },
          "&:hover": {
            color: classes.lake600,
            backgroundColor: classes.grey100,
            "&& $iconSizeLarge": {
              color: classes.lake600,
            },
          },
          "&:active, &$focusVisible": {
            color: classes.lake700,
            backgroundColor: classes.grey100,
            "&& $iconSizeLarge": {
              color: classes.lake700,
            },
          },
        },
        "&.MuiButton-sizeSmall": {
          color: classes.lake500,
          "&& $iconSizeSmall": {
            color: classes.grey700,
          },
          "&:hover": {
            color: classes.lake600,
            backgroundColor: classes.grey100,
            "&& $iconSizeSmall": {
              color: classes.grey700,
            },
          },
          "&:active, &$focusVisible": {
            color: classes.lake700,
            backgroundColor: classes.grey100,
            "&& $iconSizeSmall": {
              color: classes.grey700,
            },
          },
          "&:disabled": {
            "&& $iconSizeSmall": {
              color: classes.grey500,
            },
          },
        },
      },
    },
    MuiIconButton: {
      root: {
        "&.iconButton": {
          borderRadius: "4px",
          padding: "8px",
          height: "48px",
          minWidth: "48px",
          width: "48px",
          "& svg ": { fontSize: "32px" },
        },
      },
      sizeSmall: {
        "&.iconButton": {
          borderRadius: "4px",
          padding: "5px",
          height: "32px",
          width: "32px",
          minWidth: "32px",
          "& svg ": { fontSize: "24px" },
        },
      },
      colorSecondary: {
        "&.iconButton": {
          backgroundColor: classes.lake500,
          color: "white",
          "&.theme_roundButton__1-4KI": {
            backgroundColor: classes.lake25,
            color: classes.lake600,
          },
          "&:hover": {
            boxShadow: "0 0 0 0",
            backgroundColor: classes.lake600,
            "&.theme_roundButton__1-4KI": {
              backgroundColor: classes.lake50,
              color: classes.lake600,
            },
          },
          "&:active": {
            boxShadow: "0 0 0 0",
            backgroundColor: classes.lake700,
          },
          "&$disabled": {
            color: "white",
            backgroundColor: classes.grey500,
          },
          "&.outlinedIconButtonSecondary": {
            backgroundColor: classes.white,
            color: classes.lake500,
            border: `2px solid ${classes.lake500}`,
            "&:hover": {
              backgroundColor: classes.lake500,
              border: `2px solid ${classes.lake500}`,
              color: "white",
            },
            "&:active": {
              backgroundColor: classes.lake600,
              border: `2px solid ${classes.lake600}`,
              color: "white",
            },
          },
          "&.textIconButtonSecondary": {
            backgroundColor: "white",
            color: classes.lake500,
            "&:hover": {
              backgroundColor: classes.grey100,
              color: classes.lake600,
            },
            "&:active": {
              backgroundColor: classes.grey100,
              color: classes.lake700,
            },
          },
        },
      },
      colorPrimary: {
        "&.iconButton": {
          backgroundColor: classes.moss400,
          color: "white",
          "&:hover": {
            backgroundColor: classes.moss500,
            boxShadow: "0 0 0 0",
          },
          "&:active": {
            boxShadow: "0 0 0 0",
            backgroundColor: classes.moss600,
          },
          "&$disabled": {
            color: "white",
            backgroundColor: classes.grey500,
          },
          "&.outlinedIconButtonPrimary": {
            backgroundColor: classes.white,
            color: classes.moss500,
            border: `2px solid ${classes.moss500}`,
            "&:hover": {
              backgroundColor: classes.moss500,
              border: `2px solid ${classes.moss500}`,
              color: "white",
            },
            "&:active": {
              backgroundColor: classes.moss600,
              border: `2px solid ${classes.moss600}`,
              color: "white",
            },
          },
          "&.textIconButtonPrimary": {
            backgroundColor: "white",
            color: classes.moss500,
            "&:hover": {
              backgroundColor: classes.grey100,
              color: classes.moss600,
            },
            "&:active": {
              backgroundColor: classes.grey100,
              color: classes.moss700,
            },
          },
        },
      },
    },
    MuiSvgIcon: {
      // secondary = success = green
      colorSecondary: {
        color: "#007600",
      },
      // action = info = orange
      colorAction: {
        color: "#F4AD22",
      },
      // error = warning = red
      colorError: {
        color: "#C20B0B",
      },
      fontSizeSmall: {
        fontSize: "1rem",
      },
      fontSizeLarge: {
        fontSize: "1.5rem",
      },
    },
    MuiLink: {
      root: {
        fontWeight: "bold",
      },
    },
    MuiAccordion: {
      root: {
        "&$expanded": {
          margin: 0,
          borderRight: "1px solid #00829B",
          borderLeft: "1px solid #00829B",
        },
      },
    },
    MuiAlert: {
      icon: {
        padding: 0,
      },
      message: {
        padding: 0,
      },
      filledSuccess: {
        backgroundColor: "#D9FCDA",
        color: "#949494",
      },
      filledWarning: {
        backgroundColor: "#FFE9BD",
        color: "#949494",
      },
      filledInfo: {
        backgroundColor: "#BFF4FF",
        color: "#949494",
      },
      filledError: {
        backgroundColor: "#FFE4DE",
        color: "#949494",
      },
    },
    MuiAvatar: {
      root: {
        fontSize: "1.0rem",
        width: "30px",
        height: "30px",
      },
    },
    MuiList: {
      root: {
        outline: "none",
      },
    },
    MuiFilledInput: {
      root: {
        paddingTop: " 0 !important",
        backgroundColor: "none",
        "&:hover": { backgroundColor: "none" },
        "&:active": { backgroundColor: "none" },
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "none",
        },
      },
    },
    // Applied to the <li> elements
    MuiMenuItem: {
      root: {
        fontFamily: "Public Sans",
        fontSize: "13px",
        fontWeight: "600",
        lineHeight: "20px",
        textAlign: "left",
        "&:hover": {
          backgroundColor: classes.lake25,
        },
        "&$selected": {
          backgroundColor: classes.lake25,
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
      },
    },
  },
});

export default theme;
