import React, { useState, useEffect } from "react";
import {
  Button as ButtonMui,
  ButtonBase as ButtonBaseMui,
} from "@material-ui/core/";
import Tooltip from "@mui/material/Tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import PropTypes from "prop-types";

import classes from "../../themes/theme.module.css";

// button icon types
export const BIT_COPY = "BIT_COPY";

/*
  return a native mui Botton for tooltip.
  when wrapped with tooltip, need a native mui component to avoid console warning message
 */
export const getNativeButton = (props) => {
  const {
    variant,
    color,
    size,
    disabled,
    className,
    children,
    baseButton,
    sx,
    onClick,
    icon,
    ...remainingProps
  } = props;

  /* istanbul ignore next */
  const labelStyle =
    size === "small"
      ? `${classes.actionTextSmall} ${classes.buttonTextSmall}`
      : `${classes.actionTextLarge} ${classes.buttonTextLarge}`;

  /* istanbul ignore next */
  const buttonProps = {
    classes: baseButton ? { root: labelStyle } : { label: labelStyle },
    className,
    type: "button",
    color,
    variant,
    size,
    ...remainingProps,
  };

  /* istanbul ignore next */
  const getIcon = (itype) => {
    const style = { marginRight: "10px" };
    switch (itype) {
      case BIT_COPY:
        return <ContentCopyIcon sx={style} />;
      default:
        return null;
    }
  };

  let btn = (
    <ButtonMui disabled={disabled} {...buttonProps} onClick={onClick}>
      {getIcon(icon)}
      {children}
    </ButtonMui>
  );

  /* istanbul ignore next */
  if (baseButton) {
    btn = (
      <ButtonBaseMui disabled={disabled} {...buttonProps}>
        {getIcon(icon)}
        {children}
      </ButtonBaseMui>
    );
  }

  /* istanbul ignore next */
  if (sx) {
    btn = (
      <ButtonMui disabled={disabled} {...buttonProps} style={sx}>
        {getIcon(icon)}
        {children}
      </ButtonMui>
    );
  }

  return btn;
};

const Button = (props) => {
  const {
    variant,
    color,
    size,
    disabled,
    className,
    children,
    baseButton,
    sx,
    toolTip,
    toolTipTimer,
    onClick,
    icon,
    ...remainingProps
  } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const labelStyle =
    size === "small"
      ? `${classes.actionTextSmall} ${classes.buttonTextSmall}`
      : `${classes.actionTextLarge} ${classes.buttonTextLarge}`;

  // with toolTip and a trigger, the onClick function must be passed all the way down to here (props.onClick)
  // so the tip can be opened up with the click!
  /* istanbul ignore next */
  const getOnClick = () => {
    if (onClick) {
      return (arg) => {
        if (toolTip && toolTipTimer) {
          setTooltipOpen(true);
        }
        onClick(arg); // what if arg
      };
    }
    return null;
  };

  const buttonProps = {
    classes: baseButton ? { root: labelStyle } : { label: labelStyle },
    className,
    type: "button",
    color,
    variant,
    size,
    ...remainingProps,
    onClick: getOnClick(),
  };

  // so the delayed function can be terminated on unmount
  /* istanbul ignore next */
  useEffect(() => {
    if (tooltipOpen && toolTipTimer) {
      const timerId = setTimeout(() => {
        setTooltipOpen(false);
      }, toolTipTimer);
      return () => {
        if (timerId) clearTimeout(timerId);
      };
    }
  }, [tooltipOpen]);

  /* istanbul ignore next */
  const getIcon = (itype) => {
    const style = { marginRight: "10px" };
    switch (itype) {
      case BIT_COPY:
        return <ContentCopyIcon sx={style} />;
      default:
        return null;
    }
  };

  let btn = (
    <ButtonMui disabled={disabled} {...buttonProps}>
      {getIcon(icon)}
      {children}
    </ButtonMui>
  );

  if (baseButton) {
    btn = (
      <ButtonBaseMui disabled={disabled} {...buttonProps}>
        {getIcon(icon)}
        {children}
      </ButtonBaseMui>
    );
  }
  if (sx) {
    btn = (
      <ButtonMui disabled={disabled} {...buttonProps} style={sx}>
        {getIcon(icon)}
        {children}
      </ButtonMui>
    );
  }

  /* istanbul ignore next */
  if (toolTip) {
    if (toolTipTimer) {
      return (
        <Tooltip
          data-testid="avatar-tool-tip"
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => {
            setTooltipOpen(false);
          }}
          open={tooltipOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={<span style={{ fontSize: 16 }}>{toolTip}</span>}
          placement="top"
        >
          {btn}
        </Tooltip>
      );
    }
    return <Tooltip title={toolTip}>{btn}</Tooltip>;
  }
  return btn;
};

Button.defaultProps = {
  toolTip: null,
  toolTipTimer: 1500,
  onClick: null,
  icon: null,
  // click: null,
  baseButton: false,
  color: "primary",
  variant: "contained",
  size: "large",
  disabled: false,
  className: null,
  children: null,
  sx: null,
};

Button.propTypes = {
  onClick: PropTypes.func,
  toolTip: PropTypes.string,
  toolTipTimer: PropTypes.number,
  icon: PropTypes.string,
  // click: PropTypes.func,
  baseButton: PropTypes.bool,
  color: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  /* "children" here is anything wrapped by the button component
  (e.g., Another component and a string, or a plain string, or multiple components).
  There's no reason to typecheck those combinations. */
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  sx: PropTypes.shape(),
};

export default Button;
