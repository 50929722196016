/* istanbul ignore file */
import React from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Button from "../Button";

const DropdownButton = (props) => {
  // State
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Props
  const {
    dropdown, // can show dropdown menu
    onClick,
    onMenuClose,
    disabled,
    label,
    size,
    variant,
    style,
    items,
  } = props;

  const handleClick = (event) => {
    if (onClick) {
      onClick();
    }
    if (!disabled && dropdown) {
      setAnchorEl(event.currentTarget);
    }
  };

  /* istanbul ignore next */
  const handleDownloadDropdownClose = () => {
    // Close dropdown menu
    setAnchorEl(null);
    if (onMenuClose) onMenuClose();
    return true;
  };

  const handleItemClick = (fn) => {
    setAnchorEl(null);
    fn();
  };

  const btnStyle = {
    height: 48,
    ...style,
  };

  const itemStyle = {
    // width: btnStyle.width - 10,
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant={variant}
        color="primary"
        size={size}
        className="button"
        endIcon={<KeyboardArrowDownIcon style={{ fontSize: 28 }} />}
        style={btnStyle}
        disabled={disabled}
        id="download-btn-id"
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleDownloadDropdownClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {items.map((d) => {
          return (
            <MenuItem
              onClick={() => {
                handleItemClick(d.click);
              }}
              key={d.key}
              id={d.id}
              disabled={!!d.disabled}
              style={itemStyle}
            >
              {d.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default DropdownButton;

DropdownButton.defaultProps = {
  dropdown: true,
  onClick: null,
  onMenuClose: null,
  disabled: false,
  label: "Dropdown",
  size: "medium",
  variant: "contained",
  style: {},
};

DropdownButton.propTypes = {
  dropdown: PropTypes.bool,
  onClick: PropTypes.func,
  onMenuClose: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  style: PropTypes.shape(),
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
