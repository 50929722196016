import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const bgcolor = "#FFF";
const borderColor = "#000";
const txtcolor = "#000";
const txtsize = 14;
const bottomOffset = -10; // closer to the top anchor

const tipStyle = {
  backgroundColor: bgcolor, // rec background color
  border: `1px solid ${borderColor}`,
  color: txtcolor, // text color
  fontSize: txtsize, // text size
};

/**
 * in src/components/UI/Tooltip/Tooltip.jsx, when CustomWidthTooltip is defined
 * within the JDPTooltip (to receive the user customerization with the above consts),
 * and when the anchor element receives mouse click, the first click doesn't trigger
 * onClick handler on the anchor element !
 */
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    interactive={0} // to dismiss console warning of bool is assiend to non-bool param
    classes={{ popper: className }}
    // placement="bottom-start"
    slotProps={{
      // distance between tooltip and the anchor
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, bottomOffset],
            },
          },
        ],
      },
    }}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    ...tipStyle,
    width: "auto",
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      border: `1px solid ${borderColor}`,
      backgroundColor: bgcolor,
    },
  },
});

const BasicTooltip = ({ children, ...restProps }) => {
  return (
    <CustomWidthTooltip {...restProps} arrow>
      {children}
    </CustomWidthTooltip>
  );
};
export default BasicTooltip;

BasicTooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
};
