import React from "react";
import PropTypes from "prop-types";
// import Typography from "@mui/material/Typography";
import WarningIcon from "@material-ui/icons/Warning";

import ModalContentBase from "../ModalContentBase";
import Dialog, {
  TT_ORANGE600,
} from "../../Dialogs/DraggableDialog/DraggableDialog";
import { DF_BTN_WARNING } from "../../Dialogs/DialogFooter";

import classes from "../InfoBoard.module.css";
import filestatsclasses from "../../../Downloader/FileStats/FileStats.module.css";
// import JDPLifeHelpIcon from "../Icons/JDPLifeHelpIcon";

const ConfirmChange = ({
  children,
  title,
  acceptTxt,
  acceptBtnFn,
  cancelTxt,
  cancelCrossFn, // the cross icon click handler
  cancelBtnFn,
  doNotShowAgainCheckFn,
}) => {
  // console.log(atype, "[ConfirmChange]");

  return (
    <Dialog
      topType={TT_ORANGE600}
      cancelBtnFn={cancelBtnFn}
      cancelBtnText={cancelTxt}
      acceptBtnFn={acceptBtnFn}
      acceptBtnText={acceptTxt}
      btnColor={DF_BTN_WARNING}
      checkBtnText={doNotShowAgainCheckFn ? "Do not show this again." : null}
      checkBtnFn={doNotShowAgainCheckFn}
    >
      <ModalContentBase titleText={title} closeFn={cancelCrossFn}>
        <table
          style={{
            backgroundColor: classes.orange50,
            padding: "10px",
          }}
        >
          <tbody>
            <tr>
              <td style={{ verticalAlign: "top" }}>
                <WarningIcon
                  style={{ color: classes.orange600 }}
                  className={filestatsclasses.icon}
                />
              </td>
              <td style={{ paddingLeft: 10, fontSize: 20 }}>{children}</td>
            </tr>
          </tbody>
        </table>
      </ModalContentBase>
    </Dialog>
  );
};

export default ConfirmChange;

ConfirmChange.defaultProps = {
  title: "Confirm Dialog",
  cancelTxt: null,
  acceptTxt: null,
  acceptBtnFn: null,
  cancelBtnFn: null,
  cancelCrossFn: null,
  doNotShowAgainCheckFn: null,
};

ConfirmChange.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  acceptTxt: PropTypes.string,
  acceptBtnFn: PropTypes.func,
  cancelTxt: PropTypes.string,
  cancelBtnFn: PropTypes.func,
  cancelCrossFn: PropTypes.func,
  doNotShowAgainCheckFn: PropTypes.func,
};
