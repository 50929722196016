// TODO: This crap can go away when we are not supporting existing components
import { globusDownloadSize, browserDownloadWarningSize } from "./LDFFUtils";

export const KINGDOM_PHYTOZOME = "phytozome";
export const KINGDOM_MYCOCOSM = "mycocosm";
export const KINGDOM_IMG = "img";
export const KINGDOM_PHYCOCOSM = "phycocosm";
export const FUNGALALGAE = ["mycocosm", "phycocosm"];

const BYTES_IN_KB = 1024; // 1KB constant: 2^10
const BYTES_IN_MB = BYTES_IN_KB * BYTES_IN_KB; // 1 MB constant: 2^20
const BYTES_IN_GB = BYTES_IN_MB * BYTES_IN_KB; // 1G constant 2^30
const BYTES_IN_TB = BYTES_IN_GB * BYTES_IN_KB; // 1T constant 2^40
const VIA_BROWSER_MAX = 3 * BYTES_IN_GB; // 3G limit for browser download

/**
 * Sum the file_size fields of all files
 * @param {array} files array to sum file sizes from
 */
const filesSizeInBytes = (files) => {
  if (!files) return;
  return files.map((d) => d.file_size).reduce((t, s) => t + s, 0);
};

const dateTransform = (date) => {
  let seconds;
  try {
    seconds = Date.parse(date);
  } catch {
    return "N/A";
  }

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(seconds)) return "N/A";
  const formatter = new Intl.DateTimeFormat("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  const parsed = formatter.formatToParts(new Date(seconds));
  const day = parsed.find((item) => item.type === "day").value;
  const month = parsed
    .find((item) => item.type === "month")
    .value.toUpperCase();
  const year = parsed.find((item) => item.type === "year").value;
  return `${day} ${month} ${year}`;
};

/**
 * Count the number of files with a status of purged/restore-in-progress
 * @param {array} files the files to look through and count the number of purged
 */
const purgedFileCount = (files) => {
  let count = 0;
  files.forEach((d) => {
    if (["PURGED", "RESTORE_IN_PROGRESS"].indexOf(d.file_status) > -1) {
      count += 1;
    }
  });
  return count;
};

/**
 * Number of unique organisms the collection of files belong to.
 * @param {array} files
 */
const selectedOrganismCount = (files) => {
  const organisms = new Set();
  files.forEach((f) => {
    organisms.add(f.organism_id);
  });
  return organisms.size;
};

const fileStatusTransform = (status) => {
  const STATUS_NOW = "Now";
  const STATUS_ARCHIVED = "Archived";
  const STATUS_PENDING = "Pending";

  // default to archived status if unexpected value - JDP-1098
  if (!status || typeof status !== "string") {
    return STATUS_ARCHIVED;
  }
  if (status.toUpperCase() === "PURGED") {
    return STATUS_ARCHIVED;
  }
  if (
    status.toUpperCase() === "BACKUP_COMPLETE" ||
    status.toUpperCase() === "RESTORED"
  ) {
    return STATUS_NOW;
  }
  if (status.toUpperCase() === "RESTORE_IN_PROGRESS") {
    return STATUS_PENDING;
  }
  // default to archived status if it doesn't map to one of the existing options - JDP-1098
  return STATUS_ARCHIVED;
};

const selectionSummary = (files) => {
  const info = {
    files,
    purgedIds: [],
    purgedSizeInBytes: 0, // total purged file size in Byte
    purgedCount: 0, // purged file count
    ondiskIds: [],
    ondiskSizeInBytes: 0, // total on-disk file size in byte
    ondiskCount: 0, // on-disk file size
    totalSizeInBytes: 0, // size in Byte of all files
    totalCount: 0, // count of all files
  };

  if (files && files.length > 0) {
    files.forEach((d) => {
      const fstatus = fileStatusTransform(d.file_status);
      if (fstatus === "Now") {
        info.ondiskCount += 1;
        info.ondiskSizeInBytes += d.file_size;
        info.ondiskIds.push(d._id);
      } else {
        info.purgedCount += 1;
        info.purgedSizeInBytes += d.file_size;
        info.purgedIds.push(d._id);
      }
    });

    info.totalSizeInBytes = info.purgedSizeInBytes + info.ondiskSizeInBytes;
    info.totalCount = info.purgedCount + info.ondiskCount;
    info.organismCount = selectedOrganismCount(files);
  }
  return info;
};
/**
 * Create a unique file name for the download, based on date time
 */
const createDownloadFilename = () => {
  const formatter = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "numeric",
    hour12: false,
  });
  const [
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
    ,
    { value: hour },
    ,
    { value: minute },
    ,
    { value: second },
  ] = formatter.formatToParts(new Date());
  return `download.${year}${month}${day}.${hour}${minute}${second}.zip`;
};

// return the download size limit in Bytes for warning, eigher from FF or default
const getBrowserDownloadWarningSizeInByte = (ldClient) => {
  let size = browserDownloadWarningSize(ldClient);
  size = size === null ? VIA_BROWSER_MAX : size * BYTES_IN_GB;
  return size;
};

// return the size in Bytes for suggesting globus download, eigher from FF or 2 * VIA_BROWSER_MAX
const getGlobusDownloadSizeInByte = (ldClient) => {
  let size = globusDownloadSize(ldClient);
  size = size === null ? VIA_BROWSER_MAX * 2 : size * BYTES_IN_GB;
  return size;
};

const partitionByRelevancy = (files, scoreMin) => {
  // files : array of file records
  const over = [];
  const under = [];
  files?.forEach((file) => {
    if (file.score >= scoreMin) {
      over.push(file);
    } else {
      under.push(file);
    }
  });
  return { over, under };
  // return files.filter((file) => file.score < scoreMin).length > 0;
};

const gid2fidsMap = (files) => {
  // files : array of file object, which must carry organism level id as organism_id
  // return a map of oid : [fids]
  const map = {};
  files?.forEach((file) => {
    const { organism_id } = file;
    if (organism_id) {
      let fids = map[organism_id];
      if (!fids) {
        fids = [];
        map[organism_id] = fids;
      }
      fids.push(file._id);
    }
  });
  return map;
  // return files.filter((file) => file.score < scoreMin).length > 0;
};

const selectedForRow = (files, rowId) => {
  // files : array of file object, which must carry organism level id as organism_id
  // return list of files or file ids, of whick f.organism_id == rowId, OR []
  const currentSelectionMap = files?.length > 0 ? gid2fidsMap(files) : {};
  return currentSelectionMap[rowId] ? currentSelectionMap[rowId] : [];
};

const extension = (filename) => filename.split(".").pop();

const webReadable = (filename, fileExtensionList = null) => {
  // if the file content can be viewed with browser
  if (filename.toLowerCase() === "readme") return true;

  const READABLE = fileExtensionList || [
    "txt",
    "pdf",
    "html",
    "json",
    "xml",
    "yaml",
    "log",
  ];
  const ext = extension(filename);
  return ext !== filename && READABLE.includes(ext.toLowerCase());
};

export {
  dateTransform,
  filesSizeInBytes,
  selectionSummary,
  selectedOrganismCount,
  purgedFileCount,
  createDownloadFilename,
  fileStatusTransform,
  VIA_BROWSER_MAX,
  BYTES_IN_TB,
  BYTES_IN_GB,
  getBrowserDownloadWarningSizeInByte,
  getGlobusDownloadSizeInByte,
  partitionByRelevancy,
  gid2fidsMap,
  selectedForRow,
  webReadable,
};
