import React from "react";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import Tooltip from "../Tooltip/Tooltip";

import classes from "./DUSChip.module.css";

/*
  <DUSChip label="RST" outline size="medium" /> // medium outline
  <DUSChip label="RST" size="medium" />         // medium
  <DUSChip label="RST" />                       // small
  <DUSChip label="UNRST" />                     // small
*/
const DUSChip = ({
  label,
  outline,
  size,
  mleft,
  mright,
  mbottom,
  tooltip,
  interactive,
}) => {
  // const classes = useStyles();
  const css = {
    marginLeft: mleft,
    marginRight: mright,
    marginBottom: mbottom,
    backgroundColor: classes.orange50, // "#FFE9BD",
    border: `1px solid ${classes.orange500}`, // #FF9E1B`,
    color: classes.orange800, // "#AD4800",
    height: "22px", // small height
    // borderRadius: "8px",
    "&:hover": {
      border: `1px solid ${classes.orange600}`,
    },
  };

  if (outline) {
    css.backgroundColor = "#FFF";
  }

  if (label.toLowerCase() === "unrst") {
    css.backgroundColor = classes.moss25; // "#ECFDED";
    css.border = `1px solid ${classes.moss500}`; // #008A05";
    css.color = classes.moss600; // "#007600";
    css["&:hover"] = {
      border: `1px solid ${classes.moss600}`,
      color: classes.moss800,
    };
  }

  if (size === "medium") {
    css.height = "28px";
  }

  let tipTitle = null;
  if (!tooltip) {
    let status = label.toLowerCase();
    if (outline) {
      status = "partially restricted";
    } else if (status === "rst") {
      status = "restricted";
    } else if (status === "unrst") {
      status = "unrestricted";
    }
    tipTitle = `Data policy: ${status}`;
  } else if (tooltip !== "none") {
    tipTitle = tooltip;
  }

  const chip = <Chip label={label} size={size} sx={css} />;
  return tipTitle ? (
    <Tooltip title={tipTitle} interactive={interactive}>
      {chip}
    </Tooltip>
  ) : (
    chip
  );
};

export default DUSChip;

DUSChip.defaultProps = {
  outline: false,
  size: "small",
  mleft: "10px",
  mbottom: "6px",
  mright: "0px,",
  tooltip: null,
  interactive: false, // interactive tooltip
};

DUSChip.propTypes = {
  label: PropTypes.string.isRequired,
  outline: PropTypes.bool,
  size: PropTypes.string,
  mleft: PropTypes.string,
  mbottom: PropTypes.string,
  mright: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  interactive: PropTypes.bool,
};
