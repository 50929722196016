import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { TableCell, SvgIcon, Typography, Box } from "@material-ui/core/";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import MoreVertIcon from "@material-ui/icons/MoreVert";

import filesize from "filesize";

import Button from "../../Button/Button";
import GenomeKebab, {
  DUS_STATUS_ALL,
  DUS_STATUS_PARTIAL,
  DUS_META_VALUE_RST,
  DUS_META_VALUE_PARTIAL,
} from "../../KebabMenu/GenomeKebab";

import { ReactComponent as blueDownArrow } from "../../../assets/images/arrowdown.svg";
import { ReactComponent as blueUpArrow } from "../../../assets/images/arrowup.svg";
import { ReactComponent as grayDownArrow } from "../../../assets/images/arrowgray.svg";
import addToCartImage from "../../../assets/images/32x32_AddShoppingCartRounded.svg";
import removeFromCartImage from "../../../assets/images/32x32_RemoveShoppingCartRounded.svg";

import DUSChip from "../../UI/DUSChip/DUSChip";

import classes from "./GenomeTableCells.module.css";

import { ROW_SELECTED_NONE } from "../Constants";

import * as cartTool from "../../../utils/CartDataTools";

// import { base as ldbase } from "../../../utils/LDFFUtils";
import Tooltip from "../../UI/Tooltip/Tooltip";
import TransientTooltip from "../../UI/Tooltip/TransientTooltip/TransientTooltip";
import ShoppingCart from "../../UI/ShoppingCart/ShoppingCart";

import FileAndPageContext from "../../../context/FileAndPageContext";
import { useSearchParams } from "../../../hooks/useSearchParams";

const MYCOCOSM_URL_ROOT = "https://mycocosm.jgi.doe.gov";

const GenomeTableCells = (props) => {
  const {
    row,
    onRowClick,
    expanded,
    isHovering,
    honeycomb,
    selectState,
    cartData,
    restoreid,
    // ldClient,
    addFilesToCart,
    selectedFiles,
    setSelectedFiles, /// SearchPageContainer.setSelectedFiles
  } = props;

  const { cart: cartpage } = useSearchParams();

  const [anchorEl, setAnchorEl] = useState(null);
  const [mycocosmPortalId, setMycocosmPortalId] = useState(null);
  const [cartUpdated, setCartUpdated] = useState(false);

  /* istanbul ignore next */
  const doCartUpdated = () => {
    setCartUpdated(true);
    setTimeout(() => {
      setCartUpdated(false);
    }, 1500);
  };

  /* istanbul ignore next */
  const handleKebabClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    honeycomb.sendUiInteractionSpan("clicked-genome-kebab-open", {
      genome_id: row.id,
    });
  };

  const upDownArrow = (hovering, isExpanded) => {
    if (!hovering && !isExpanded) {
      return grayDownArrow;
    }
    if (hovering && !isExpanded) {
      return blueDownArrow;
    }
    if (isExpanded) {
      return blueUpArrow;
    }
  };

  const arrowLabel = (hovering, isExpanded, currentRow) => {
    if (!hovering && !isExpanded) {
      return `Expandable ${currentRow.name} File Table`;
    }
    if (hovering && !isExpanded) {
      return `Expand ${currentRow.name} File Table`;
    }
    if (isExpanded) {
      return `Collapse ${currentRow.name} File Table`;
    }
  };

  const mycocosmURL = (portalId) => `${MYCOCOSM_URL_ROOT}/${portalId}`;

  /* istanbul ignore next */
  const click = {
    className: classes.clickable,
    onClick: (event) => {
      event.stopPropagation();
      if (mycocosmPortalId) {
        const win = window.open(mycocosmURL(mycocosmPortalId), "_blank");
        honeycomb.sendUiInteractionSpan("mycocosm-new-version", {
          portal_id: mycocosmPortalId,
        });
        setMycocosmPortalId(null);
        if (win) win.focus();
      } else {
        onRowClick(event, row.id);
      }
    },
  };

  const { deleteOrgFromCart } = useContext(FileAndPageContext);

  /* istanbul ignore next */
  const handleDeleteFromCart = () => {
    honeycomb.sendUiInteractionSpan("cart-data-change", {
      action: "genome row removal",
      data: { organism_id: row.id },
    });
    deleteOrgFromCart(row);
  };

  const thPros = {
    component: "th",
    scope: "row",
  };

  const thCenterProps = {
    ...thPros,
    align: "center",
  };

  const nameStyle = { padding: 0 };
  const [removeBtnHover, setRemoveBtnHover] = useState(false);

  const inCartFileCount = cartTool.inCartFileCount(cartData, row.id); // toplevel "organisms" : map of org_id to in_cart_file_count
  const inCartIcon =
    inCartFileCount > 0 && !cartpage ? (
      <ShoppingCart
        count={inCartFileCount}
        tip="File(s) in the Cart"
        badgeBackgroundColor={classes.lake700}
      />
    ) : null;

  // JDP-2166 the genome row: shopping cart icon to the right end - do not show if restoreid exists
  const allInCart = cartTool.allFilesInCart(cartData, row);

  /* istanbul ignore next */
  const cartAddOrRemoveIcon = restoreid ? null : (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <img
      className={
        allInCart ? classes.addToCartIcon32 : classes.removeFromCartIcon32
      }
      src={allInCart ? removeFromCartImage : addToCartImage}
      alt="cart"
      onClick={(event) => {
        event.stopPropagation();
        const toBeAddedFiles = [];

        if (allInCart) {
          handleDeleteFromCart();
          doCartUpdated();
        } else {
          row.files.forEach((f) => {
            if (!cartTool.fileInCart(cartData, f.organism_id, f._id)) {
              f.organism_id = row.id;
              toBeAddedFiles.push(f);
            }
          });
          if (toBeAddedFiles.length > 0) {
            // update selection to remove ALL selected files from this data set
            if (selectedFiles) {
              const datasetIds = toBeAddedFiles.map((f) => f._id);
              const newSelectedFiles = selectedFiles.filter(
                (f) => !(f.organism_id === row.id && datasetIds.includes(f._id))
              );
              setSelectedFiles(
                newSelectedFiles.length > 0 ? newSelectedFiles : null
              );
            }

            addFilesToCart(toBeAddedFiles);
            doCartUpdated();
          }
        }

        honeycomb.sendUiInteractionSpan("genome-add-to-cart-click", {
          action: allInCart ? "delete" : "add",
          count: allInCart ? row.files.length : toBeAddedFiles.length,
        });
      }}
    />
  );

  /* istanbul ignore next */
  const cartAddOrRemoveIconWithTip = cartAddOrRemoveIcon ? (
    <TransientTooltip
      noticeLabel={
        cartUpdated
          ? !allInCart
            ? "Dataset removed from cart"
            : "Dataset added to cart"
          : null
      }
      labelConf={
        cartUpdated
          ? null
          : [
              {
                type: "title",
                value: allInCart
                  ? "Remove all dataset files from the cart"
                  : "Add all dataset files to the cart",
              },
            ]
      }
      child={cartAddOrRemoveIcon}
      open={cartUpdated}
      bottomOffset={0}
      // iconType={ICON_WARN}
    />
  ) : null;

  // JDP-2166 the genome row: show the normal Remove button in cart page, else the new Add/Remove cart icon
  /* istanbul ignore next */
  const btnOrCart = cartpage ? (
    <Button
      type="button"
      className="gRowSelectButton"
      size="small"
      variant="outlined"
      color="secondary"
      startIcon={
        <DeleteForeverIcon
          style={{
            margin: 0,
            color: removeBtnHover ? "#FFFFFF" : classes.lake500,
          }}
        />
      }
      onClick={(event) => {
        event.stopPropagation();
        handleDeleteFromCart();
      }}
      onMouseOver={() => {
        setRemoveBtnHover(true);
      }}
      onMouseLeave={() => {
        setRemoveBtnHover(false);
      }}
    >
      Remove
    </Button>
  ) : (
    cartAddOrRemoveIconWithTip
  );

  const kebabTriggerIcon = (
    <TableCell
      {...thPros}
      {...click}
      component="td"
      style={{ borderRight: `solid 1px #949494`, width: 40 }} // safari: row border get lost
    >
      <div style={{ height: 48, width: 24, paddingRight: 40 }}>
        <MoreVertIcon
          className={classes.kebab}
          onClick={handleKebabClick}
          data-testid="kebab-menu"
        />
        <GenomeKebab
          {...props}
          row={row}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      </div>
    </TableCell>
  );

  let duschip = null;

  /* istanbul ignore next */
  if (row) {
    if (row.data_utilization_status === DUS_META_VALUE_PARTIAL) {
      duschip = (
        <DUSChip
          data-testid="genome-row-duschip"
          label="RST"
          outline
          size="medium"
        />
      );
      row.dus_status = DUS_STATUS_PARTIAL;
    } else if (row.data_utilization_status === DUS_META_VALUE_RST) {
      duschip = (
        <DUSChip data-testid="genome-row-duschip" label="RST" size="medium" />
      );
      row.dus_status = DUS_STATUS_ALL;
    }
  }

  /* istanbul ignore next */
  const superseded = row.superseded_by ? (
    <>
      superseded by{" "}
      <span
        style={{
          textDecoration: "underline",
          color: mycocosmPortalId ? "orange" : "inherit",
        }}
        title={mycocosmURL(row.superseded_by)}
        onMouseEnter={() => setMycocosmPortalId(row.superseded_by)}
        onMouseLeave={() => setMycocosmPortalId(null)}
      >
        {row.successor_name}
      </span>
    </>
  ) : null;

  /* istanbul ignore next */
  const supersededChip =
    row.superseded_by && selectState !== ROW_SELECTED_NONE ? (
      <DUSChip
        label="OBSOLETE"
        size="medium"
        mleft="4px"
        mbottom="4px"
        mright="5px"
        tooltip="none"
      />
    ) : null;

  // genome category label
  const gCatLabel = `${row.label ? row.label : "Genome"}${
    row.product_search_category ? ` - ${row.product_search_category}` : ""
  }`;

  /* istanbul ignore next */
  const gNameLabel = row.superseded_by ? (
    <Tooltip title="A newer version of this genome exists.">
      <span>{row.name}</span>
    </Tooltip>
  ) : (
    row.name
  );

  // if (
  //   [
  //     // "Escherichia coli BW25113 JBEI-FM003",
  //     // "Escherichia coli BW25113 JBEI-FM001",
  //     "Hot spring itags",
  //   ].includes(row.name)
  // ) {
  //   console.log("[GT] row=", row, inCartFileCount);
  // }

  // if (row.id === "IMG_PMO-400887") {
  //   console.log("[GTC]", row.files, props);
  // }

  const selectedFileCountInDataset = selectedFiles
    ? selectedFiles.reduce(
        (sum, d) => (d.organism_id === row.id ? sum + 1 : sum),
        0
      )
    : 0;

  return (
    <>
      <TableCell
        {...thCenterProps}
        style={{ height: 42, width: 40 }}
        {...click}
        component="td"
      >
        <SvgIcon
          viewBox="0 0 48 48"
          component={upDownArrow(isHovering, expanded)}
          alt={arrowLabel(isHovering, expanded, row)}
          aria-label={arrowLabel(isHovering, expanded, row)}
          data-testid={`${expanded ? "expanded" : "collapsed"}-genome-row-icon`}
          style={{
            verticalAlign: "middle",
            width: 48,
            height: 48,
          }}
        />
      </TableCell>
      <TableCell {...thPros} {...click} style={nameStyle} component="td">
        <table>
          <tbody>
            <tr>
              {inCartIcon && (
                <td style={{ paddingRight: 2, paddingLeft: 2, paddingTop: 10 }}>
                  {inCartIcon}
                </td>
              )}

              <td>
                <Typography variant="subtitle1">
                  {gCatLabel} {superseded}
                </Typography>

                <Typography
                  variant="h3"
                  className={classes.h3Color}
                  data-testid="genome-row-name"
                >
                  {gNameLabel}
                  {duschip}
                  {supersededChip}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </TableCell>
      <TableCell {...thPros} {...click} component="td" style={{ width: 400 }}>
        <Typography variant="subtitle1">Number of files</Typography>
        <Typography
          variant="h3"
          className={classes.h3Color}
          // style={{ fontSize: 22 }}
        >
          {`${
            selectedFileCountInDataset
              ? `${selectedFileCountInDataset} selected ${
                  inCartFileCount === 0 ? "out of " : ""
                }`
              : ""
          }${selectedFileCountInDataset && inCartFileCount > 0 ? " / " : ""}${
            inCartFileCount > 0 ? `${inCartFileCount} in Cart out of ` : ""
          }${row.files.length}`}
        </Typography>
      </TableCell>
      <TableCell {...thPros} {...click} component="td" style={{ width: 130 }}>
        <Typography variant="subtitle1">Total file size</Typography>
        <Typography variant="h3" className={classes.h3Color}>
          {filesize(row.fileSize, { round: 1 })}
        </Typography>
      </TableCell>

      <TableCell align="right" {...click} style={{ width: 100 }}>
        <Box className="genomeRowButtons">{btnOrCart}</Box>
      </TableCell>
      {kebabTriggerIcon}
    </>
  );
};

export default GenomeTableCells;

GenomeTableCells.defaultProps = {
  ldClient: null,
  row: {},
  onRowClick: () => {},
  expanded: false,
  isHovering: false,
  honeycomb: {
    sendUiInteractionSpan: () => {},
  },
  selectState: ROW_SELECTED_NONE,
  cartData: null,
  restoreid: null,
  addFilesToCart: null,
  selectedFiles: null,
};

GenomeTableCells.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    label: PropTypes.string,
    fileSize: PropTypes.number,
    files: PropTypes.arrayOf(PropTypes.shape()),
    kingdom: PropTypes.string,
    dus_status: PropTypes.string,
    data_utilization_status: PropTypes.string,
    superseded_by: PropTypes.string,
    successor_name: PropTypes.string,
    product_search_category: PropTypes.string,
  }),
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
  }),
  ldClient: PropTypes.objectOf(Object),
  onRowClick: PropTypes.func,
  expanded: PropTypes.bool,
  isHovering: PropTypes.bool,
  cartData: PropTypes.shape(),
  selectState: PropTypes.number,
  restoreid: PropTypes.string,
  addFilesToCart: PropTypes.func,
  selectedFiles: PropTypes.arrayOf(PropTypes.shape()),
  setSelectedFiles: PropTypes.func.isRequired,
};
