import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

import NoDraggableDialog, {
  DLG_ERROR,
} from "../../../UI/InfoBoard/Standalone/NoDraggableDialog";
import { infoText } from "./styles";
import { DF_BTN_ERROR } from "../../../UI/Dialogs/DialogFooter";

const GrantAccessFailureDlg = ({ cancelBtnFn }) => {
  // console.log("[GrantAccessFailureDlg]", data);
  return (
    <NoDraggableDialog
      type={DLG_ERROR}
      title="Unexpected issue!"
      width={800}
      cancelCrossFn={cancelBtnFn}
      acceptBtnText="Cancel"
      acceptBtnFn={cancelBtnFn}
      btnColor={DF_BTN_ERROR}
      subcontent={
        <Typography paragraph sx={infoText}>
          <span style={{ display: "block" }}>
            We&apos;ve encountered an unexpected issue. The Data Protal Team has
            been notified.
          </span>

          <span style={{ display: "block", paddingTop: 20 }}>
            Please re-submit your request at a later time.
          </span>
        </Typography>
      }
    />
  );
};

export default GrantAccessFailureDlg;

GrantAccessFailureDlg.propTypes = {
  // data: PropTypes.shape().isRequired,
  cancelBtnFn: PropTypes.func.isRequired,
};
