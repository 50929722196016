import React from "react";

import PropTypes from "prop-types";

import { useParams } from "react-router-dom";
import { Grid, Link, Toolbar, Typography } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";

import ContactUs from "./ContactUs/ContactUs";

import doeLogo from "../../assets/images/doeoos.svg";
import lbllogo from "../../assets/images/berkeleylab.svg";
import bioslogo from "../../assets/images/biosciences.svg";

import Release from "../../release";

import classes from "./Footer.module.css";

function Footer(props) {
  const { honeycomb, footerRef } = props;
  const { segment } = useParams();

  // Accessibility
  const accessibilityHref =
    "https://jgi.doe.gov/accessibility-section-508-statement/";
  const accessibilityLink = (
    <Link
      href={accessibilityHref}
      data-testid="accessibilityLink"
      onClick={() => {
        honeycomb.sendUiInteractionSpan("clicked-external-link: accessibility");
      }}
    >
      <Typography className={classes.linkFont}>
        Accessibility / Section 508
      </Typography>
    </Link>
  );

  // Disclaimer
  const disclaimerHref = "https://jgi.doe.gov/disclaimer/";
  const disclaimerLink = (
    <Link
      href={disclaimerHref}
      data-testid="disclaimerLink"
      onClick={() => {
        honeycomb.sendUiInteractionSpan("clicked-external-link: disclaimer");
      }}
    >
      <Typography className={classes.linkFont}>Disclaimer</Typography>
    </Link>
  );

  // Contact Us (Jira Dialog)
  const contactUs = <ContactUs {...props} />;

  // Twitter
  const twitterHref = "https://twitter.com/jgi/";
  const twitterLink = (
    <Link
      href={twitterHref}
      data-testid="twitterLink"
      onClick={() => {
        honeycomb.sendUiInteractionSpan("clicked-external-link: twitter");
      }}
      aria-label="JGI Twitter"
    >
      <TwitterIcon className={classes.twitterIcon} />
    </Link>
  );

  // Facebook
  const facebookHref = "https://www.facebook.com/JointGenomeInstitute/";
  const facebookLink = (
    <Link
      href={facebookHref}
      data-testid="facebookLink"
      onClick={() => {
        honeycomb.sendUiInteractionSpan("clicked-external-link: facebook");
      }}
      aria-label="JGI Facebook"
    >
      <FacebookIcon className={classes.facebookIcon} />
    </Link>
  );

  // Links Row Container
  const links = (
    <Grid container spacing={0} justify="flex-start">
      <Grid item xs={12} sm={10}>
        <ul className={classes.ul}>
          <li className={classes.li}>{accessibilityLink}</li>
          <li className={classes.li}>{disclaimerLink}</li>
          <li className={classes.li}>
            {contactUs}
            {twitterLink}
            {facebookLink}
          </li>
        </ul>
      </Grid>
    </Grid>
  );

  // Copyright
  const copyrightText = `©1997-${new Date().getFullYear()} The Regents of the University of California.`;
  const copyright = (
    <Grid item xs={12} sm={10}>
      <div className={classes.li}>
        <Typography
          className={classes.copyrightText}
          data-testid="copyrightText"
        >
          {copyrightText}
        </Typography>
      </div>
    </Grid>
  );

  // Version
  const versionText = `Version: ${Release.version} (${Release.commit})`;
  const version = (
    <Grid item xs={12} sm={10}>
      <div className={classes.li}>
        <Typography className={classes.versionText} data-testid="versionText">
          {versionText}
        </Typography>
      </div>
    </Grid>
  );

  // BioSciences/LBL, LBL and DOE Logo/Link
  const doeLogoLink = (
    <div className={classes.threeLogo} style={{ display: "inline-flex" }}>
      <Link
        href="https://biosciences.lbl.gov"
        className={classes.doeLogo}
        data-testid="bioSciencesLogoLink"
        onClick={() => {
          honeycomb.sendUiInteractionSpan(
            "clicked-external-link: biosciences-logo"
          );
        }}
      >
        <img src={bioslogo} alt="BioSciences" title="BioSciences, LBL" />
      </Link>
      <Link
        href="https://www.lbl.gov"
        className={classes.doeLogo}
        data-testid="lblLogoLink"
        onClick={() => {
          honeycomb.sendUiInteractionSpan("clicked-external-link: lbl-logo");
        }}
      >
        <img src={lbllogo} alt="LBL" title="LBL" />
      </Link>
      <Link
        href="https://www.energy.gov/science/office-science"
        data-testid="doeLogoLink"
        onClick={() => {
          honeycomb.sendUiInteractionSpan("clicked-external-link: doeoos-logo");
        }}
      >
        <img src={doeLogo} alt="DOE" title="DOE Office of Science" />
      </Link>
    </div>
  );

  return (
    <Toolbar
      ref={footerRef}
      className={segment === "mydata" ? classes.toolBar : classes.toolBarFix}
    >
      <Grid
        container
        spacing={0}
        justify="flex-start"
        className={classes.gridContainer}
      >
        <Grid item xs={12} sm={10}>
          {links}
          {copyright}
          {version}
        </Grid>
        <Grid item xs={6} sm={2}>
          {doeLogoLink}
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default Footer;

Footer.defaultProps = {
  honeycomb: { sendUiInteractionSpan: () => {} },
  footerRef: null,
};

Footer.propTypes = {
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
  }),
  footerRef: PropTypes.shape(),
};
