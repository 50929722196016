import React from "react";

import PropTypes from "prop-types";
import { Typography, Grid } from "@material-ui/core";
import Box from "@mui/material/Box";

import CrossClose from "./CrossClose";

import logo from "../../../assets/images/jgi_logo.jpg";
import classes from "./InfoBoard.module.css";

const ModalContentBase = ({
  closeFn,
  titleText,
  subTitleText,
  showlogo,
  children,
  subcontent, // appear below the main body
}) => {
  // console.error(closeFn, "[ModalContentBase]");
  return (
    <>
      <Grid container spacing={0} wrap="nowrap" style={{ width: "100%" }}>
        {showlogo && (
          <Grid item>
            <img src={logo} className={classes.smlogo} alt="JGI logo" />
          </Grid>
        )}

        <Grid item sm={12}>
          <Box sx={{ marginBottom: "10px" }}>
            <Typography className={classes.header}>
              <span className={`${classes.h5Desktop} ${classes.light}`}>
                {titleText}
              </span>
              {subTitleText ? (
                <>
                  <span className={classes.subTitle}>{subTitleText}</span>
                </>
              ) : null}
            </Typography>
          </Box>
          <Box data-testid="dialog-content-base-id">{children}</Box>
          <Box>{subcontent}</Box>
        </Grid>

        {closeFn ? (
          <Grid item>
            <CrossClose close={closeFn} />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default ModalContentBase;

ModalContentBase.defaultProps = {
  closeFn: null,
  children: null,
  showlogo: true,
  titleText: "ModalContentBase title",
  subTitleText: null,
  subcontent: null,
};

ModalContentBase.propTypes = {
  closeFn: PropTypes.func,
  titleText: PropTypes.string,
  subTitleText: PropTypes.string,
  showlogo: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  subcontent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
