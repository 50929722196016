import React from "react";
import PropTypes from "prop-types";

import InfoIcon from "@mui/icons-material/Info";

const ErrorInfo = (props) => {
  const { id, testid, bgcolor, iconColor, width, message } = props;
  return (
    <div
      id={id}
      data-testid={testid}
      style={{
        marginTop: 6,
        backgroundColor: bgcolor,
        width,
        padding: 10,
        display: "flex",
      }}
    >
      <InfoIcon style={{ marginTop: -2, color: iconColor, marginRight: 6 }} />{" "}
      <span>{message}</span>
    </div>
  );
};

export default ErrorInfo;
ErrorInfo.defaultProps = {
  id: "error-info-id",
  testid: "error-info-id",
  bgcolor: "#FFE4DE",
  iconColor: "#FF0000",
};

ErrorInfo.propTypes = {
  id: PropTypes.string,
  testid: PropTypes.string,
  bgcolor: PropTypes.string,
  iconColor: PropTypes.string,
  width: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
};
