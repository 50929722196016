import React, { useState } from "react";

import { PropTypes } from "prop-types";

import PopMenu from "./PopMenu";
import DataUtilization from "../Pages/DataUtilization/DataUtilization";
import Dialog from "../UI/Dialogs/DraggableDialog/DraggableDialog";

const UserAvatar = ({
  honeycomb,
  user,
  handleLogout,
  ldClientMaster,
  ldClient,
  size,
}) => {
  const [dup, setDup] = useState(null);

  return (
    <>
      <PopMenu
        honeycomb={honeycomb}
        ldClientMaster={ldClientMaster}
        ldClient={ldClient}
        width={200}
        user={user}
        setDup={setDup}
        handleLogout={handleLogout}
        size={size}
      />
      {dup ? (
        <Dialog
          cancelBtnFn={() => {
            const now = new Date();
            honeycomb.sendUiInteractionSpan(`dup-from-avatar`, {
              openTime: dup,
              closeTime: now,
              openDuration: now - dup,
              unit: "ms",
            });
            setDup(null);
          }}
          cancelBtnText="Cancel"
        >
          <DataUtilization />
        </Dialog>
      ) : null}
    </>
  );
};

export default UserAvatar;

UserAvatar.defaultProps = {
  size: "medium",
  ldClient: null,
};

UserAvatar.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  honeycomb: PropTypes.shape({ sendUiInteractionSpan: PropTypes.func })
    .isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email_address: PropTypes.string.isRequired,
    keycloak: PropTypes.bool,
  }).isRequired,
  ldClientMaster: PropTypes.shape().isRequired,
  ldClient: PropTypes.shape(),
  size: PropTypes.string,
};
