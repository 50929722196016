import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import NoDraggableDialog, {
  DLG_INFO,
} from "../../../UI/InfoBoard/Standalone/NoDraggableDialog";
import { infoText } from "./styles";
import { ORCID_USER_COLOR } from "./constants";
import { DF_BTN_INFO } from "../../../UI/Dialogs/DialogFooter";

const GrantSuccessConfirmationDlg = ({ data, continueBtnFn }) => {
  /*
    data = {
      good: {
        users: { OCIRD: { first_name, last_name, email } },
        projects: [FD],
      },
      bad: {
        users: { OCIRD: ERROR_STR },
        projects: [FD],
      },
    };  
  */

  // console.log("[GrantSuccessConfirmationDlg]", data);
  const proj = data.good.projects.map((d) => `${d}`).join(",");
  return (
    <NoDraggableDialog
      type={DLG_INFO}
      title="Grant Access Confirmation"
      width={800}
      acceptBtnText="OK"
      acceptBtnFn={continueBtnFn}
      cancelCrossFn={continueBtnFn}
      btnColor={DF_BTN_INFO}
    >
      <Box style={{ maxHeight: "280px", overflow: "auto" }}>
        {Object.keys(data.good.users).map((orcid, idx) => {
          const key = `${data.good.users[orcid].oid}_${idx}`;
          return (
            <Box key={key}>
              <Typography paragraph sx={infoText}>
                <span style={{ color: ORCID_USER_COLOR, fontWeight: "bold" }}>
                  {data.good.users[orcid].first_name[0]}.{" "}
                  {data.good.users[orcid].last_name}
                </span>{" "}
                (ORCID: <span style={{ fontWeight: "bold" }}>{orcid}</span>) has
                had access granted to the project
                {data.good.projects.length > 1 && "s"} {proj}
              </Typography>
            </Box>
          );
        })}{" "}
      </Box>
    </NoDraggableDialog>
  );
};

export default GrantSuccessConfirmationDlg;

GrantSuccessConfirmationDlg.propTypes = {
  data: PropTypes.PropTypes.shape().isRequired,
  continueBtnFn: PropTypes.func.isRequired,
};
