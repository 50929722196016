import * as React from "react";

import PropTypes from "prop-types";
import { Zoom, useScrollTrigger, Button } from "@material-ui/core";

import backtotop from "../../../assets/images/backtotop.svg";
import classes from "./BackToTop.module.css";

const BackToTop = ({
  topRef,
  honeycomb,
  threshold,
  top,
  left,
  right,
  bottom,
}) => {
  const trigger = useScrollTrigger({
    threshold,
    disableHysteresis: true,
  });

  const handleClick = () => {
    honeycomb.sendUiInteractionSpan(`clicked-back-to-top`);

    if (topRef) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const style = {
    position: "fixed",
    zIndex: "99",
  };

  const map = { bottom, right, top, left };

  Object.keys(map).forEach((key) => {
    if (map[key] !== null) style[key] = `${map[key]}px`;
  });

  return (
    <Zoom in={trigger}>
      <Button onClick={handleClick} style={style}>
        <img
          src={backtotop}
          className={classes.backToTopIcon}
          alt="scroll back to top"
        />
      </Button>
    </Zoom>
  );
};

export default BackToTop;

BackToTop.defaultProps = {
  topRef: null,
  top: null,
  left: null,
  right: 24,
  bottom: 100,
  threshold: 600,
};

BackToTop.propTypes = {
  topRef: PropTypes.shape(),
  honeycomb: PropTypes.shape({ sendUiInteractionSpan: PropTypes.func })
    .isRequired,
  threshold: PropTypes.number,
  top: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
};
