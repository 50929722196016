import * as React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

const BusyDialog = ({ title, message }) => {
  return (
    <div>
      <Dialog
        open
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}

        <DialogContent cs={{ cursor: "progress" }}>
          <Box>
            {message && (
              <DialogContentText id="alert-dialog-description">
                {message}
              </DialogContentText>
            )}
            <Box sx={{ width: 400 }}>
              <LinearProgress />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BusyDialog;

BusyDialog.defaultProps = {
  title: null,
  message: null,
};

BusyDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};
