/* istanbul ignore file */
import React from "react";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { useSearchParams } from "../../hooks/useSearchParams";

import RestoreRequest from "./RestoreRequest";
import CartPage from "./CartPage";
import SearchPageContainer from "./SearchPageContainer/SearchPageContainer";

import { base as ldbase } from "../../utils/LDFFUtils";

/**
  delegate different views to view-specific components
 */
const PageBrancher = (props) => {
  const { ldClient } = props;
  const { restoreid, cart } = useSearchParams();

  const new_filter_ff = ldbase(ldClient, "filter-on-left");

  const MARGIN = 20;
  const HEIGHT_MAX = 1200;
  const topBoxStyle = {
    minWidth: 800,
    marginTop: MARGIN,
    height: "auto",
    maxHeight: HEIGHT_MAX,
  };

  if (new_filter_ff) {
    topBoxStyle.marginLeft = 16;
    topBoxStyle.marginRight = 16;
  } else {
    topBoxStyle.marginLeft = 120;
    topBoxStyle.marginRight = 126;
  }

  const getPage = () => {
    if (restoreid) {
      return <RestoreRequest {...props} />;
    }
    if (cart) {
      return <CartPage {...props} />;
    }
    return <SearchPageContainer {...props} />;
  };

  return (
    <Box className="ContentContainer" style={topBoxStyle}>
      {getPage()}
    </Box>
  );
};

export default PageBrancher;

PageBrancher.propTypes = {
  ldClient: PropTypes.shape().isRequired,
};
