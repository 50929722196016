const add = (path) => {
  if (window.gtag) {
    // Update GA on route change
    const url = window.location.href;
    const m = url.match(/^https?:\/\/\w*.jgi.doe.gov:?\d*/);
    const gapath = m ? `${url.split(m[0])[1]}/${path}` : `${url}/${path}`;

    window.gtag("config", "UA-20148135-6", {
      page_path: gapath,
    });
  }
};

export default {
  add,
};
