import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import classes from "./PagingUI.module.css";

export const PAGE_SIZES = [5, 10, 20, 30, 40, 50];

const PAGE_SIZE_LABEL = "Datasets per page"; // "Page size"

const PageSizeSelector = ({
  pageSizeArray,
  pageSizeValue,
  pageSizeChanged,
}) => {
  let pSizeArray = [...pageSizeArray];
  if (!pSizeArray.includes(pageSizeValue)) {
    pSizeArray.push(pageSizeValue);
    pSizeArray = pSizeArray.sort((a, b) => a - b);
  }

  const handleChange = (event) => {
    const size = event.target.value;
    pageSizeChanged(size);
  };

  return (
    <Select
      data-testid="page-size-select"
      classes={{
        root: classes.pageSizeNum,
      }}
      // value={pageSize}
      value={pageSizeValue}
      onChange={handleChange}
      renderValue={(selected) => {
        return selected;
      }}
      inputProps={{ "aria-label": "Without label" }}
      variant="standard"
      disableUnderline
    >
      {pSizeArray.map((psize) => {
        const tid = `page-size-${psize}`;
        return (
          <MenuItem data-testid={tid} key={psize} value={psize}>
            {psize}
          </MenuItem>
        );
      })}
    </Select>
  );
};
PageSizeSelector.propTypes = {
  pageSizeChanged: PropTypes.func.isRequired,
  pageSizeArray: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ).isRequired,
  pageSizeValue: PropTypes.number.isRequired,
};

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      "&.Mui-selected": {
        background: classes.lake25, // "#DFF9FF",
        border: `1px solid ${classes.lake500}`, // "1px solid #00829B",
        boxSizing: "border-box",
        borderRadius: 4,

        // font from design
        // fontFamily: "Source Sans Pro",
        // fontStyle: "normal",
        // fontWeight: 600,
        // fontSize: 16,
        // lineHeight: "20%",
        // letterSpacing: "0.15px",
        // color: "#000",
        // opacity: 0.87,
      },
      "&.Mui-selected:hover": {
        background: classes.lake50,
      },
    },
    "& .MuiPaginationItem-root:hover": {
      background: classes.lake25,
    },
  },
}));

const PagingUI = ({
  pageSizeLabel,
  pageSizeValue,
  pageTotal,
  pageSizeArray,
  pageNumberValue,
  pageSizeChanged,
  pageNumChanged,
  componentProps,
}) => {
  const paginationClasses = useStyles();

  const [_pageSizeArray, setPageSizeArray] = useState(
    pageSizeArray || PAGE_SIZES
  );

  useEffect(() => {
    if (!_pageSizeArray.includes(pageSizeValue)) {
      _pageSizeArray.push(pageSizeValue);
      setPageSizeArray([..._pageSizeArray]);
    }
  }, [pageSizeValue]);

  const paging = (
    <Pagination
      classes={{ root: paginationClasses.ul }}
      count={pageTotal}
      // defaultPage={curPage === undefined ? 1 : curPage}
      page={pageNumberValue}
      shape="rounded"
      style={{ marginLeft: 10 }}
      onChange={(event, value) => {
        // setPageNumer(value);
        pageNumChanged(value, event);
      }}
    />
  );

  const labelStyle = {
    marginRight: 8,
    position: "relative",
    top: 2,
    color: "#00829B",
    fontWeight: "bold",
  };

  const labelProps = componentProps?.label ? componentProps.label : {};

  return (
    <div style={{ display: "flex" }}>
      <Typography style={{ ...labelStyle, ...labelProps }}>
        {pageSizeLabel}:
      </Typography>
      <PageSizeSelector
        pageSizeChanged={pageSizeChanged}
        pageSizeArray={_pageSizeArray}
        pageSizeValue={pageSizeValue}
      />
      {paging}
    </div>
  );
};

export default PagingUI;

PagingUI.defaultProps = {
  pageSizeLabel: PAGE_SIZE_LABEL,
  componentProps: null,
  pageSizeArray: null,
};

PagingUI.propTypes = {
  pageSizeArray: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  pageTotal: PropTypes.number.isRequired,
  pageNumberValue: PropTypes.number.isRequired,
  pageSizeValue: PropTypes.number.isRequired,
  pageSizeChanged: PropTypes.func.isRequired,
  pageNumChanged: PropTypes.func.isRequired,
  pageSizeLabel: PropTypes.string,
  componentProps: PropTypes.shape(),
};
