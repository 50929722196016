export const STATUS_NOW = "Now";
export const STATUS_ARCHIVED = "Archived";
export const STATUS_PENDING = "Pending";

const fileStatusLabeler = (status) => {
  // default to archived status if unexpected value - JDP-1098
  if (!status || typeof status !== "string") {
    return STATUS_ARCHIVED;
  }
  const splitStatus = status.split(";");

  if (status.toUpperCase() === "PURGED") {
    return STATUS_ARCHIVED;
  }
  if (
    status.toUpperCase() === "BACKUP_COMPLETE" ||
    status.toUpperCase() === "RESTORED" ||
    (splitStatus && splitStatus[0] === "BACKUP_COMPLETE") ||
    (splitStatus && splitStatus[0] === "RESTORED")
  ) {
    return STATUS_NOW;
  }
  if (status.toUpperCase() === "RESTORE_IN_PROGRESS") {
    return STATUS_PENDING;
  }
  // default to archived status if it doesn't map to one of the existing options - JDP-1098
  return STATUS_ARCHIVED;
};

const phytozomeDataTypeLabeler = (rawType) => {
  if (!rawType || typeof rawType !== "string") {
    return rawType;
  }

  const noCapKeys = ["miRNA", "rRNA", "snRNA", "tRNA"];

  // Can contained multiple comma seperated values
  if (rawType.includes(",")) {
    let prettyTypes = "";

    const types = rawType.split(",");
    for (let i = 0, len = types.length; i < len; i++) {
      const type = types[i];
      if (noCapKeys.includes(type)) {
        prettyTypes += type;
      } else {
        // Capitalize it
        prettyTypes += type.charAt(0).toUpperCase() + type.slice(1);
      }

      if (i + 1 !== len) {
        // Add comma, not on last one yet
        prettyTypes += ", ";
      }
    }

    return prettyTypes;
  }

  if (noCapKeys.includes(rawType)) {
    return rawType;
  }
  // Capitalize it
  return rawType.charAt(0).toUpperCase() + rawType.slice(1);
};

export { fileStatusLabeler, phytozomeDataTypeLabeler };
