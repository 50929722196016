import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";

const SkeletonTable = () => {
  const buildSkeletonRow = (key) => (
    <TableRow key={key}>
      <TableCell>
        <Skeleton variant="text" animation="wave" height={38} />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" animation="wave" height={38} />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" animation="wave" height={38} />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" animation="wave" height={38} />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" animation="wave" height={38} />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" animation="wave" height={38} />
      </TableCell>
    </TableRow>
  );

  const buildSkeletonRows = () => {
    const rows = [];
    let skeletonCount = 0;
    while (skeletonCount < 10) {
      skeletonCount += 1;
      rows.push(buildSkeletonRow(skeletonCount));
    }
    return rows;
  };

  return <TableBody>{buildSkeletonRows()}</TableBody>;
};

export default SkeletonTable;
