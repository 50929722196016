import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import WarningIcon from "@material-ui/icons/Warning";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";

const borderColor = "#4A4A4A"; // "#E6E8ED";

export const ICON_NONE = "ICON_NONE";
export const ICON_WARN = "ICON_WARN";

const JDPTooltip = ({
  title,
  children,
  iconType,
  clickOpen,
  parentClose, // when parent comp disappears
  bgcolor, // tip box background color
  txtcolor, // tip box content text color
  txtsize, // tip box content text size
  followCursor, // where to show
  placement, // "bottom", "top", "left", "right"
  bottomOffset,
  arrow,
  controlledOpen, // Open by client
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (parentClose && open) {
      setOpen(false);
    }
  }, [parentClose]);

  const containerStyle = {
    display: "flex",
    alignItems: "center",
    padding: "0px 5px",
  };
  const iconStyle = { color: "#898989", marginRight: 10 };

  const tipStyle = {
    backgroundColor: bgcolor, // rec background color
    border: `1px solid ${borderColor}`, // rec border
    color: txtcolor, // text color
    fontSize: txtsize, // text size
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const tipProps = {
    arrow, // client configured to show the triangle of the tip boundary
    followCursor,
    disableFocusListener: true, // to allow the hyperlink be followed on click
  };
  if (placement) {
    tipProps.placement = placement;
  }
  if (clickOpen) {
    tipProps.open = open;
    // tipProps.onClose = handleTooltipClose;
  }
  if (controlledOpen !== null) {
    tipProps.open = controlledOpen;
  }

  const icon = () => {
    return iconType === ICON_WARN ? <WarningIcon style={iconStyle} /> : null; // other icons?
  };

  const tooltipText = clickOpen ? (
    <table>
      <tbody>
        <tr>
          <td style={{ width: "100%" }}>{title}</td>
          <td style={{ verticalAlign: "top" }}>
            <CloseIcon
              size="small"
              style={{ cursor: "pointer" }}
              onClick={handleTooltipClose}
            />
          </td>
        </tr>
      </tbody>
    </table>
  ) : (
    title
  );

  const body = clickOpen ? (
    <Button
      style={{ textTransform: "unset", padding: 0 }}
      onClick={handleClick}
    >
      {children}
    </Button>
  ) : (
    children || <span />
  );

  // the tip box arraw - with white background and user defined border color;
  // border and background matches the main rect
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      interactive={0} // to dismiss console warning of bool is assiend to non-bool param
      classes={{ popper: className }}
      // placement="bottom-start"
      slotProps={{
        // distance between tooltip and the anchor
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, bottomOffset],
                // [x,y] when @ bottom, positive value moving to bottom-right
                // when @ top, positive values move to top-left
              },
            },
          ],
        },
      }}
    />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      ...tipStyle,
      width: "auto",
    },
    [`& .${tooltipClasses.arrow}`]: {
      "&:before": {
        border: `1px solid ${borderColor}`,
        backgroundColor: bgcolor,
      },
    },
  });

  return (
    <CustomWidthTooltip
      title={
        <div style={containerStyle}>
          {icon()}
          {tooltipText}
        </div>
      }
      {...tipProps}
    >
      {body}
    </CustomWidthTooltip>
  );
};
export default JDPTooltip;

JDPTooltip.defaultProps = {
  iconType: ICON_WARN,
  clickOpen: false,
  parentClose: false,
  width: null,
  marginRight: null,
  marginTop: null,
  marginBottom: null,
  bgcolor: "#FFFFFF",
  txtcolor: "#222222",
  txtsize: 14,
  placement: null,
  followCursor: false,
  bottomOffset: 0,
  arrow: true,
  controlledOpen: null,
};

JDPTooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
  iconType: PropTypes.string,
  clickOpen: PropTypes.bool,
  parentClose: PropTypes.bool,
  width: PropTypes.number,
  marginRight: PropTypes.number,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  bgcolor: PropTypes.string,
  txtcolor: PropTypes.string,
  txtsize: PropTypes.number,
  placement: PropTypes.string,
  followCursor: PropTypes.bool,
  bottomOffset: PropTypes.number,
  arrow: PropTypes.bool,
  controlledOpen: PropTypes.bool,
};
