import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";

import cookieUtil from "../../../utils/CookieHandler";

import NoDraggableDialog from "../../UI/InfoBoard/Standalone/NoDraggableDialog";

import { whoami } from "../../../utils/HttpClientProvider";
import classes from "../Header.module.css";

const MimicUserDialog = ({ handleCancel, currentUser }) => {
  const [currentMimicUserId, setCurrentMimicUserId] = useState(null);
  const [contactId, setContactID] = useState("");
  const [badformat, setBadformat] = useState(false);
  const [warning, setWarning] = useState(null);
  const [currentMimicUser, setCurrentMimicUser] = useState(null);
  const [toBeMimicUser, setToBeMimicUser] = useState(null);

  const WARN_GENERAL = "WARN_GENERAL";
  const WARN_INVALID_ID = "WARN_INVALID_ID";
  const WARN_CURRENT = "WARN_CURRENT";
  const WARN_VALID_USER = "WARN_VALID_USER";

  useEffect(() => {
    const mimicId = cookieUtil.getContactIDCookie();
    if (mimicId && currentUser.mimic) {
      setCurrentMimicUser(currentUser.mimic);
      setContactID(mimicId);
      setCurrentMimicUserId(mimicId);
    }
  }, []);

  const sanityCheck = () => {
    setToBeMimicUser(null);
    if (!contactId) {
      setWarning({
        type: WARN_GENERAL,
        text: "Need contact id in the input field.",
      });
      return false;
    }

    if (currentMimicUser && contactId === `${currentMimicUser.contact_id}`) {
      setWarning({
        type: WARN_CURRENT,
        text: `${contactId} is the currently mimicked user`,
      });
      return false;
    }

    return true;
  };

  const resetCookie = () => {
    if (currentMimicUser?.contactId) {
      cookieUtil.setContactIDCookie(currentMimicUser.contactId);
    } else {
      cookieUtil.deleteContactIDCookie();
    }
  };

  const validateMimicUser = (apply = false) => {
    if (apply && toBeMimicUser) {
      window.location.reload(false);
    }

    cookieUtil.setContactIDCookie(contactId);
    const promise = whoami();

    promise.then((resp) => {
      resetCookie();
      if (resp?.error) {
        setWarning({
          type: WARN_GENERAL,
          text: `Validation failed : ${resp.error}`,
        });
        return false;
      }
      const udata = resp.data?.mimic;
      if (!udata) {
        setWarning({
          type: WARN_GENERAL,
          text: `Validation failed : no mimic info returned by whoami`,
        });
        return false;
      }
      if (udata.error) {
        if (udata.error === "contact ID not found") {
          setWarning({
            type: WARN_INVALID_ID,
            text: `Not a valid ID`,
          });
          return false;
        }
        setWarning({
          type: WARN_GENERAL,
          text: `Validation failed : ${udata.error}`,
        });
        return false;
      }
      if (apply) {
        cookieUtil.setContactIDCookie(contactId);
        window.location.reload(false);
      } else {
        setToBeMimicUser(udata);
        setWarning({
          type: WARN_VALID_USER,
          text: `${udata.first_name} ${udata.last_name}  ${udata.email_address}`,
        });
      }
    });
  };

  const handleValidate = () => {
    if (sanityCheck()) {
      validateMimicUser();
    }
  };

  const handleApply = (event) => {
    if (event) {
      event.stopPropagation();
    }

    if (!sanityCheck()) {
      return;
    }

    validateMimicUser(true);
  };

  const doCancel = (event) => {
    // console.log("[doCancel]", currentMimicUser, contactId);
    if (
      currentMimicUser &&
      contactId &&
      currentMimicUser.contact_id !== contactId
    ) {
      // New contact id haven't been applied
      cookieUtil.setContactIDCookie(currentMimicUser.contact_id);
    }
    handleCancel(event);
  };

  const getWarning = () => {
    switch (warning?.type) {
      case WARN_INVALID_ID:
        return (
          <Grid container wrap="nowrap">
            <ErrorIcon sx={{ color: "red" }} />
            <Typography>{warning.text}</Typography>
          </Grid>
        );
      case WARN_CURRENT:
      case WARN_GENERAL:
        return <Typography>{warning.text}</Typography>;
      case WARN_VALID_USER:
        return (
          <Grid container>
            <InfoIcon sx={{ color: "green", marginRight: 1 }} />
            {/* <Typography>Find user: </Typography> */}
            <Typography>{warning.text}</Typography>
          </Grid>
        );

      default:
        return null;
    }
  };

  // console.log("[warning]", warning, user);
  const info = getWarning();

  const infoBoxColor =
    warning?.type === WARN_GENERAL || warning?.type === WARN_INVALID_ID
      ? "#FFE4DE"
      : warning?.type === WARN_VALID_USER
      ? "#BFF4FF"
      : "#eee";

  const btnGen = (label, click, disabled = false) => (
    <Button
      id={label.toLowerCase().replace(/ /g, "_")}
      disabled={disabled}
      variant="contained"
      style={{ textTransform: "none" }}
      onClick={click}
    >
      {label}
    </Button>
  );

  const content = (
    <>
      <Box
        component="form"
        sx={{
          width: 400,
        }}
        noValidate
        autoComplete="off"
      >
        <Grid container spacing={1} alignItems="center" columns={20}>
          <Grid item xs={20}>
            <TextField
              error={badformat || warning?.type === WARN_INVALID_ID}
              id="contact-id"
              data-testid="contact-id"
              label="Contact ID to Mimic"
              placeholder="Contact ID to Mimic"
              value={contactId}
              onKeyPress={(ev) => {
                // validate contact id on Enter key
                if (ev.key === "Enter") {
                  ev.preventDefault();
                  handleValidate();
                }
              }}
              onChange={(event) => {
                setBadformat(false);
                setWarning(null);
                const reg = new RegExp(/^\d+$/);
                const val = event.target.value;
                if (!val || reg.test(val)) {
                  setContactID(val);
                } else {
                  setBadformat(true);
                }
              }}
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </Box>
      {info && (
        <Box
          sx={{
            marginBottom: 2,
            marginTop: 1,
            padding: 1,
            backgroundColor: `${infoBoxColor}`,
            borderRadius: 1,
          }}
        >
          {info}
        </Box>
      )}
      <Link
        component={RouterLink}
        target="_blank"
        to="//contacts.jgi.doe.gov/"
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{
          textDecoration: "none",
        }}
      >
        <Typography
          style={{
            marginLeft: 2,
            fontSize: 14,
            fontStyle: "italic",
          }}
        >
          * Find Contact ID Here{" "}
        </Typography>
      </Link>

      {currentMimicUser && (
        <Grid
          container
          sx={{
            marginTop: 2,
            padding: 1,
            backgroundColor: "#FFE9BD",
          }}
        >
          <WarningIcon style={{ color: classes.orange600, marginRight: 1 }} />
          <Typography>Mimicking user</Typography>
          <Typography sx={{ paddingLeft: 1, fontWeight: "bold" }}>
            {currentMimicUser.first_name} {currentMimicUser.last_name}{" "}
            {currentMimicUser.email_address}
          </Typography>
        </Grid>
      )}

      <Grid container spacing={1} columns={20} sx={{ marginTop: 2 }}>
        <Grid item xs={10}>
          {btnGen("Apply Contact ID", handleApply, false)}
        </Grid>

        <Grid item xs={10}>
          {btnGen(
            "Stop Mimicking",
            (event) => {
              event.stopPropagation();
              cookieUtil.deleteContactIDCookie();
              cookieUtil.setPMOSEnv(null); // reset to production
              window.location.reload(false);
            },
            !currentMimicUserId
          )}
        </Grid>
      </Grid>
    </>
  );
  return (
    <NoDraggableDialog
      // width={800}
      title="Mimic User"
      subcontent={content}
      cancelCrossFn={doCancel}
    />
  );
};

export default MimicUserDialog;

MimicUserDialog.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  currentUser: PropTypes.shape().isRequired,
};
