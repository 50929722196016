/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import Link from "@mui/material/Link";
import { CSVLink } from "react-csv";

import classes from "../DataGrid.module.css";
import { infoText, infoTextBold } from "./styles";

import NoDraggableDialog from "../../../UI/InfoBoard/Standalone/NoDraggableDialog";

const NotPiAlertDialog = ({ uid, data, cancelBtnFn, proceedBtnFn }) => {
  // console.log("[NotPiAlertDialog]", uid, JSON.stringify(data));
  const [config, setConfig] = useState({});

  const csvLink = useRef(); // setup the ref that we'll use for the hidden CsvLink click once we've updated the data

  useEffect(() => {
    // console.log("[DLG]", user);
    const conf = {
      good: [],
      bad: [],
    };

    data.forEach((d) => {
      if (`${d.pi_contact_id}` === uid) {
        conf.good.push(d);
      } else {
        conf.bad.push(d);
      }
    });

    setConfig(conf);
  }, []);

  const exportHandler = () => {
    // console.log("[exportHandler]", config.bad);
    csvLink.current.link.click();
    cancelBtnFn();
  };

  let aBtnTxt = "Proceed without this project";
  if (config.good?.length > 0 && config.bad?.length > 1) {
    aBtnTxt = "Proceed without these projects";
  } else if (config.good?.length === 0) {
    aBtnTxt = null;
  }

  let msgPrefix = `You are not a PI for the selected project${
    config.bad?.length > 1 ? "s" : ""
  }.`;
  if (config.good?.length > 0 && config.bad?.length > 0) {
    msgPrefix = "You have selected project(s) for which you are not a PI.";
  }

  const tableStyle = {
    borderSpacing: 4,
    // border: "1px solid",
    // borderCollapse: "collapse",
  };
  const numStyle = {
    fontSize: 14,
    fontWeight: "bold",
    verticalAlign: "top",
  };
  const idStyle = { ...numStyle };
  const piStyle = { fontSize: 14, padding: "0px 20px" };
  const emailStyle = { ...piStyle };
  // DEBUG
  // numStyle.border = "1px solid #cccccc";
  // idStyle.border = "1px solid #cccccc";
  // piStyle.border = "1px solid #cccccc";
  // emailStyle.border = "1px solid #cccccc";

  return (
    <NoDraggableDialog
      title="Insufficient Privileges"
      width={800}
      // contentHeight={300}
      acceptBtnText={aBtnTxt || "Cancel"}
      acceptBtnFn={() => {
        if (!aBtnTxt) {
          cancelBtnFn();
        } else {
          proceedBtnFn(true);
        }
      }}
      cancelBtnText="Export PI Contact Information"
      cancelCrossFn={cancelBtnFn}
      cancelBtnFn={exportHandler}
      subcontent={
        <div style={{ paddingTop: "24px", display: "flex" }}>
          <LiveHelpIcon
            sx={{ color: classes.lake500, position: "relative", top: 2 }}
          />
          <Link
            href="https://sites.google.com/lbl.gov/data-portal-help/home/faq#h.ec6vdlscyc4i"
            target="_blank"
            sx={{ color: classes.lake500, fontSize: 18, marginLeft: "10px" }}
          >
            Learn more about grant/access
          </Link>
        </div>
      }
    >
      <Typography paragraph sx={infoText}>
        {msgPrefix} Please contact the PI{config.bad?.length > 1 && `s`} to
        manage access to these project(s) on your behalf.
      </Typography>
      <Typography sx={infoTextBold}>
        Datasets affected ({config.bad?.length}):
      </Typography>
      <div style={{ overflow: "auto", maxHeight: 100, maxWidth: 600 }}>
        <table style={tableStyle}>
          <tbody>
            {config.bad?.length > 0 &&
              config.bad.map((d, idx) => (
                <tr key={`${d.id}`}>
                  <td style={numStyle}>{idx + 1}.</td>
                  <td style={idStyle}>{d.id}</td>
                  <td style={piStyle}>PI: {d.PI?.name || `NULL`}</td>
                  {/* <td style={emailStyle}>Email: {d.PI?.email || `NULL`}</td> */}
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {config.bad?.length > 0 && (
        <CSVLink
          data={config.bad.map((d) => {
            const tmp = {};
            tmp.id = d.id;
            tmp.ProjName = d.projName;
            tmp.PIName = d.PI?.name;
            tmp.PIEmail = d.PI?.email;
            // other fields??
            return tmp;
          })}
          filename="access_request_contacts.csv"
          className="hidden"
          ref={csvLink}
          target="_blank"
        />
      )}
    </NoDraggableDialog>
  );
};

export default NotPiAlertDialog;

NotPiAlertDialog.propTypes = {
  uid: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  cancelBtnFn: PropTypes.func.isRequired,
  proceedBtnFn: PropTypes.func.isRequired,
};
