/* istanbul ignore file */
import React, { useRef, useState } from "react";

import Libhoney from "libhoney";
import { v4 as uuid4 } from "uuid";
import { useLocation, useParams } from "react-router-dom";

import Release from "../../../release";
import withPropPrefix from "../../../utils/ObjectUtils";
import { HCTRACE_COOKIE_KEY } from "../../../utils/CookieHandler";

/**
 * Higher order component used to add honeycomb related functions to the WrappedComponent.
 * Functions are added to props under 'honeycomb'.
 *
 * Typical Usage: Set up tracing using initRootTraceID() early in the app startup. Then throughout
 * the app when info should be sent to HC, use one of the 'sendXyzSpan' functions to send to honeycomb
 * with the correct service_name. The raw 'sendSpan' and 'buildTrace' functions are used to provide
 * support for functionality beyond what is provided by the specific sendXyzSpan functions (like sending with
 * a parentID or sending a span with a new service_name).
 */
const withHoneycomb = (WrappedComponent) => {
  const HoneycombHOC = (props) => {
    const [user, setUser] = useState();
    /** Create a version 4 UUID string that is a valid Honeycomb trace/span ID. */
    const location = useLocation();
    const params = useParams();

    const generateNewSpanID = () => uuid4();

    const traceID = useRef(generateNewSpanID());
    const jgiFrontendHoneycomb = useRef(
      new Libhoney({
        writeKey: process.env.REACT_APP_HONEYCOMB_KEY, // Key from env variable
        // NODE_ENV is set depending on script used:
        // npm start -> 'development', npm test -> 'test', npm build -> 'production'
        dataset:
          process.env.NODE_ENV === "production"
            ? "jgidp-production"
            : "jgidp-dev",
      })
    );

    /** Generate and retain a root trace ID */
    const initRootTraceID = () => {
      traceID.current = generateNewSpanID();
    };

    /** Return the retained trace ID */
    const getTraceID = () => {
      return traceID.current;
    };

    /**
     * Build a trace object for honeycomb containing the 3 tracing ID's: trace, span, and parent
     * @param  {string} [parentID] ID of the spans direct parent, can be null for root level spans
     * @param  {string} [spanID] ID to be used as the spanID, generated or uses root trace ID if not provided
     * @returns An object containing all trace ids with honeycomb consistent id casing
     */
    const buildTrace = (parentID, spanID) => {
      return {
        trace_id: traceID.current,
        span_id: spanID || (parentID ? generateNewSpanID() : traceID.current),
        parent_id: parentID,
      };
    };

    /**
     * Send a span to the JGI Honeycomb. In addition to the provided payload,
     * the span sent to honeycomb includes user info, user system info,
     * launch darkly feature flags, and app version/git information.
     * @param {Object} payload Payload to be included in the span sent to honeycomb
     * @param {Object} trace Tracing object providing the 3 tracing ID's (trace, span, and parent)
     */
    const sendSpan = (payload, trace) => {
      const jsHeapUsed =
        window.performance.memory && window.performance.memory.usedJSHeapSize;
      const jsHeapTotal =
        window.performance.memory && window.performance.memory.totalJSHeapSize;
      const { timestamp, duration, status, ...rest } = payload;
      if (duration !== undefined) {
        rest.duration_ms = duration;
      }
      if (status !== undefined) {
        rest.status_code = status;
      }
      const hcUser = {};
      const names = ["id", "login", "email_address"];
      names.forEach((prop) => {
        if (user && user[prop]) {
          hcUser[`request.user.${prop}`] = user[prop];
        }
      });

      const honeycombPayload = {
        // Span Tracing IDs
        ...withPropPrefix(trace, "trace"),

        // Add Connection Info
        connection_type: navigator.connection && navigator.connection.type,
        connection_type_effective:
          navigator.connection && navigator.connection.effectiveType,
        connection_rtt: navigator.connection && navigator.connection.rtt,

        // Window/Screen Info
        "request.user_agent": window.navigator.userAgent,
        window_height: window.innerHeight,
        window_width: window.innerWidth,
        screen_height: window.screen && window.screen.height,
        screen_width: window.screen && window.screen.width,

        // Memory Info
        js_heap_used: jsHeapUsed,
        js_heap_total: jsHeapTotal,

        // Hardcode user for now since have no auth
        ...hcUser,

        jgidp_frontend_commit: Release.commit,
        jgidp_frontend_version: Release.version,

        location: { ...location },
        params: { ...params },
        // Send the rest of our fields
        ...rest,
      };

      const event = jgiFrontendHoneycomb.current.newEvent();
      event.add(honeycombPayload);

      // Need to subtract off the duration otherwise the duration time will start when
      // the span arrives (should end when duration is over, not start)
      if (payload.timestamp) {
        event.timestamp = payload.timestamp;
      } else if (payload.duration) {
        event.timestamp = new Date(Date.now() - payload.duration).toISOString();
      } else {
        event.timestamp = new Date().toISOString();
      }

      event.send();
    };

    /**
     * Send a span to honeycomb with the service_name: "axios-get".
     * Typically used for timing and success measurement spans related to fetching data.
     * @param {string} path The path/route of the request (used in the span name as well)
     * @param {Object} [payload] Optional payload object to be included in honeycomb span
     * @param {Object} [trace] Optional tracing object, if not provided sets trace root span as parent
     * @returns {string} The spanID of the span sent to honeycomb
     */
    const sendAxiosGetSpan = (path, payload, trace) => {
      const hcPayload = {
        api: path,
        name: `${path}-fetch`,
        service_name: "axios-get",
        ...payload,
      };
      const hcTrace = trace || buildTrace(traceID.current);

      sendSpan(hcPayload, hcTrace);
      return hcTrace.span_id;
    };

    /**
     * Send a span to honeycomb with the service_name: "download".
     * Typically used for timing and success measurement spans related to downloading files.
     * @param {string} name The name of the span to be sent to honeycomb
     * @param {Object} [payload] Optional payload object to be included in honeycomb span
     * @param {Object} [trace] Optional tracing object, if not provided sets trace root span as parent
     * @returns {string} The spanID of the span sent to honeycomb
     */
    const sendDownloadSpan = (name, payload, trace) => {
      const hcPayload = {
        name,
        service_name: "download",
        ...payload,
      };
      const hcTrace = trace || buildTrace(traceID.current);

      sendSpan(hcPayload, hcTrace);
      return hcTrace.span_id;
    };

    /**
     * Send a span to honeycomb with the service_name: "frontend-processing".
     * Typically used for timing durations of frontend 'work'.
     * @param {string} name The name of the span to be sent to honeycomb
     * @param {Object} [payload] Optional payload object to be included in honeycomb span
     * @param {Object} [trace] Optional tracing object, if not provided sets trace root span as parent
     * @returns {string} The spanID of the span sent to honeycomb
     */
    const sendProcessingSpan = (name, payload, trace) => {
      const hcPayload = {
        name,
        service_name: "frontend-processing",
        ...payload,
      };
      const hcTrace = trace || buildTrace(traceID.current);

      sendSpan(hcPayload, hcTrace);
      return hcTrace.span_id;
    };

    /**
     * Send a span to honeycomb with the service_name: "react-router".
     * Typically used to report routing changes.
     * @param {string} newRoute The new route per react router (used in span name as well)
     * @param {Object} [payload] Optional payload object to be included in honeycomb span
     * @param {Object} [trace] Optional tracing object, if not provided sets trace root span as parent
     * @returns {string} The spanID of the span sent to honeycomb
     */
    const sendRoutingSpan = (newRoute, payload, trace) => {
      const hcPayload = {
        name: `reroute-to: ${newRoute}`,
        service_name: "react-router",
        ...payload,
      };
      const hcTrace = trace || buildTrace(traceID.current);

      sendSpan(hcPayload, hcTrace);
      return hcTrace.span_id;
    };

    /**
     * Send a span to honeycomb with the service_name: "ui-interaction".
     * Typically used for sending spans describing user interaction with the app (i.e. clicks, submits, ect)
     * @param {string} name The name of the span to be sent to honeycomb
     * @param {Object} [payload] Optional payload object to be included in honeycomb span
     * @param {Object} [trace] Optional tracing object, if not provided sets trace root span as parent
     * @returns {string} The spanID of the span sent to honeycomb
     */
    const sendUiInteractionSpan = (name, payload, trace) => {
      const hcPayload = {
        name,
        service_name: "ui-interaction",
        ...payload,
      };
      const hcTrace = trace || buildTrace(traceID.current);

      sendSpan(hcPayload, hcTrace);
      return hcTrace.span_id;
    };

    /** Return the X-Honeycomb-Trace header */
    const getTraceHeader = (trace) =>
      trace && trace.trace_id && trace.span_id
        ? {
            [HCTRACE_COOKIE_KEY.replace(/_/g, "-")]: `1;trace_id=${
              trace.trace_id
            },parent_id=${trace.span_id}${
              process.env.NODE_ENV !== "production"
                ? `,dataset=${jgiFrontendHoneycomb.current.dataset}`
                : ""
            }`,
          }
        : null;

    return (
      <WrappedComponent
        {...props}
        honeycomb={{
          buildTrace,
          getTraceID,
          initRootTraceID,
          sendAxiosGetSpan,
          sendDownloadSpan,
          sendProcessingSpan,
          sendRoutingSpan,
          sendSpan,
          sendUiInteractionSpan,
          getTraceHeader,
          setHcUser: setUser,
        }}
      />
    );
  };

  return HoneycombHOC;
};

export default withHoneycomb;
