/* istanbul ignore file */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import classes from "./Card.module.css";

const LinkButton = ({ href, label, newtab, honeykey, honeycomb }) => {
  const css = ` ${classes.actionTextLarge} ${classes.linkButton}`;
  return (
    <div className={classes.linkButtonContainer}>
      <Link
        className={css}
        href={href}
        underline="none"
        target={newtab ? "_blank" : null}
        onClick={() => {
          if (honeykey && honeycomb) {
            honeycomb.sendUiInteractionSpan(honeykey, {
              link: `${label}/${href}`,
            });
          }
        }}
      >
        {label}
        <KeyboardArrowRightIcon className={classes.linkButtonIcon} />
      </Link>
    </div>
  );
};

export default LinkButton;

LinkButton.defaultProps = {
  newtab: false,
  honeycomb: null,
  honeykey: null,
};

LinkButton.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  newtab: PropTypes.bool,
  honeycomb: PropTypes.shape(),
  honeykey: PropTypes.string,
};
