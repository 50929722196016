import React from "react";
import PropTypes from "prop-types";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";

const SkeletonTable = ({ rowCount }) => {
  const buildSkeletonRow = (key) => (
    <TableRow key={`skeleton_row_${key}`} style={{ width: "100%" }}>
      <TableCell style={{ width: "100%" }}>
        <Skeleton
          variant="text"
          animation="wave"
          height={120}
          style={{ width: "100%" }}
        />
      </TableCell>
    </TableRow>
  );

  const buildSkeletonRows = () => {
    const rows = [];
    const i = rowCount || 10;
    let skeletonCount = 0;
    while (skeletonCount < i) {
      skeletonCount += 1;
      rows.push(buildSkeletonRow(skeletonCount));
    }
    return rows;
  };

  return <TableBody style={{ width: "100%" }}>{buildSkeletonRows()}</TableBody>;
};

export default SkeletonTable;

SkeletonTable.defaultProps = {
  rowCount: 10,
};

SkeletonTable.propTypes = {
  rowCount: PropTypes.number,
};
