// split title line into <56 char sublines, on word boundary
const toLinesByWordBoundary = (instr, lmax = 56) => {
  if (!instr) return [];
  const noLineBreakText = instr.replace(/\r?\n|\r/g, " ");
  const toks = noLineBreakText.split(" ").filter((e) => e !== "");
  const lines = [];
  const tmpList = [];
  toks.every((word) => {
    if (tmpList.length > 0 && `${tmpList.join(" ")} ${word}`.length > lmax) {
      lines.push(tmpList.join(" "));
      tmpList.splice(0, tmpList.length);
    }
    tmpList.push(word);
    return true;
  });
  if (tmpList.length > 0) {
    lines.push(tmpList.join(" "));
  }
  return lines;
};

// remove lenth from end, at word boundary
const removeFromEndOnWord = (instr, lenth) => {
  const words = instr.split(" ").filter((w) => w !== "");
  let lastIdx = words.length - 1;
  let cutLen = lenth;
  for (; lastIdx > -1; lastIdx--) {
    const lastWord = words[lastIdx];
    cutLen -= lastWord.length + 1;
    if (cutLen <= 0) break;
  }
  const reminder = words.slice(0, lastIdx);
  return reminder.join(" ");
};

const toInt = (val, defVal = 0) => {
  // parseInt(val), if false, return defVal
  const ival = parseInt(val, 10);
  return !Number.isNaN(ival) ? ival : defVal;
};

// valid ORCID : https://support.orcid.org/hc/en-us/articles/360006897674-Structure-of-the-ORCID-Identifier
// last position can be digit or "X"
const isValidOrcid = (val) =>
  val.match(/^\d{4}-\d{4}-\d{4}-\d{3}[\dX]$/) !== null;

export { toLinesByWordBoundary, removeFromEndOnWord, toInt, isValidOrcid };
