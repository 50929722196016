/* istanbul ignore file */
import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { Link } from "@material-ui/core";

import classes from "./HomePage.module.css";
import { HREF_DOC_JGI_DATA } from "../../utils/URLConstants";

const OurData = ({ honeycomb }) => {
  const linkClass = classes.linkLake400;
  const getLinkComp = (label, href) => {
    const props = {
      className: linkClass,
      href,
      target: "_blank",
      onClick: () => {
        if (honeycomb) {
          honeycomb.sendUiInteractionSpan("homepage-link-click", {
            link: `${label}/${href}`,
          });
        }
      },
    };
    return <Link {...props}>{label}</Link>;
  };

  return (
    <div>
      <Typography
        className={`${classes.h2Desktop} ${classes.ourdataTitleText}`}
      >
        Our data
      </Typography>
      <Typography
        className={`${classes.bodyLargeDesktop} ${classes.ourdataText}`}
      >
        The U.S. Department of Energy (DOE) Joint Genome Institute (JGI) is a
        DOE Office of Science User Facility located at Lawrence Berkeley
        National Laboratory (Berkeley Lab). The JGI takes great pride in
        producing high-quality genomic and metagenomic data outputs for our
        users and the community. We ensure consistent quality by taking the
        following measures:
      </Typography>

      <div className={`${classes.bodyLargeDesktop} ${classes.ourdataBullet}`}>
        <ul>
          <li>Offering varied products and clearly indicating their quality</li>
          <li>Conducting expert-level review of data before release</li>
          <li>Producing deeper metagenome sequences</li>
          <li>Developing new tools</li>
          <li>
            {getLinkComp(
              "Learn more about our data quality",
              HREF_DOC_JGI_DATA
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default OurData;

OurData.defaultProps = {
  honeycomb: null,
};

OurData.propTypes = {
  honeycomb: PropTypes.shape(),
};
