import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import classes from "../Alert.module.css";

const GenericIssues = (props) => {
  const { title, subtitle, message, honeycomb } = props;
  const [rendered, setRendered] = useState(false);
  const honeycombMsg = "generic issues : ";

  useEffect(() => {
    if (honeycombMsg && !rendered) {
      honeycomb.sendProcessingSpan(honeycombMsg);
      setRendered(true);
    }
  }, [honeycomb, honeycombMsg, rendered]);

  const stitle = subtitle ? (
    <span className={classes.subtitle}> - {subtitle}</span>
  ) : null;
  return honeycombMsg ? (
    <Grid container>
      <Grid item className={classes.background}>
        <Grid
          container
          spacing={2}
          wrap="nowrap"
          className={classes.warningContainer}
        >
          <Grid item>
            <InfoIcon color="action" fontSize="large" />
          </Grid>
          <Grid item>
            <Typography variant="h3" className={classes.h5DesktopHeavy}>
              {title} {stitle}
            </Typography>
            <Typography variant="subtitle1" className={classes.bodySmall}>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: message,
                }}
              />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};

export default GenericIssues;

GenericIssues.defaultProps = {
  honeycomb: {
    sendProcessingSpan: () => {},
  },
  subtitle: null,
};

GenericIssues.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  message: PropTypes.string.isRequired,
  honeycomb: PropTypes.shape({
    sendProcessingSpan: PropTypes.func,
  }),
};
