import React, { useContext } from "react";
import { Box, Typography } from "@material-ui/core/";
import { useParams } from "react-router-dom";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useQuery } from "react-query";

import ConfigContext from "../../../context/ConfigContext";
import { getStatus } from "../../../utils/HttpClientProvider";

export default function NullQueryState() {
  const { segment } = useParams();
  const { kingdom } = useContext(ConfigContext);

  const { data: apiStatus, isLoading } = useQuery(["api-status"], () =>
    getStatus()
  );

  if (isLoading) {
    return "";
  }

  const statusKey = segment === "search" ? "searchable" : segment;

  if (!apiStatus[statusKey]) {
    throw new Error(`No status key for ${segment}`);
  }

  const totalOrganisms = apiStatus[statusKey].organisms.toLocaleString();
  // console.log(apiStatus, statusKey, totalOrganisms, "[NullQuery]");

  const theBox = (
    <Box
      style={{
        height: "120px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
      data-testid="null-query-state"
    >
      <FilterListIcon
        style={{
          width: 120,
          height: 120,
          color: "#C8C8C8",
        }}
        viewBox="-5 -5 32.7 32.7"
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography
          display="block"
          style={{
            fontSize: 44,
            color: "#919191",
            fontWeight: "bold",
            marginLeft: -3,
            paddingTop: 12,
          }}
        >
          Start your search
        </Typography>

        <Typography display="block" style={{ fontSize: 16, marginTop: -6 }}>
          {`${kingdom} has data for more than ${totalOrganisms} organisms.`}
        </Typography>
        <Typography>
          Enter a search term to find what you&apos;re looking for.
        </Typography>
      </Box>
    </Box>
  );

  return theBox;
}

NullQueryState.defaultProps = {};

NullQueryState.propTypes = {};
