/* istanbul ignore file */
import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import JDPLifeHelpIcon from "../../UI/Icons/JDPLifeHelpIcon";
import classes from "../../UI/InfoBoard/InfoBoard.module.css";
import { HREF_DOC_ARCHIVED } from "../../../utils/URLConstants";

const GlobusDownloadOKWithId = ({ requestid, currentUser, fileSummary }) => {
  return (
    <div id="globus-download-confirmation" className={classes.block}>
      <Typography paragraph>
        We’ve received your download request <b>#{requestid}</b> and are
        gathering your files now. Please refer to this id when you have any
        questions about the status of your download request.
      </Typography>
      {fileSummary.purgedSizeInBytes ? (
        <Typography paragraph>
          Your{" "}
          <Link href={HREF_DOC_ARCHIVED} target="_blank">
            <b>archived files</b>
          </Link>{" "}
          will be accessible within <b>24 hours</b>.
        </Typography>
      ) : null}
      <Typography paragraph>
        We’ll send you an email to <b>{currentUser.email_address}</b> once the
        files are ready to be downloaded.
      </Typography>

      {fileSummary.purgedSizeInBytes ? (
        <Typography paragraph>
          <JDPLifeHelpIcon size="default">
            <Link href={HREF_DOC_ARCHIVED} target="_blank" underline="hover">
              Learn about archived files and how they affect downloads
            </Link>
          </JDPLifeHelpIcon>
        </Typography>
      ) : null}
    </div>
  );
};

export default GlobusDownloadOKWithId;

GlobusDownloadOKWithId.propTypes = {
  requestid: PropTypes.number.isRequired,
  currentUser: PropTypes.shape({
    email_address: PropTypes.string.isRequired,
  }).isRequired,
  fileSummary: PropTypes.shape({
    totalCount: PropTypes.number.isRequired,
    totalSizeInBytes: PropTypes.number.isRequired,
    purgedSizeInBytes: PropTypes.number.isRequired,
  }).isRequired,
};
