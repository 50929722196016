import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import WarningIcon from "@material-ui/icons/Warning";
import Tooltip from "@material-ui/core/Tooltip";
import filesize from "filesize";
import {
  getBrowserDownloadWarningSizeInByte,
  getGlobusDownloadSizeInByte,
} from "../../../utils/FileUtils";

import { useSearchParams } from "../../../hooks/useSearchParams";

import {
  globusDownloadSize,
  browserDownloadWarningSize,
} from "../../../utils/LDFFUtils";
import {
  BROWSER_DOWNLOAD_SIZE_INFO,
  GLOBUS_DOWNLOAD_SIZE_INFO,
  TAPE_FILE_INFO,
} from "../../Alert/PageAlert/PageAlert";
import classes from "./FileStats.module.css";

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: 320,
    paddingRight: "26px",
  },
}))(Tooltip);

const FileStats = ({
  fileSummary,
  setAppMessageID,
  ldClient,
  cart,
  imgBulk,
}) => {
  const { restoreid } = useSearchParams();
  const sizeInfo = fileSummary
    ? ` ${filesize(fileSummary?.totalSizeInBytes, { round: 1 })}`
    : "";

  const infoTxt = [];
  const warningSizeByte = getBrowserDownloadWarningSizeInByte(ldClient);
  const warningSizeInFF = browserDownloadWarningSize(ldClient);

  const globusSizeByte = getGlobusDownloadSizeInByte(ldClient);
  const globusSizeInFF = globusDownloadSize(ldClient);

  if (fileSummary?.totalSizeInBytes > globusSizeByte) {
    const gsize = globusSizeInFF
      ? `${globusSizeInFF}GB`
      : filesize(globusSizeByte, { round: 1 });
    infoTxt.push(
      `Globus download is recommended for selections ${gsize} and larger.`
    );
  } else if (fileSummary?.totalSizeInBytes > warningSizeByte) {
    const wsize = warningSizeInFF
      ? `${warningSizeInFF}GB`
      : filesize(warningSizeByte, { round: 1 });
    infoTxt.push(
      `Browser download is not recommended for selections ${wsize} and larger.`
    );
  }

  if (fileSummary?.purgedCount > 0) {
    infoTxt.push(
      "Some files must be restored from our tape archive before they can be downloaded."
    );
  }

  const getTipLi = (arr) => {
    const html = [];
    arr.forEach((t) => {
      html.push(<li key={t}> {t} </li>);
    });

    return html;
  };

  const warningTip =
    infoTxt.length > 0 ? (
      <HtmlTooltip title={<ul>{getTipLi(infoTxt)}</ul>}>
        <WarningIcon
          style={{ color: classes.orange600 }}
          className={classes.icon}
        />
      </HtmlTooltip>
    ) : fileSummary ? (
      <CheckIcon style={{ color: classes.moss500 }} className={classes.icon} />
    ) : null;

  useEffect(() => {
    if (fileSummary?.totalSizeInBytes > globusSizeByte) {
      setAppMessageID(GLOBUS_DOWNLOAD_SIZE_INFO);
    } else if (fileSummary?.totalSizeInBytes > warningSizeByte) {
      setAppMessageID(BROWSER_DOWNLOAD_SIZE_INFO);
    } else {
      setAppMessageID(GLOBUS_DOWNLOAD_SIZE_INFO, false);
      setAppMessageID(BROWSER_DOWNLOAD_SIZE_INFO, false);
    }
    if (fileSummary?.purgedCount > 0) {
      setAppMessageID(TAPE_FILE_INFO);
    } else {
      setAppMessageID(TAPE_FILE_INFO, false);
    }
  }, [
    fileSummary?.totalSizeInBytes,
    fileSummary?.purgedCount,
    setAppMessageID,
  ]);

  const genomeInfo = fileSummary ? (
    <>
      <Typography
        variant="h5"
        data-testid="file-stats-genomes-selected"
      >{`${fileSummary?.organismCount} `}</Typography>
      <Typography variant="subtitle2">
        {` genome${fileSummary?.organismCount !== 1 ? "s " : " "}`}
      </Typography>
      <Typography variant="subtitle2">{" | "}</Typography>
    </>
  ) : null;

  const ui = imgBulk ? (
    <Box className={`${classes.root} DownloaderPanel`} data-testid="file-stats">
      {imgBulk.file_count && imgBulk.size ? (
        <>
          <Typography variant="subtitle2"> file count </Typography>
          <Typography variant="h5">{imgBulk.file_count}</Typography>
          <Typography variant="subtitle2"> | </Typography>
          <Typography variant="subtitle2"> size </Typography>
          <Typography variant="h5">
            {filesize(imgBulk.size, {
              round: 1,
            })}
          </Typography>
        </>
      ) : (
        <Typography variant="subtitle2">
          Received wrong data from JDP API server
        </Typography>
      )}
    </Box>
  ) : (
    <Box className={`${classes.root} DownloaderPanel`} data-testid="file-stats">
      {(cart || restoreid) && warningTip}
      {genomeInfo}
      <Typography variant="h5" data-testid="file-stats-files-selected">
        {fileSummary ? `${fileSummary.totalCount} ` : "0"}
      </Typography>
      <Typography variant="subtitle2">
        {` file${fileSummary && fileSummary.totalCount === 1 ? "" : "s "} ${
          cart ? "" : "selected"
        }`}
      </Typography>

      {fileSummary ? <Typography variant="subtitle2"> | </Typography> : null}
      {fileSummary ? (
        <Typography variant="h5" data-testid="file-stats-size-info">
          {sizeInfo}
        </Typography>
      ) : null}
    </Box>
  );

  return ui;
};

export default FileStats;

FileStats.defaultProps = {
  fileSummary: null,
  ldClient: null,
  cart: false,
  imgBulk: null,
};

FileStats.propTypes = {
  fileSummary: PropTypes.shape(),
  setAppMessageID: PropTypes.func.isRequired,
  ldClient: PropTypes.shape({
    variation: PropTypes.func,
  }),
  cart: PropTypes.bool,
  imgBulk: PropTypes.shape(),
};
