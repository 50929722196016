import React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import WarningIcon from "@material-ui/icons/Warning";
import PropTypes from "prop-types";

import {
  TT_RED500,
  TT_LAKE500,
  TT_ORANGE600,
  TT_MAP,
} from "../../Dialogs/DraggableDialog/DraggableDialog";

import classes from "../../Dialogs/DraggableDialog/DraggableDialog.module.css";
import DialogFooter, { DF_BTN_WARNING } from "../../Dialogs/DialogFooter";
import ModalContentBase from "../ModalContentBase";

import filestatsclasses from "../../../Downloader/FileStats/FileStats.module.css";

export const DLG_WARNING = "DLG_WARNING";
export const DLG_INFO = "DLG_INFO";
export const DLG_ERROR = "DLG_ERROR";

const getDlgTopType = (atype) => {
  switch (atype) {
    case DLG_WARNING:
      return TT_ORANGE600;
    case DLG_ERROR:
      return TT_RED500;
    default:
      return TT_LAKE500;
  }
};
function NoDraggableDialog({
  type,
  children, // appear in the main colored body with warning icon
  subcontent, // appear below the main body
  title,
  acceptBtnText,
  acceptBtnFn,
  cancelBtnText,
  cancelBtnFn,
  cancelCrossFn, // the cross icon click handler
  doNotShowAgainCheckFn,
  cancelBtnDisabled, // show disabled button, if has BtnText
  acceptBtnDisabled, //
  btnColor,
  width,
  height,
  contentHeight,
}) {
  const ttype = getDlgTopType(type); // top type
  const tCSS = TT_MAP[ttype];
  const dlgCSS = { ...tCSS };

  if (width) {
    dlgCSS.maxWidth = width;
    dlgCSS.minWidth = width;
  }

  if (height) {
    dlgCSS.maxHeight = height;
    dlgCSS.minHeight = height;
  }

  const contentCSS = {};
  if (contentHeight) {
    contentCSS.overflow = "auto";
    contentCSS.height = contentHeight;
  }

  const tableCSS = {
    backgroundColor: type === DLG_WARNING ? classes.orange50 : "inherit",
    padding: "10px",
  };

  return (
    <Dialog
      open
      aria-labelledby="dialog-title"
      PaperProps={{
        sx: { ...dlgCSS },
      }}
    >
      <DialogContent className={classes.modalContent}>
        <ModalContentBase titleText={title} closeFn={cancelCrossFn}>
          {children && (
            <div style={contentCSS}>
              <table style={tableCSS}>
                <tbody>
                  <tr>
                    {type === DLG_WARNING ? (
                      <td style={{ verticalAlign: "top" }}>
                        <WarningIcon
                          style={{ color: classes.orange600 }}
                          className={filestatsclasses.icon}
                        />
                      </td>
                    ) : null}

                    <td
                      style={{ paddingLeft: 10, fontSize: 20, minWidth: 460 }}
                    >
                      {children}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {subcontent}
        </ModalContentBase>
      </DialogContent>

      {cancelBtnFn || acceptBtnFn ? (
        <DialogActions>
          <DialogFooter
            btnColor={btnColor}
            cancelBtnText={cancelBtnText}
            cancelBtnFn={cancelBtnFn}
            acceptBtnText={acceptBtnText}
            acceptBtnFn={acceptBtnFn}
            className={classes.footer}
            cancelBtnDisabled={cancelBtnDisabled}
            acceptBtnDisabled={acceptBtnDisabled}
            checkBtnText={
              doNotShowAgainCheckFn ? "Do not show this again." : null
            }
            checkBtnFn={doNotShowAgainCheckFn}
          />
        </DialogActions>
      ) : (
        <span style={{ height: "26px" }}>
          <br />
        </span>
      )}
    </Dialog>
  );
}
export default NoDraggableDialog;

NoDraggableDialog.defaultProps = {
  title: "Confirm Dialog",
  subcontent: null,
  cancelBtnText: null,
  acceptBtnText: null,
  acceptBtnFn: null,
  cancelBtnFn: null,
  cancelCrossFn: null,
  doNotShowAgainCheckFn: null,
  cancelBtnDisabled: false, // ?
  acceptBtnDisabled: false, // ?
  btnColor: DF_BTN_WARNING,
  width: null,
  height: null,
  contentHeight: null,
  type: DLG_WARNING,
  children: null,
};

NoDraggableDialog.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  subcontent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  acceptBtnText: PropTypes.string,
  acceptBtnFn: PropTypes.func,
  cancelBtnText: PropTypes.string,
  cancelBtnFn: PropTypes.func,
  cancelCrossFn: PropTypes.func,
  cancelBtnDisabled: PropTypes.bool,
  acceptBtnDisabled: PropTypes.bool,
  doNotShowAgainCheckFn: PropTypes.func,
  btnColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  contentHeight: PropTypes.number,
  type: PropTypes.string,
};
