import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";

import { Select, MenuItem } from "@material-ui/core";
import { useParams } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import theme from "../BentoBox.module.css";

import NewSearchWithSelectionDlg from "../../SearchAutocomplete/Dialogs/NewSearchWithSelectionDlg";

const iconStyles = {
  selectIcon: {
    display: "flex",
    fontSize: "27px",
    color: theme.lake600,
    alignItems: "center",
    margin: "0 15px",
  },
};

const CustomExpandMore = withStyles(iconStyles)(
  ({ className, classes, ...rest }) => {
    return (
      <KeyboardArrowDownIcon
        {...rest}
        className={clsx(className, classes.selectIcon)}
      />
    );
  }
);

const useStyles = makeStyles(() => ({
  MuiList: {
    margin: "12px 0",
  },
  MuiMenu: {
    margin: "9px 0",
  },
}));

const SegmentSelect = ({
  updateUrlSegment,
  className,
  honeycomb,
  options,
  setDividerVisibility,
  addToCartHandler,
  withSelections,
  disabled,
}) => {
  const { segment } = useParams();
  const myStyles = useStyles();

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(options[0].value);

  useEffect(() => {
    const { pathname } = window.location;
    let selValue = options[0].value;

    for (let i = 0; i < options.length; i++) {
      const { value: val } = options[i];
      if (pathname.indexOf(val) !== -1) {
        selValue = val;
        break;
      }
    }
    setValue(selValue);
  }, []);

  const onSegmentChange = (newSegment) => {
    honeycomb.sendUiInteractionSpan(
      `clicked-navigate-to-segment-${newSegment}`
    );
    updateUrlSegment(newSegment);
  };

  const [dialogConf, setDialogConf] = useState(null);

  const handleClose = () => {
    setDividerVisibility(true);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setDividerVisibility(false);
  };

  const handleChange = (e) => {
    const newSegment = e.target.value.toString();
    if (newSegment !== null && withSelections && addToCartHandler) {
      setDialogConf({ type: true, value: newSegment });
    } else {
      onSegmentChange(newSegment);
    }
  };

  const dialog = withSelections && addToCartHandler && dialogConf && (
    <NewSearchWithSelectionDlg
      addToCartAndProceedBtnFn={() => {
        onSegmentChange(dialogConf.value);
        setDialogConf(null);
        addToCartHandler();
      }}
      crossDismissBtnFn={() => {
        setDialogConf(null);
      }}
      dropFilesANdProceedBtnFn={() => {
        onSegmentChange(dialogConf.value);
        setDialogConf(null);
      }}
    />
  );

  return (
    <>
      <Select
        disabled={disabled}
        value={value}
        onMouseEnter={() => {
          setDividerVisibility(false);
        }}
        onMouseLeave={() => {
          setDividerVisibility(true);
        }}
        onMouseDown={() => {
          setDividerVisibility(false);
        }}
        onFocus={() => {
          setDividerVisibility(!open);
        }}
        onBlur={() => {
          setDividerVisibility(true);
        }}
        className={`${className} ${open ? theme.listOpen : ""}`}
        id="Kingdom"
        defaultValue={segment}
        label="everything"
        disableUnderline
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
        MenuProps={{
          id: "BentoBoxSegmentMenu",
          className: myStyles.MuiMenu,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          getContentAnchorEl: null,
          MenuListProps: { disablePadding: true, className: myStyles.MuiList },
        }}
        IconComponent={CustomExpandMore}
      >
        {options.map((op) => (
          <MenuItem value={op.value} key={op.label}>
            {op.label}
          </MenuItem>
        ))}
      </Select>
      {dialog}
    </>
  );
};

export default SegmentSelect;

SegmentSelect.defaultProps = {
  honeycomb: {
    sendUiInteractionSpan: () => {},
  },
  className: "",
  setDividerVisibility: () => {},
  updateUrlSegment: () => {},
  addToCartHandler: null,
  withSelections: null,
  disabled: false,
};

SegmentSelect.propTypes = {
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
  }),
  className: PropTypes.string,
  setDividerVisibility: PropTypes.func,
  options: PropTypes.arrayOf(Object).isRequired,
  updateUrlSegment: PropTypes.func,
  addToCartHandler: PropTypes.func,
  withSelections: PropTypes.bool,
  disabled: PropTypes.bool,
};
