/* istanbul ignore file */
import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { Link, makeStyles } from "@material-ui/core";

import Card, { BUTTON_TYPE_LINK } from "../Card/Card";
import classes from "./HomePage.module.css";
import {
  HREF_DOC_HOW_TO_SEARCH,
  HREF_DOC_ROOT,
} from "../../utils/URLConstants";

const useStyles = makeStyles(() => ({
  root: {
    padding: "24px 24px",
  },
}));

const MiddleCards = ({ honeycomb }) => {
  const muiClasses = useStyles();

  const cardSearch = (
    <>
      Search metadata for over 13 PB of top-quality plant, algal, fungal, and
      microbial genomic and metagenomic data.
      <br />
      <br />
      Our enhanced search includes typeahead for increased precision, as well as
      robust filters that will help you find only the data you’re looking for,
      regardless of your research area.
    </>
  );

  const linkClass = `${classes.bodyBase} ${classes.linkInCard}`;
  const getLinkComp = (label, href) => {
    return (
      <Link
        className={linkClass}
        href={href}
        target="_blank"
        onClick={() => {
          if (honeycomb) {
            honeycomb.sendUiInteractionSpan("homepage-link-click", {
              link: `${label}/${href}`,
            });
          }
        }}
      >
        {label}
      </Link>
    );
  };
  const cardDownload = (
    <>
      Once you’ve found the data you need, download it via browser or using a
      custom-generated curl command.
      <br />
      <br />
      Interested in automated searches or bulk downloads? Check out our{" "}
      {getLinkComp(
        "API Tutorial",
        "https://sites.google.com/lbl.gov/data-portal-help/home/tips_tutorials/api-tutorial"
      )}{" "}
      to create custom scripts.
    </>
  );

  const cardAnalyze = (
    <>
      Access JGI’s most popular — and powerful — analysis tools, including{" "}
      {getLinkComp(
        "IMG’s gene search",
        "https://img.jgi.doe.gov/cgi-bin/mer/main.cgi?section=GeneSearch&page=searchForm"
      )}
      ,{" "}
      {getLinkComp(
        "BLAST search",
        "https://img.jgi.doe.gov/cgi-bin/mer/main.cgi?section=FindGenesBlast&page=geneSearchBlast"
      )}
      , and{" "}
      {getLinkComp(
        "function finder",
        "https://img.jgi.doe.gov/cgi-bin/mer/main.cgi?section=FindFunctions&page=findFunctions"
      )}
      ,{" "}
      {getLinkComp(
        "Phytozome’s BLAST search",
        "https://phytozome-next.jgi.doe.gov/blast-search"
      )}{" "}
      and{" "}
      {getLinkComp("BioMart", "https://phytozome-next.jgi.doe.gov/biomart/")},
      and{" "}
      {getLinkComp(
        "MycoCosm/PhycoCosm’s analysis tools",
        "https://mycocosm.jgi.doe.gov/fungi/fungi.info.html"
      )}
      : BLAST search, the annotation finder, MCL clusters, and more.
    </>
  );

  const cardLearn = (
    <>
      Troubleshoot common issues with {getLinkComp("our FAQ", HREF_DOC_ROOT)},
      delve into our{"  "}
      {getLinkComp(
        "Data Usage policy",
        "https://jgi.doe.gov/user-programs/pmo-overview/policies/legacy-data-policies/"
      )}
      , and get more information about{" "}
      {getLinkComp(
        "submitting proposals to JGI",
        "https://proposals.jgi.doe.gov/"
      )}
      .
    </>
  );

  const topRowH = 370;
  const bottomRowH = 260;
  return (
    <>
      <div className={classes.middleCards}>
        <Grid className={muiClasses.root} container spacing={6}>
          <Grid item md={6} xs={12}>
            <Card
              height={topRowH}
              title="Search"
              content={cardSearch}
              button={{
                type: BUTTON_TYPE_LINK,
                href: HREF_DOC_HOW_TO_SEARCH,
                newtab: true,
                label: "Learn more about search",
                honeykey: "homepage-link-click",
                honeycomb,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Card
              height={topRowH}
              title="Download"
              content={cardDownload}
              button={{
                type: BUTTON_TYPE_LINK,
                href: "https://files.jgi.doe.gov/apidoc/",
                newtab: true,
                label: "View our API docs",
                honeykey: "homepage-link-click",
                honeycomb,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Card height={bottomRowH} title="Analyze" content={cardAnalyze} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Card
              height={bottomRowH}
              title="Learn"
              content={cardLearn}
              button={{
                type: BUTTON_TYPE_LINK,
                href: HREF_DOC_ROOT,
                newtab: true,
                label: "Browse the FAQ",
                honeykey: "homepage-link-click",
                honeycomb,
              }}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default MiddleCards;

MiddleCards.defaultProps = {
  honeycomb: null,
};

MiddleCards.propTypes = {
  honeycomb: PropTypes.shape(),
};
