import React, { useContext, useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { useHistory } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import SettingsIcon from "@mui/icons-material/Settings";

import Button from "../../Button/Button";
import classes from "./FilterToggle.module.css";
import ConfigContext from "../../../context/ConfigContext";
import { useSearchParams } from "../../../hooks/useSearchParams";

const GearIconWithBadge = ({ activeCnt }) => {
  /**
   * The mui badge has hover zone to the top extended way beyond the visual boundary, interferes with
   * components above it.
   * The solution is to construct a composed custom component using legacy html tags.
   */
  const badgeSize = 22; // circle size
  const yShift = 2; // icon group (Gear + badge) vertical position; > 0 => move down
  const badgeStyle = {
    position: "relative",
    right: -19,
    top: -56 + yShift,
    width: badgeSize,
    height: badgeSize,
    backgroundColor: "#FFF",
    border: "1px solid #0F9AB7",
    borderRadius: badgeSize * 0.5,
    verticalAlign: "middle",
    visibility: activeCnt > 0 ? "visible" : "hidden",
  };
  const badgeLabelTextStyle = {
    position: "relative",
    top: -2,
    fontFamily: "Public Sans",
    fontSize: 14,
    fontWeight: 600,
    color: "#000",
  };

  return (
    <>
      <div style={{ flexDirection: "column", paddingRight: 16, height: 48 }}>
        <div>
          <SettingsIcon
            style={{
              position: "relative",
              top: 6 + yShift,
            }}
            sx={{ fontSize: "28px" }}
          />
        </div>
        <div>
          <SettingsIcon
            style={{ position: "relative", left: 16, top: -12 + yShift }}
            sx={{ fontSize: "18px" }}
          />
        </div>
        <div style={badgeStyle}>
          <span style={badgeLabelTextStyle}>{activeCnt} </span>
        </div>
      </div>
    </>
  );
};
GearIconWithBadge.propTypes = {
  activeCnt: PropTypes.number.isRequired,
};

const FilterToggle = ({
  filterExpanded,
  setFilterExpanded,
  honeycomb,
  disabled,
  // ldClient,
}) => {
  const history = useHistory();
  const { searchParams, FILE_NAME_REGX_KEY } = useSearchParams();
  const { organismFilters, fileFilters, goldFilters } =
    useContext(ConfigContext);
  let filterConfig = organismFilters
    ? organismFilters.concat(fileFilters)
    : fileFilters;

  if (filterConfig && goldFilters) {
    filterConfig = filterConfig.concat(goldFilters);
  }

  /**
   * Count the number of filters in the URL. Handles the possiblity
   * of multiple comma-seperated values in each filter.
   * @param {*} urlParams - URL Search Params to count
   * @returns - Number of filters currently in the URL
   */
  const countFilters = (urlParams) => {
    let count = 0;

    for (let i = 0, len = filterConfig.length; i < len; i++) {
      const config = filterConfig[i];

      const urlFilter = urlParams.get(config.filterId);

      if (urlFilter) {
        const filterVals = urlFilter.split(",");
        count += filterVals.length;
      }
    }
    if (urlParams.get(FILE_NAME_REGX_KEY)) {
      count += 1;
    }

    return count;
  };

  const [filterCount, setFilterCount] = useState(countFilters(searchParams));

  // re-count filters when URL changes
  useEffect(() => {
    return history.listen((loc) => {
      const urlParams = new URLSearchParams(loc.search);
      setFilterCount(countFilters(urlParams));
    });
  }, [history]);

  const onToggleClick = () => {
    honeycomb.sendUiInteractionSpan(
      `${filterExpanded ? "collapsed" : "expanded"}-filter-toggle`
    );

    setFilterExpanded(!filterExpanded);
  };

  return (
    <Box className={classes.roundBox}>
      <Button
        className={`${classes.toggleButton} ${classes.roundButton}`}
        size="small"
        color="secondary"
        onClick={onToggleClick}
        disabled={disabled}
        style={disabled ? { backgroundColor: classes.grey100 } : {}}
        classes={{ root: classes.labelText }}
      >
        <>
          <Typography
            className={`${classes.buttonLabelText} ${classes.actionTextSmall}`}
          >
            {filterExpanded ? "Hide Filters" : "Show Filters"}
          </Typography>
          <GearIconWithBadge activeCnt={filterCount} />
        </>
      </Button>
    </Box>
  );
};

export default FilterToggle;

FilterToggle.defaultProps = {
  filterExpanded: false,
  honeycomb: {
    sendUiInteractionSpan: () => {},
  },
  setFilterExpanded: () => {},
  disabled: false,
};

FilterToggle.propTypes = {
  filterExpanded: PropTypes.bool,
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
  }),
  setFilterExpanded: PropTypes.func,
  disabled: PropTypes.bool,
  // ldClient: PropTypes.shape().isRequired,
};
