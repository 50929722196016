import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Alert } from "@material-ui/lab";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";
import DoneIcon from "@material-ui/icons/Done";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import Link from "@mui/material/Link";

import classes from "../Alert.module.css";

import {
  HREF_DOC_ARCHIVED,
  HREF_DOC_DOWNLOAD_DAILY_LIMITS,
  HREF_DOC_CURL_DOWNLOAD,
  HREF_DOC_GLOBUS_AND_HOW,
} from "../../../utils/URLConstants";

// import { base as ldbase } from "../../../utils/LDFFUtils";
import { useSearchParams } from "../../../hooks/useSearchParams";

export const GLOBUS_DOWNLOAD_SIZE_INFO = "GLOBUS_DOWNLOAD_SIZE_INFO";
export const BROWSER_DOWNLOAD_SIZE_INFO = "BROWSER_DOWNLOAD_SIZE_INFO";
export const TAPE_FILE_INFO = "TAPE_FILE_INFO";

// TODO : should live in InfoBoard?
export const RESTORE_WILL_OVER_USER_QUOTA = "RESTORE_WILL_OVER_USER_QUOTA";
export const RESTORE_OVER_USER_QUOTA = "RESTORE_OVER_USER_QUOTA";
export const RESTORE_NEAR_USER_QUOTA = "RESTORE_NEAR_USER_QUOTA";
export const RESTORE_DISK_SPACE = "RESTORE_DISK_SPACE";

export const ALERT_TYPE_ERROR = "error";
export const ALERT_TYPE_WARN = "warning";
export const ALERT_TYPE_INFO = "info";
export const ALERT_TYPE_SUCCESS = "success";

const PageAlert = ({ atype, variant, mid, message }) => {
  const [show, setShow] = useState(true);
  const iprops = { severity: atype, variant };

  const { cart: cartpage } = useSearchParams();

  const INFO_MSG = {
    BROWSER_DOWNLOAD_SIZE_INFO: (
      <>
        For downloads of this size, we recommend that you use either our command
        line, or Globus download options. Please{" "}
        <Link
          // href={faqAPI(`${HREF_DOC_CURL_DOWNLOAD},${HREF_DOC_GLOBUS_AND_HOW}`)}
          href={HREF_DOC_CURL_DOWNLOAD}
          target="_blank"
          style={{ margin: "0px 4px" }}
          rel="noreferrer"
        >
          click here
        </Link>{" "}
        to learn more about this.
      </>
    ),
    GLOBUS_DOWNLOAD_SIZE_INFO: (
      <>
        For downloads of this size, we recommend that you use our Globus
        download option. Please{" "}
        <Link
          href={HREF_DOC_GLOBUS_AND_HOW}
          target="_blank"
          style={{ margin: "0px 4px" }}
        >
          click here
        </Link>{" "}
        to learn more about this.
      </>
    ),
    TAPE_FILE_INFO: (
      <>
        We notice that you&apos;ve selected some{" "}
        <Link
          href={HREF_DOC_ARCHIVED}
          target="_blank"
          style={{ margin: "0px 4px" }}
        >
          archived files,
        </Link>
        which means that the delivery of all of your requested files might be
        delayed up to 24 hours.
      </>
    ),

    RESTORE_WILL_OVER_USER_QUOTA:
      "We're unable to process your request — restoring these files would cause you to exceed your daily download limit.",
    RESTORE_OVER_USER_QUOTA:
      "You've reached your daily download limit. Please return tomorrow to make this request again.",
    RESTORE_NEAR_USER_QUOTA:
      "You're approaching your daily file request limit. Learn more about download limits.",
    RESTORE_DISK_SPACE:
      "Our servers can't process your request right now — please try again later.",
  };

  if (cartpage && show && mid) {
    let extra = null;
    if ([RESTORE_WILL_OVER_USER_QUOTA, RESTORE_NEAR_USER_QUOTA].includes(mid)) {
      extra = (
        <Link href={HREF_DOC_DOWNLOAD_DAILY_LIMITS} target="_blank">
          Learn more about download limits.
        </Link>
      );
    }

    const icon = (alert_type) => {
      if (alert_type === ALERT_TYPE_WARN) {
        return <WarningIcon style={{ color: classes.orange600 }} />;
      }
      if (alert_type === ALERT_TYPE_INFO) {
        return <InfoIcon style={{ color: classes.lake500 }} />;
      }
      if (alert_type === ALERT_TYPE_SUCCESS) {
        return <DoneIcon style={{ color: classes.moss500 }} />;
      }
      if (alert_type === ALERT_TYPE_ERROR) {
        return <ErrorIcon style={{ color: classes.red500 }} />;
      }
    };

    return (
      <div>
        <Alert
          icon={icon(atype)}
          className={classes.infoBar}
          {...iprops}
          action={
            <CloseIcon
              data-testid="alert-close-icon"
              style={{ cursor: "pointer" }}
              title="dismiss"
              onClick={() => {
                setShow(false);
              }}
            />
          }
        >
          {message || <span className={classes.infoText}>{INFO_MSG[mid]}</span>}
          {extra}
        </Alert>
      </div>
    );
  }
  return null;
};

export default PageAlert;

PageAlert.defaultProps = {
  atype: ALERT_TYPE_WARN,
  variant: "filled",
  mid: BROWSER_DOWNLOAD_SIZE_INFO,
  message: null,
  callback: null,
  data: null,
  ldClient: null,
};

PageAlert.propTypes = {
  atype: PropTypes.string,
  variant: PropTypes.string,
  mid: PropTypes.string,
  message: PropTypes.node,
  callback: PropTypes.func,
  data: PropTypes.string,
  ldClient: PropTypes.shape(),
};
