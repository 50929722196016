// ## long-lived FFs
import timeUtils from "./TimeUtils";

const base = (ldClient, keyname, defVal = null) => {
  if (!ldClient) return defVal;
  return ldClient?.variation(keyname, defVal);
};

const outageWarning = (ldClient) => {
  return base(ldClient, "outage-warning", "OFF");
};

const plannedNERSCOutageDowntime = (ldClient) => {
  return base(ldClient, "planned-nersc-outage-downtime", "OFF");
};

const genericOutage = (ldClient) => {
  return base(ldClient, "outage-mode");
};

const genericIssue = (ldClient) => {
  const data = base(ldClient, "unexpected-issues");
  if (Array.isArray(data) && data.length > 0) {
    return data[0]; // the 1st element is currently active
  }
  return data;
};

const _previewer = (data, viewer) => {
  // helper - if viewer ( email ) in data.previewer
  // console.log("[_previewer]", data, viewer);
  if (data.previewer && viewer) {
    const viewerList = data.previewer.split(","); // csv of user email
    return viewerList.includes(viewer);
  }
  return false;
};

const allGenericIssue = (ldClient, email) => {
  let data = base(ldClient, "unexpected-issues");

  if (Array.isArray(data) && data.length > 0) {
    const msg = [];
    data.forEach((d) => {
      if (d.message) {
        let show = d.show !== false; // backward compatible, not explicitly disabled
        show = show && timeUtils.currentTimeIsIn(d.start, d.end);
        if (show || _previewer(d, email)) {
          msg.push(d);
        }
      }
    });

    data = msg;
  }
  return data;
};

// return the browser-download-size.globus_download_size value (Int) from FF
const globusDownloadSize = (ldClient) => {
  // const sizeff = ldClient?.variation("browser-download-size", null);
  const sizeff = base(ldClient, "browser-download-size", null);
  return sizeff?.globus_download_size &&
    !Number.isNaN(parseFloat(sizeff.globus_download_size, 10))
    ? parseFloat(sizeff.globus_download_size, 10)
    : null;
};

// ==========================

const browserDownloadWarningSize = (ldClient) => {
  // const sizeff = ldClient?.variation("browser-download-size", null);
  const sizeff = base(ldClient, "browser-download-size", null);

  return sizeff?.web_download_warning_limit &&
    !Number.isNaN(parseFloat(sizeff.web_download_warning_limit, 10))
    ? parseFloat(sizeff.web_download_warning_limit, 10)
    : null;
};

const keycloakff = (ldClient) => {
  return base(ldClient, "dual-authentication");
};

const delightedSurvey = (ldClient) => {
  return base(ldClient, "delighted-survey", {});
};

const dupVersion = (ldClient) => {
  // const dupVerJSON = ldClient?.variation("dup-version", {});
  const dupVerJSON = base(ldClient, "dup-version", {});
  return dupVerJSON?.version ? dupVerJSON.version : "1.0";
};

const announcements = (ldClient, email) => {
  const data = base(ldClient, "announcements");

  const activeData = [];
  if (data?.list && Array.isArray(data.list) && data.list.length > 0) {
    data.list.forEach((d) => {
      if (d.message) {
        let show = d.show !== false; // backward compatible, not explicitly disabled
        show = show && timeUtils.currentTimeIsIn(d.start, d.end);
        const preview = _previewer(d, email);
        if (show || preview) {
          if (preview) {
            d.previewer = true;
          } else {
            delete d.previewer;
          }
          activeData.push(d);
        }
      }
    });
  }
  return activeData;
};

const debuglog = (ldClient) => {
  return base(ldClient, "fe-debug-log");
};

export {
  base,
  globusDownloadSize,
  browserDownloadWarningSize,
  outageWarning,
  keycloakff,
  delightedSurvey,
  plannedNERSCOutageDowntime,
  genericOutage,
  dupVersion,
  genericIssue,
  allGenericIssue,
  announcements,
  debuglog,
};
