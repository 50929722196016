import React, { Component } from "react";

import { PropTypes } from "prop-types";

import { openCollectorDialog } from "../Footer/ContactUs/ContactUs";
import InfoMessage, { MSG_SYSTEM_OUTAGE } from "../UI/InfoMessage/InfoMessage";

import ErrorMessage, {
  ERR_DATA,
  ERR_NOT_FOUND,
  ERR_NERSC_POWER_OUTAGE,
  ERR_SYSTEM_OUTAGE,
  ERR_API_CALL,
  ERR_NETWORK,
  ERR_FILTER_RECORDS,
} from "../UI/ErrorMessage/ErrorMessage";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, goHome: false };
  }

  componentDidCatch(error) {
    this.setState({
      error,
    });
  }

  handleContactUs = () => {
    const { honeycomb } = this.props;
    honeycomb.sendUiInteractionSpan("clicked-contact-us-button");
    openCollectorDialog();
  };

  handleReturnHome = () => {
    const { honeycomb } = this.props;
    honeycomb.sendUiInteractionSpan("clicked-return-home-button");
    this.setState({ error: false, goHome: true });
  };

  render() {
    const { error, goHome } = this.state;
    if (goHome) {
      window.location.assign("/");
    }

    if (error) {
      const { honeycomb } = this.props;
      const errorMessage = error.message;

      // eslint-disable-next-line no-console
      console.warn(errorMessage, "[ErrorB] render");

      if (errorMessage === ERR_NOT_FOUND) {
        return (
          <ErrorMessage
            type={ERR_NOT_FOUND}
            accept={this.handleReturnHome}
            honeycomb={honeycomb}
          />
        );
      }

      if (errorMessage === ERR_NERSC_POWER_OUTAGE) {
        const { ldClient } = this.props;
        const downtime = ldClient.variation(
          "planned-nersc-outage-downtime",
          "OFF"
        );
        return (
          <ErrorMessage
            type={ERR_NERSC_POWER_OUTAGE}
            downtime={downtime}
            honeycomb={honeycomb}
          />
        );
      }

      if (errorMessage === ERR_SYSTEM_OUTAGE) {
        if (error.text === undefined) {
          return (
            <ErrorMessage type={ERR_SYSTEM_OUTAGE} honeycomb={honeycomb} />
          );
        }

        return (
          <InfoMessage
            type={MSG_SYSTEM_OUTAGE}
            text={error.text}
            honeycomb={honeycomb}
          />
        );
      }

      if (errorMessage === ERR_API_CALL) {
        return (
          <ErrorMessage
            type={ERR_API_CALL}
            cancel={this.handleContactUs}
            accept={this.handleReturnHome}
            honeycomb={honeycomb}
          />
        );
      }

      if (errorMessage === ERR_FILTER_RECORDS) {
        return (
          <ErrorMessage
            type={ERR_FILTER_RECORDS}
            text={error.text}
            cancel={this.handleContactUs}
            accept={this.handleReturnHome}
            honeycomb={honeycomb}
          />
        );
      }

      if (errorMessage === ERR_NETWORK) {
        return (
          <ErrorMessage
            type={ERR_NETWORK}
            cancel={this.handleContactUs}
            accept={this.handleReturnHome}
            honeycomb={honeycomb}
          />
        );
      }

      // default error
      return (
        <ErrorMessage
          type={ERR_DATA}
          cancel={this.handleContactUs}
          accept={this.handleReturnHome}
          honeycomb={honeycomb}
        />
      );
    }

    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;

ErrorBoundary.defaultProps = {
  honeycomb: {
    sendUiInteractionSpan: () => {},
  },
  ldClient: null,
};

ErrorBoundary.propTypes = {
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
  }),
  ldClient: PropTypes.shape({
    variation: PropTypes.func,
  }),
  children: PropTypes.element.isRequired,
};
