/* istanbul ignore file */
import { useState } from "react";
import { useQuery } from "react-query";
import httpclient from "../utils/HttpClientProvider";
import { useConfigContext } from "../context/ConfigContext";

import { useApiUtil } from "./useApiUtil";
import { debuglog } from "../utils/LDFFUtils";

export const useApiCustom = (honeycomb) => {
  const { dataUrl } = useConfigContext();

  /* eslint-disable-next-line no-unused-vars */
  const [queryError, setQueryError] = useState(null);

  const { restoreid, params, preProcessData } = useApiUtil();
  const allowedMethods = ["get", "post"];
  const useGenomeQuery = (custom, ldClient = null) => {
    const { customURL, method, payload } = custom;

    const lowerMethod = method.toLowerCase();
    if (!allowedMethods.includes(lowerMethod)) {
      return {
        data: null,
        isLoading: false,
        isFetchingNextPage: false,
        hasNextPage: false,
      };
    }

    // Use restore requst url if we have a restore id
    const url =
      customURL ||
      (restoreid
        ? `/request_archived_files/requests/${restoreid}?api_version=2`
        : dataUrl);

    const endpoint = url.split("?")[0].replace(/\//g, ""); // search, img_file_list, mycocosm_file_list, phytozome_file_list
    const debug = ldClient ? debuglog(ldClient) : null;
    const endpointFF =
      debug?.endpoint && debug.endpoint[endpoint]
        ? debug.endpoint[endpoint]
        : null;

    // useQuery will run if params and/or url changes
    return useQuery(
      ["allData", params, url, payload],
      async () => {
        const trace = honeycomb.buildTrace(honeycomb.getTraceID());
        const tstart = Date.now();

        let res = null;

        if (lowerMethod === "get") {
          res = await httpclient.get(url, {
            headers: honeycomb.getTraceHeader(trace),
            params,
          });
        } else if (lowerMethod === "post") {
          res = await httpclient.post(url, payload, {
            headers: honeycomb.getTraceHeader(trace),
          });
        }

        if (res && res.data) {
          if (
            debug?.endpoint?.any?.raw_json?.log ||
            endpointFF?.raw_json?.log
          ) {
            const dataStr = JSON.stringify(res.data);
            // eslint-disable-next-line
            console.log(
              "[FROM BE]",
              debug?.endpoint?.any.raw_json?.str || endpointFF?.raw_json?.str
                ? dataStr
                : res.data,
              dataStr.length
            );
          }
          const hcPayload = {
            status: res.status,
            duration: tstart ? Date.now() - tstart : 0,
            response: {
              file_total: res.data.file_total,
              total: res.data.total,
            },
            params,
          };
          honeycomb.sendAxiosGetSpan(url, hcPayload, trace);
        }

        if (res.error) {
          return {};
        }

        const cleanData = preProcessData(res.data);

        if (
          debug?.endpoint?.any?.fe_page_json?.log ||
          endpointFF?.fe_page_json?.log
        ) {
          const dataStr = JSON.stringify(cleanData);
          // eslint-disable-next-line
          console.log(
            "[PAGE DATA]",
            debug?.endpoint?.any.fe_page_json?.str ||
              endpointFF.fe_page_json.str
              ? dataStr
              : cleanData,
            dataStr.length
          );
        }

        return cleanData;
      },
      {
        onError: (error) => {
          // github.com/facebook/react/issues/14981#issuecomment-468460187
          setQueryError(() => {
            throw new Error(error);
          });
        },
      }
    );
  };

  return {
    useGenomeQuery,
    queryError,
  };
};

export { useApiCustom as default };
