import get from "lodash.get";

import getFileType from "./SpeciesUtils";
import { fileStatusTransform } from "./FileUtils";
/**
 * Sort an array of names in the specified order
 *
 * @param {array} names Genome names to be sorted
 * @param {*} ascending Sort by ascending?
 */
const sortGenomeTableNames = (names, ascending) => {
  if (!names) {
    return [];
  }
  const direction = ascending ? 1 : -1;
  return [...names].sort((a, b) => (a.name > b.name ? direction : -direction));
};

/**
 * Sort the rows in the specified order
 *
 * @param {array} rows row to be sorted
 * @param {string} primary column to primary sort on
 * @param {boolean} ascending sort in ascending order?
 * @param {string} segment current segment (phytozome, mycocosm, search, ect)
 */
const sortRowsWithFileName = (rows, primary, ascending = true, segment) => {
  const secondary = primary === "file_name" ? undefined : "file_name";
  const unsorted = segment === "img" ? [] : [...rows];
  const tarFiles = [];
  if (segment === "img") {
    // JDP-2282 : for img data, leave tar files on top
    rows.forEach((r) => {
      if (r.file_name.endsWith(".tar.gz")) {
        tarFiles.push(r);
      } else {
        unsorted.push(r);
      }
    });
  }
  const sorted = unsorted.sort((a, b) => {
    let aval;
    let bval;
    // if sorting on file_type, we need to transform the value using the entire row
    if (primary === "file_type") {
      aval = getFileType(a, segment);
      bval = getFileType(b, segment);
      // otherwise establish the base value
    } else {
      aval = get(a, primary);
      bval = get(b, primary);
    }
    // if sorting on file_status, transform the base value
    if (primary === "file_status") {
      aval = fileStatusTransform(aval);
      bval = fileStatusTransform(bval);
      // otherwise check if the base value is an array
    }
    /* now that values have been transformed, make sure they're all strings.
    using numeric: true in localeCompare gives us proper numeric sort with strings */
    aval = aval?.toString();
    bval = bval?.toString();
    // if the primary column values are the same, sort on file_name
    if (aval === bval && secondary) {
      aval = get(a, secondary);
      bval = get(b, secondary);
      return aval.localeCompare(bval, "en", {
        sensitivity: "accent",
        numeric: true,
      });
    }
    // push empty values to the bottom
    if (!aval && !bval) {
      return 0;
    }
    if (!aval) {
      return ascending ? 1 : -1;
    }
    if (!bval) {
      return ascending ? -1 : 1;
    }
    // sort by primary
    return ascending
      ? aval.localeCompare(bval, "en", {
          sensitivity: "accent",
          numeric: true,
        })
      : -aval.localeCompare(bval, "en", {
          sensitivity: "accent",
          numeric: true,
        });
  });

  return tarFiles.concat(sorted);
};

export { sortRowsWithFileName, sortGenomeTableNames };
