import React from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";

const SkeletonDownloader = () => {
  return (
    <Box style={{ display: "flex", width: "100%" }}>
      <Box flexGrow={1}>
        <Skeleton variant="text" animation="wave" height={36} width={156} />
      </Box>
      <Skeleton variant="text" animation="wave" height={36} width={156} />
    </Box>
  );
};

export default SkeletonDownloader;
