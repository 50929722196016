import React from "react";
import PropTypes from "prop-types";

import ConfirmDialog from "../../UI/InfoBoard/Standalone/ConfirmDialog";

const CartIsFullDialog = ({ closeFn }) => {
  return (
    <ConfirmDialog
      title="Files Not Added to Cart"
      acceptTxt="Close"
      acceptBtnFn={closeFn}
      cancelCrossFn={closeFn}
    >
      <div>
        {`You have requested to add files to the cart that will cause the cart's size limit to be exceeded.`}
      </div>

      <div style={{ paddingTop: 20 }}>
        In order to proceed, either download what you currently have in the
        cart, or add a reduced number of files to the cart.
      </div>
    </ConfirmDialog>
  );
};

export default CartIsFullDialog;

CartIsFullDialog.propTypes = {
  closeFn: PropTypes.func.isRequired,
};
