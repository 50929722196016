import React from "react";

import PropTypes from "prop-types";
import { Checkbox, FormControlLabel } from "@material-ui/core/";

import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";

import classes from "./StyledCheckbox.module.css";

const StyledCheckbox = (props) => {
  const { showOutline, iconColor, label, disabled, ...rest } = props;

  const icon = showOutline ? (
    <CheckBoxOutlinedIcon color="primary" className={classes.icon} />
  ) : (
    <CheckBoxOutlineBlankIcon className={classes.icon} />
  );

  const styledCheckbox = (
    <Checkbox
      color="primary"
      variant="text"
      size="medium"
      disabled={disabled}
      checkedIcon={
        <CheckBoxIcon color={iconColor || "primary"} className={classes.icon} />
      }
      icon={icon}
      {...rest}
    />
  );

  if (label) {
    return (
      <FormControlLabel
        classes={{
          label: classes.actionTextSmall,
        }}
        control={styledCheckbox}
        label={label}
      />
    );
  }

  return styledCheckbox;
};

export default StyledCheckbox;

StyledCheckbox.defaultProps = {
  showOutline: false,
  iconColor: null,
  label: null,
  disabled: false,
};
StyledCheckbox.propTypes = {
  showOutline: PropTypes.bool,
  iconColor: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};
