/* istanbul ignore file */
import React, { useEffect, useRef } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import PropTypes from "prop-types";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { LicenseInfo } from "@mui/x-license-pro";
import theme from "./themes/theme";
import AppContainer from "./components/AppContainer/AppContainer";
import withHoneycomb from "./components/HOC/Honeycomb/hoc-with-honeycomb";
import withUser from "./components/HOC/User/hoc-with-user";
import withLaunchdarkly from "./components/HOC/LaunchDarkly/hoc-with-launchdarkly";
import withGARouteUpdating from "./components/HOC/GoogleAnalytics/hoc-with-ga-route-updating";

import FileAndPageProvider from "./components/ContextProviders/FileAndPageProvider";
import ConfigProvider from "./components/ContextProviders/ConfigProvider";

// react-query by default refetches data when the browser is refocused; we disable that
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = (props) => {
  const startTimeRef = useRef(Date.now());
  const { honeycomb, ldClient, ldClientMaster } = props;

  const buildTrace = useRef();
  const sendSpan = useRef();

  const reactQueryDebug = process.env.REACT_APP_QUERY_DEBUG;

  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_KEY);

  useEffect(() => {
    buildTrace.current = honeycomb.buildTrace;
    sendSpan.current = honeycomb.sendSpan;
  }, [honeycomb]);

  useEffect(() => {
    // Create app startup root honeycomb span (event)
    const payload = {
      name: "app-startup",
      service_name: "startup",
      duration: Date.now() - startTimeRef.current,
    };
    const trace = buildTrace.current();
    sendSpan.current(payload, trace);
  }, []);

  if (!ldClientMaster) return null; // Note: could put a loading state page here

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ConfigProvider ldClient={ldClient}>
          <FileAndPageProvider {...props}>
            {/* we wrap all the functional pages with the client provider, so we can access fetched data anywhere */}
            <QueryClientProvider client={queryClient}>
              <AppContainer {...props} />
              {reactQueryDebug && <ReactQueryDevtools />}
            </QueryClientProvider>
          </FileAndPageProvider>
        </ConfigProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default withHoneycomb(
  withUser(withLaunchdarkly(withGARouteUpdating(App)))
);

App.defaultProps = {
  honeycomb: {
    buildTrace: () => {},
    initRootTraceID: () => {},
    sendSpan: () => {},
  },
  ldClient: null,
  ldClientMaster: null,
};
App.propTypes = {
  honeycomb: PropTypes.shape({
    buildTrace: PropTypes.func,
    initRootTraceID: PropTypes.func,
    sendSpan: PropTypes.func,
    sendRoutingSpan: PropTypes.func,
  }),
  ldClient: PropTypes.shape(),
  ldClientMaster: PropTypes.shape(),
};
