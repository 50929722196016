import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem, Button } from "@material-ui/core";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import classes from "../../../themes/theme.module.css";
import Tooltip, { ICON_NONE } from "../../UI/Tooltip/Tooltip";

export const SELECT_ALL_FLAG = 1;
export const CLEAR_ALL_FLAG = 0;

const SelectAllButton = (props) => {
  // State
  const buttonRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);

  const { setSelectedFiles } = props; // SearchPageContainer.setSelectedFiles

  const labelStyle = `${classes.actionTextLarge} ${classes.buttonTextLarge}`;

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    // Close dropdown menu
    setAnchorEl(null);
    return true;
  };

  const handleItemClick = (fn) => {
    setAnchorEl(null);
    fn();
  };

  const handleDropDownClick = (flag) => {
    setAnchorEl(null);
    if (setSelectedFiles) {
      setSelectedFiles(null, false, flag); // SearchPage.setSelectedFiles
    }
  };

  const dropdownItems = [
    {
      label: "Select All Files on Page",
      key: "select-all-on-page",
      id: "select-all-on-page-id",
      click: () => handleDropDownClick(SELECT_ALL_FLAG),
    },
    {
      label: "Clear Selection",
      key: "clear-all-on-page",
      id: "clear-all-on-page-item",
      click: () => handleDropDownClick(CLEAR_ALL_FLAG),
    },
  ];

  const TIP_ON_CHECKLIST_ICON = "TIP_ON_CHECKLIST_ICON";
  const TIP_ON_KEYBOARDARROW_ICON = "TIP_ON_KEYBOARDARROW_ICON";
  const withTooltip = (tipType) => {
    let body = tipType;
    if (tipType === TIP_ON_CHECKLIST_ICON) {
      body = <ChecklistRtlIcon style={{ padding: 0, margin: 0 }} />;
    } else if (tipType === TIP_ON_KEYBOARDARROW_ICON) {
      body = <KeyboardArrowDownIcon style={{ fontSize: 28 }} />;
    }
    return tipType === TIP_ON_CHECKLIST_ICON ? (
      <Tooltip
        title="File selection options"
        iconType={ICON_NONE}
        bottomOffset={-6}
      >
        {body}
      </Tooltip>
    ) : (
      body
    );
  };

  /*
    Menu needs to have anchorEl of the Button element. Wrapping Button with Tooltip breaks that requirement.
    The tooltip can be on elements inside the archor Button to make the Menu works as desired.
  */
  return (
    <>
      <Button
        ref={buttonRef}
        onClick={handleButtonClick}
        variant="contained"
        color="primary"
        size="large"
        className="button"
        startIcon={withTooltip(TIP_ON_CHECKLIST_ICON)}
        endIcon={withTooltip(TIP_ON_KEYBOARDARROW_ICON)}
        style={{
          height: 48,
          margin: "0px 10px",
          padding: "1px 12px",
        }}
        id="select-all-id"
        classes={{ label: labelStyle }}
      >
        <span style={{ opacity: 0.5 }}>|</span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {dropdownItems.map((d) => {
          return (
            <MenuItem
              onClick={() => {
                handleItemClick(d.click);
              }}
              key={d.key}
              id={d.id}
            >
              {d.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default SelectAllButton;

SelectAllButton.defaultProps = {
  setSelectedFiles: null,
};

SelectAllButton.propTypes = {
  setSelectedFiles: PropTypes.func,
};
