/* istanbul ignore file */
import React, { useState } from "react";
import PropTypes from "prop-types";

//---
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";

import FilterMultiSelect from "../Filters/FilterMultiSelect";
import AdvancedFileNameInput from "../Filters/AdvancedFileNameInput";
import { Typography } from "../../../../node_modules/@material-ui/core/index";

import { lake500 } from "../../../themes/theme.module.css";

import Tooltip, { ICON_NONE } from "../../UI/Tooltip/Tooltip";

// Ref : src/components/Card/Card.jsx - box shadow
const shadowStyle = {
  padding: "10px 40px 10px",
  borderBottomRightRadius: 10,
  borderBottomLeftRadius: 10,
  boxShadow: "2px 3px 7px 2px #999",
};
const useStyles = makeStyles(() => ({
  root: shadowStyle,
}));

const FilterGroup = (props) => {
  const {
    label,
    filterConfig,
    data,
    ldClient,
    withDivider,
    open,
    activeCount,
  } = props;

  const muiClasses = useStyles();

  const [expand, setExpand] = useState(open);

  const inputLabelStyle = {
    fontSize: 14,
    fontFamily: "Public Sans",
    fontWeight: 400,
    textAlign: "center",
  };
  const expandIconStyle = { color: lake500, width: 28, height: 28 };

  return filterConfig ? (
    <>
      <ListItemButton
        className={expand ? muiClasses.root : "FilterGroupListItemButton"}
        onClick={() => {
          setExpand(!expand);
        }}
        divider={withDivider}
        sx={{
          backgroundColor: "#F9F9F9",
          // "&:hover": shadowStyle,
          "&:hover": {
            // padding: "10px 40px 10px",
            // borderBottomRightRadius: 10,
            // borderBottomLeftRadius: 10,
            boxShadow: "2px 3px 7px 2px #999",
          },
        }}
        style={{ paddingTop: 12, paddingBottom: 12 }}
      >
        <ListItemText
          primary={
            <Typography
              style={{
                color: "#5C5C5C",
                fontFamily: "Oswald",
                fontSize: 16,
                fontStyle: "normal",
                fontWeight: 700,
              }}
            >
              {label.toUpperCase()}
            </Typography>
          }
        />
        {!expand && activeCount ? (
          <Tooltip
            iconType={ICON_NONE}
            title="Number of filters applied"
            bottomOffset={-2}
          >
            <Avatar
              style={{
                backgroundColor: lake500,
                width: 24,
                height: 24,
                fontSize: 14,
                marginRight: 5,
              }}
            >
              {activeCount}
            </Avatar>
          </Tooltip>
        ) : null}

        {expand ? (
          <ExpandLess style={expandIconStyle} />
        ) : (
          <ExpandMore style={expandIconStyle} />
        )}
      </ListItemButton>
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {!data || data.error
            ? [1, 2, 3, 4].map((item) => (
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={200}
                  height={40}
                  key={item}
                  style={{ margin: "0px 8px" }}
                />
              ))
            : filterConfig.map((config) => {
                const facet = config.filterOptions
                  ? data[config.filterOptions][config.filterId]
                  : data.facets[config.filterId];
                if (!config.ld || (ldClient && ldClient.variation(config.ld))) {
                  return (
                    <MuiListItem
                      className="FilterGroupListItemButton"
                      key={config.filterId}
                    >
                      <FilterMultiSelect
                        key={config.filterId}
                        {...props}
                        config={config}
                        facet={facet}
                        onleft
                        inputLabelStyle={inputLabelStyle}
                      />
                    </MuiListItem>
                  );
                }
                return null;
              })}

          {label.toLowerCase() === "file property" &&
            (!data || data.error ? null : (
              <AdvancedFileNameInput
                {...props}
                config={{ filterTitle: "Enter a file name pattern here" }}
                facet={{}}
                onleft
                inputLabelStyle={inputLabelStyle}
              />
            ))}
        </List>
      </Collapse>
    </>
  ) : null;
};

export default FilterGroup;

FilterGroup.defaultProps = {
  data: null,
  ldClient: null,
  filterConfig: null,
  withDivider: true,
  activeCount: null,
  open: false,
};

FilterGroup.propTypes = {
  data: PropTypes.shape(),
  ldClient: PropTypes.shape(),
  filterConfig: PropTypes.arrayOf(PropTypes.shape()),
  label: PropTypes.string.isRequired,
  withDivider: PropTypes.bool,
  open: PropTypes.bool,
  activeCount: PropTypes.number,
};
