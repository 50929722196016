import React, { useEffect } from "react";
import PropTypes from "prop-types";

// import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { blue } from "@mui/material/colors";

import classes from "../TableContainer.module.css";

import {
  setLocalStorageRelevant,
  getLocalStorageRelevant,
} from "../../../utils/LocalStorageUtils";

export const RESULTS_MOST_RELEVANT_INDEX = 0;
export const RESULTS_ALL_INDEX = 1;

const SearchRelevanceSelector = (props) => {
  const { labels, setIndex, gap, data, honeycomb, disabled } = props;

  useEffect(() => {
    if (!disabled) {
      const idx = getLocalStorageRelevant(); // 0 if not in localStorage
      setIndex(idx);
    }
  }, []);

  const buttonMap = {};
  labels.forEach((e, idx) => {
    buttonMap[e.name] = idx;
  });

  const handleRadioChange = (event) => {
    const hcdata = {};
    [
      "file_total",
      "prioritized_file_total",
      "prioritized_total",
      "total",
      "score_threshold",
    ].forEach((k) => {
      hcdata[k] = data[k];
    });

    const radioIndex = buttonMap[event?.target.value];
    setLocalStorageRelevant(radioIndex);
    setIndex(radioIndex);
    honeycomb.sendUiInteractionSpan("relevancy-filter-selection", {
      relevancy: { radioIndex, ...hcdata },
    });
  };

  return (
    <FormControl className="RelevantPanel" component="fieldset">
      <RadioGroup
        className="SearchInfoPanel"
        row
        aria-label="gender"
        name="row-radio-buttons-group"
        onChange={handleRadioChange}
      >
        {labels.map((obj, idx) => {
          return (
            <FormControlLabel
              id={`${obj.uiid}_${idx}`}
              key={obj.name}
              value={obj.name}
              style={{ color: classes.lake500, marginLeft: gap }}
              control={
                <Radio
                  id={obj.uiid}
                  disabled={disabled}
                  checked={obj.checked}
                  style={{ padding: 4 }}
                  size="small"
                  sx={{
                    color: blue[500],
                    "&.Mui-checked": {
                      color: blue[600],
                    },
                  }}
                />
              }
              label={obj.label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
export default SearchRelevanceSelector;

SearchRelevanceSelector.defaultProps = {
  gap: 0,
  setIndex: null,
  data: null,
  honeycomb: null,
};

SearchRelevanceSelector.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setIndex: PropTypes.func,
  gap: PropTypes.number,
  data: PropTypes.shape(),
  honeycomb: PropTypes.shape(),
  disabled: PropTypes.bool.isRequired,
};
