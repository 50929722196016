/* istanbul ignore file */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

/**
 * Higher order component used to listen for routing changes and update the google analytics page_path on change.
 */
const withGARouteUpdating = (WrappedComponent) => {
  const GARouteUpdatingHOC = (props) => {
    const { listen } = useHistory();
    useEffect(() => {
      const unlisten = listen((location) => {
        if (!window.gtag) return;

        // Update GA on route change
        window.gtag("config", "UA-20148135-6", {
          page_path: location.pathname,
        });
      });

      return unlisten;
    }, [listen]);

    return <WrappedComponent {...props} />;
  };
  return GARouteUpdatingHOC;
};

export default withGARouteUpdating;
