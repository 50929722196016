/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
  useScrollTrigger,
  Link,
  Typography,
  Toolbar,
  Tooltip,
  Box,
} from "@material-ui/core";

import { PropTypes } from "prop-types";

import InfoIcon from "@material-ui/icons/Info";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import { base as ldbase, keycloakff } from "../../utils/LDFFUtils";

import ConfigContext from "../../context/ConfigContext";

import classes from "./Header.module.css";
import logo from "./images/logo-JGI-GenomePortal.png";

import Button from "../Button/Button";
import UserAvatar from "./UserAvatar";

import { useFileAndPageContext } from "../../context/FileAndPageContext";

import Announcements from "./Announcements/Announcements";
import ShoppingCart from "../UI/ShoppingCart/ShoppingCart";
import { useSearchParams } from "../../hooks/useSearchParams";

import { openCollectorDialog } from "../Footer/ContactUs/ContactUs";

import Help from "./Help";

const Header = (props) => {
  const {
    handleLogout,
    honeycomb,
    currentUser,
    ldClientMaster,
    ldClient,
    headerCartRef,
    headerCartThreshold,
  } = props;
  const { classicDownload } = useContext(ConfigContext);
  const keycloak_ff = keycloakff(ldClient);

  const { cart: cartpage } = useSearchParams();
  const history = useHistory();

  // use this login handle, which will handle the dual login and save page and file selection to localStorage
  const { handleLogin, cartData } = useFileAndPageContext();

  const { pathname } = window.location;
  const trigger = useScrollTrigger({
    threshold: headerCartThreshold,
    disableHysteresis: true,
  });

  useEffect(() => {
    if (cartpage) {
      const keep = ["/search", "/img"];
      if (
        !pathname.startsWith("/refine-download") &&
        !keep.includes(pathname)
      ) {
        // not on search or segment - routing will intercept and will not get to here!
        history.push("/search");
      }
    }
  }, [cartData]);

  const handleClickLogin = () => {
    honeycomb.sendUiInteractionSpan("clicked-header-login");

    if (handleLogin) {
      handleLogin();
    }
  };

  const handleDataPortalLink = (setLocation = false) => {
    honeycomb.sendUiInteractionSpan("clicked-home-link-logo: data-portal");
    if (setLocation) {
      window.location.assign("/");
    }
  };

  const handleGenomePortalLink = () => {
    honeycomb.sendUiInteractionSpan("clicked-external-link: genome-portal");
  };

  const handleJGIHomeLink = () => {
    honeycomb.sendUiInteractionSpan("clicked-external-link: jgi-home");
  };

  const handleClassicDownloadLink = () => {
    honeycomb.sendUiInteractionSpan("clicked-external-link: classic-download");
  };

  const HEADER_ICON_SIZE = "medium"; // the normal icon size; small/medium/large

  const userLoggedOn =
    currentUser &&
    !currentUser.anonymous &&
    (currentUser.keycloak || !keycloak_ff);

  const portal_ff = ldbase(ldClient, "my-data-portal");

  const authLi = userLoggedOn ? (
    <Box className={classes.boxUser}>
      <div className={classes.spacer}> </div>
      <UserAvatar
        user={currentUser}
        handleLogout={handleLogout}
        honeycomb={honeycomb}
        ldClientMaster={ldClientMaster}
        ldClient={ldClient}
        size={HEADER_ICON_SIZE}
      />
    </Box>
  ) : (
    <Button
      id="login-btn"
      variant="outlined"
      color="secondary"
      size="small"
      onClick={handleClickLogin}
      sx={{ marginLeft: 12, height: 24, width: 64 }} // smaller button to match small icons in header
    >
      Login
    </Button>
  );

  const gpTitle = "JGI genome portal";
  const tooltipText =
    "We're testing a new download experience (and we hope you enjoy it).";
  const tooltipText2 = "To return to the classic experience, use this link.";
  const tooltipTitle = (
    <>
      <Typography style={{ fontSize: 12 }}>{tooltipText}</Typography>
      <br />
      <Typography style={{ fontSize: 12 }}>{tooltipText2}</Typography>
    </>
  );

  // const testRef = useRef();
  return (
    <>
      <Toolbar className={classes.toolBar}>
        <Box className={classes.imageBox} title="JGI Data Portal">
          <Link
            className={classes.link}
            component={RouterLink}
            to="/"
            onClick={() => {
              handleDataPortalLink(true);
            }}
            onContextMenu={() => {
              handleDataPortalLink();
            }}
            data-testid="data-portal-link"
          >
            <img src={logo} alt="JGI Data Portal" />
          </Link>
        </Box>
        <Box className={classes.box} title="JGI website">
          <Link
            className={classes.link}
            component={RouterLink}
            target="_blank"
            to="//jgi.doe.gov"
            onClick={handleJGIHomeLink}
            onContextMenu={() => {
              handleJGIHomeLink();
            }}
            data-testid="jgi-home-link"
          >
            <Typography className={classes.linkFont}>JGI HOME</Typography>
          </Link>
        </Box>
        <Box className={classes.box} title={gpTitle}>
          <Link
            className={classes.link}
            component={RouterLink}
            target="_blank"
            to="//genome.jgi.doe.gov/portal"
            onClick={handleGenomePortalLink}
            onContextMenu={() => {
              handleGenomePortalLink();
            }}
            data-testid="genome-portal-link"
          >
            <Typography className={classes.linkFont}>GENOME PORTAL</Typography>
          </Link>
        </Box>
        <Help honeycomb={honeycomb} />

        {portal_ff && (
          <Box className={classes.box} title="My data">
            <Link
              className={classes.link}
              component={RouterLink}
              to="/mydata"
              onClick={(event) => {
                event.stopPropagation();
                honeycomb.sendUiInteractionSpan(
                  "clicked-header-my-data-portal"
                );
              }}
              data-testid="mydata-header-link"
              style={{ position: "relative", top: 4 }}
            >
              <ManageAccountsIcon
                style={{
                  position: "relative",
                  top: -4,
                  marginRight: 2,
                  color: "RGB(0,0,0,0.54)",
                }}
              />
              <Typography className={classes.linkFont}>
                MY DATA PORTAL
              </Typography>
            </Link>
          </Box>
        )}

        <Box className={classes.box} flexGrow={1}>
          {classicDownload && (
            <Link
              className={classes.link}
              component={RouterLink}
              target="_blank"
              to={classicDownload}
              onClick={handleClassicDownloadLink}
              onContextMenu={() => {
                handleClassicDownloadLink();
              }}
              data-testid="classic-download-link"
            >
              <Typography className={classes.linkFont}>
                RETURN TO CLASSIC DOWNLOAD
              </Typography>
              <Tooltip
                title={tooltipTitle}
                arrow
                classes={{ tooltip: classes.tooltip }}
              >
                <InfoIcon
                  color="secondary"
                  fontSize="large"
                  className={classes.infoIcon}
                />
              </Tooltip>
            </Link>
          )}
        </Box>

        <Button
          variant="outlined"
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            openCollectorDialog();
          }}
          style={{ minWidth: 100 }}
        >
          <Typography style={{ fontWeight: 900 }} noWrap>
            {" "}
            Contact Us
          </Typography>
        </Button>
        <Box style={{ minWidth: 20 }}> </Box>

        {(pathname?.startsWith("/mydata") || !trigger) && (
          <ShoppingCart
            cartRef={headerCartRef}
            ldClient={ldClient}
            honeycomb={honeycomb}
            count={cartData?.organisms?.length}
            size={HEADER_ICON_SIZE}
          />
        )}

        <Announcements
          ldClient={ldClient}
          size={HEADER_ICON_SIZE}
          honeycomb={honeycomb}
          currentUser={currentUser}
        />

        {authLi}
      </Toolbar>
    </>
  );
};

export default Header;

Header.defaultProps = {
  handleLogout: null,
  honeycomb: {
    sendUiInteractionSpan: () => {},
  },
  currentUser: null,
  ldClient: null,
  ldClientMaster: null,
  headerCartRef: null,
  headerCartThreshold: 0,
};

Header.propTypes = {
  handleLogout: PropTypes.func,
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
  }),
  currentUser: PropTypes.shape(),
  ldClientMaster: PropTypes.shape(),
  ldClient: PropTypes.shape(),
  headerCartRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  headerCartThreshold: PropTypes.number,
};
