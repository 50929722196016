import React from "react";
import PropTypes from "prop-types";

import classes from "./Document.module.css";

const oneBlock = (c) =>
  c.title ? (
    <div key={c.title}>
      <div className={classes.subTitle}>{c.title}</div>
      <div className={classes.content}> {c.block} </div>
    </div>
  ) : (
    <div key={c.block[0]} className={classes.content}>
      {" "}
      {c.block}{" "}
    </div>
  );

const Document = (props) => {
  const { title, paragraphs } = props;

  const content = paragraphs.map((p) => (
    <div key={p.title}>
      <div className={classes.paraTitle}> {p.title} </div>
      {p.content.map(oneBlock)}
    </div>
  ));

  return (
    <>
      <div key="doc-container" className={classes.mainTitle}>
        {title}
      </div>
      {content}
    </>
  );
};

Document.defaultProps = {
  title: "",
  paragraphs: [],
};

Document.propTypes = {
  title: PropTypes.string,
  paragraphs: PropTypes.arrayOf(PropTypes.shape()),
};

export default Document;
