import React from "react";
import { Typography } from "@material-ui/core";

import PropTypes from "prop-types";

import classes from "../../UI/InfoBoard/InfoBoard.module.css";

import ModalContentBase from "../../UI/InfoBoard/ModalContentBase";
import { getGlobusDownloadSizeInByte } from "../../../utils/FileUtils";
import {
  HREF_DOC_CURL_DOWNLOAD,
  HREF_DOC_GLOBUS_AND_HOW,
} from "../../../utils/URLConstants";
import Dialog from "../../UI/Dialogs/DraggableDialog/DraggableDialog";

export const FROM_BROWSER_DOWNLOAD = "FROM_BROWSER_DOWNLOAD";
export const FROM_CURL_DOWNLOAD = "FROM_CURL_DOWNLOAD";

const ConfirmDownloadActionDlg = ({
  handleAccept,
  handleCancel,
  fileSummary,
  ldClient,
  ftype,
}) => {
  const globusSize = getGlobusDownloadSizeInByte(ldClient);
  const dsize = fileSummary.totalSizeInBytes;

  const title =
    ftype === FROM_BROWSER_DOWNLOAD
      ? "Browser download"
      : "Command line download";
  let gtext = "our command line option";
  let ltext = HREF_DOC_CURL_DOWNLOAD;

  if (dsize > globusSize) {
    gtext = "our Globus download option";
    ltext = HREF_DOC_GLOBUS_AND_HOW;
  } else {
    gtext = "either our command line, or Globus download options";
    // ltext = `${HREF_DOC_CURL_DOWNLOAD},${HREF_DOC_GLOBUS_AND_HOW}`;
    ltext = HREF_DOC_CURL_DOWNLOAD;
  }

  const mainBody = (
    <>
      <div className={classes.block}>
        <Typography paragraph>
          Are you sure you want to continue downloading via the{" "}
          {ftype === FROM_BROWSER_DOWNLOAD ? "browser" : "command line"}?
          <br />
          <br />
          For downloads of this size, we recommend that you use {gtext}. Please
          <a
            href={ltext}
            target="_blank"
            style={{ margin: "0px 4px" }}
            rel="noreferrer"
          >
            click here
          </a>{" "}
          to learn more about this.
        </Typography>
      </div>
    </>
  );

  return (
    <Dialog
      acceptBtnFn={handleAccept}
      acceptBtnText="Continue"
      cancelBtnFn={handleCancel}
    >
      <ModalContentBase titleText={title} closeFn={handleCancel}>
        {mainBody}
      </ModalContentBase>
    </Dialog>
  );
};

export default ConfirmDownloadActionDlg;

ConfirmDownloadActionDlg.defaultProps = {
  ftype: FROM_BROWSER_DOWNLOAD,
};

ConfirmDownloadActionDlg.propTypes = {
  handleAccept: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  fileSummary: PropTypes.shape().isRequired,
  ldClient: PropTypes.shape({
    variation: PropTypes.func,
  }).isRequired,
  ftype: PropTypes.string,
};
