const currentTimeIsIn = (start, end) => {
  let yes = Boolean(start || end);
  const now = Date.now();
  if (start) {
    const st = new Date(start);
    yes = st.toString() !== "Invalid Date" && now > st;
  }

  if (yes && end) {
    const et = new Date(end);
    yes = et.toString() !== "Invalid Date" && et > now;
  }

  return yes;
};

export default {
  currentTimeIsIn,
};
