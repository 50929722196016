import React from "react";
import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";

import Dialog from "../../UI/Dialogs/DraggableDialog/DraggableDialog";
import ModalContentBase from "../../UI/InfoBoard/ModalContentBase";
import JDPLifeHelpIcon from "../../UI/Icons/JDPLifeHelpIcon";

import {
  nowStatus,
  archivedStatus,
  pendingStatus,
} from "../../FileTable/FileTableCells/FileTableCells";

import { HREF_DOC_ARCHIVED } from "../../../utils/URLConstants";

import classes from "../../UI/InfoBoard/InfoBoard.module.css";

const FileAvailabilityDlg = ({ handleCancel }) => {
  const archiveLink = (txt) => {
    return (
      <Link
        href={HREF_DOC_ARCHIVED}
        target="_blank"
        style={{ margin: "0px 4px" }}
      >
        {`${txt}`}
      </Link>
    );
  };
  const body = (
    <Grid
      container
      spacing={3}
      direction="column"
      wrap="nowrap"
      style={{ paddingLeft: "12px", paddingRight: "34px" }}
    >
      <Grid item style={{ borderBottom: "1px solid #D1D1D1" }}>
        <Grid container wrap="nowrap">
          <Grid item xs={2}>
            {archivedStatus}
          </Grid>
          <Grid item xs>
            <Typography variant="subtitle1" className={classes.infoText}>
              <>
                This file is in our
                {archiveLink("tape archive")}. Once requested, it will be ready
                for download within 24 hours (and we’ll provide instructions on
                how to retrieve it).
              </>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ borderBottom: "1px solid #D1D1D1" }}>
        <Grid container wrap="nowrap">
          <Grid item xs={2}>
            {pendingStatus}
          </Grid>
          <Grid item xs>
            <Typography variant="subtitle1" className={classes.infoText}>
              <>
                This file is being retrieved from our
                {archiveLink("tape archive")}. Once it’s ready to be downloaded,
                its status will change to {nowStatus}.
              </>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container wrap="nowrap">
          <Grid item xs={2}>
            {nowStatus}
          </Grid>
          <Grid item xs>
            <Typography variant="subtitle1" className={classes.infoText}>
              This file is ready for immediate download.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography paragraph>
          <JDPLifeHelpIcon size="default">
            {archiveLink(
              "Learn about archived files and how they affect downloads"
            )}
          </JDPLifeHelpIcon>
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Dialog acceptBtnFn={handleCancel} acceptBtnText="OK">
      <ModalContentBase
        closeFn={handleCancel}
        titleText="File download availability"
      >
        {body}
      </ModalContentBase>
    </Dialog>
  );
};

export default FileAvailabilityDlg;

FileAvailabilityDlg.propTypes = {
  handleCancel: PropTypes.func.isRequired,
};
