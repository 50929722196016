import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import NoDraggableDialog from "../../../UI/InfoBoard/Standalone/NoDraggableDialog";
import { infoText, infoTextBold } from "./styles";
import { ORCID_USER_COLOR } from "./constants";

const UnknownOrchid = ({ data, cancelBtnFn, proceedBtnFn }) => {
  // console.log("[BadID]", data);
  const infoPrefix =
    data.good?.length > 0
      ? `Some of the ORCID(s) you provided are not `
      : `None of the ORCID(s) you provided are `;

  const goodIDs =
    data.good?.length > 0 ? (
      <div>
        <Typography sx={infoTextBold}>
          ORCID{data.good.length > 1 ? "s" : ""} found and recognized:
        </Typography>
        <div style={{ fontSize: 20, overflow: "auto", maxHeight: 100 }}>
          <table style={{ borderSpacing: 6 }}>
            <tbody>
              {data.good.map((d, idx) => {
                const kid = `${d.id}-${idx}`;
                return (
                  <tr key={kid}>
                    <td
                      style={{ color: ORCID_USER_COLOR, fontWeight: "bold" }}
                    >{`${d.first_name[0]}. ${d.last_name}`}</td>
                    <td>{`${d.email}`}</td>
                    <td>
                      ORCID:{" "}
                      <span
                        style={{ fontWeight: "bold" }}
                      >{`${d.orcid_id}`}</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    ) : null;
  return (
    <NoDraggableDialog
      title="ORCID(s) not found"
      width={800}
      acceptBtnText={
        data.good?.length > 0 ? "Proceed with recognized users" : "Cancel"
      }
      acceptBtnFn={data.good?.length > 0 ? proceedBtnFn : cancelBtnFn}
      cancelCrossFn={cancelBtnFn}
      cancelBtnText={data.good?.length > 0 ? "Cancel" : null}
      cancelBtnFn={cancelBtnFn}
      subcontent={goodIDs}
    >
      <Typography paragraph sx={infoText}>
        {infoPrefix}
        associated with a registered JGI user. Please ask your targeted user(s)
        to add an ORCID to their existing JGI profile or register{" "}
        <Link target="_blank" href="https://signon.jgi.doe.gov/signon">
          here
        </Link>
        . Once they have done that, please come back to the Manage Access page
        and resubmit the request.
      </Typography>
      <Typography paragraph sx={infoText}>
        Copy the ORCID ID(s) below if necessary.
      </Typography>
      <Typography paragraph>
        If you need additional information, please visit our help page{" "}
        <Link
          target="_blank"
          href="https://sites.google.com/lbl.gov/data-portal-help/home/tips_tutorials/my-data-portal#h.o61aqwezydiv"
        >
          here
        </Link>
        .
      </Typography>

      {/* <Typography sx={infoTextBold}>
        ORCID id{data.bad.length > 1 ? "s" : ""} affected:
      </Typography> */}
      <Typography sx={infoTextBold}>ORCID(s) affected:</Typography>
      <div style={{ overflow: "auto", maxHeight: 100 }}>
        <table style={{ borderSpacing: 6 }}>
          <tbody>
            {data.bad.map((d, idx) => {
              const kid = `${d}-${idx}`;
              return (
                <tr key={kid}>
                  <td>{d}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </NoDraggableDialog>
  );
};

export default UnknownOrchid;

UnknownOrchid.propTypes = {
  data: PropTypes.shape().isRequired,
  cancelBtnFn: PropTypes.func.isRequired,
  proceedBtnFn: PropTypes.func.isRequired,
};
